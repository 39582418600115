import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Heading,
    Text,
    Card,
    useTheme,
    SimpleGrid,
    VStack,
    Divider,
    Link,
    useColorModeValue,
    Image,
    CardBody,
    RadioGroup,
    Radio,
    Tooltip,
} from '@chakra-ui/react';
import SchemaProvider from '../../utils/SchemaProvider';
import { useIntl } from 'react-intl';
import { InferType } from 'yup';
import FormikTextInput from '../../components/formik/FormikTextInput';
import FormikPasswordInput from '../../components/formik/FormikPasswordInput';
import FormStepperVisualizer from './RegisterStepper';
import FormikStepper from '../../components/formik/FormikStepper';
import FormikStep from '../../components/formik/FormikStep';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useRegister } from '../../query/resource-hooks/auth';
import { User } from '../../types';
import { AxiosError } from 'axios';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import NotificationList from '../../components/ui/NotificationsList';
import { useNotificationStore } from '../../store/ui/NotificationStore';
import { useAuthStore } from '../../store/auth/authStore';
import { FormikCheckBox } from '../../components/formik/FormikCheckBox';
import LanguageSelectMenu from '../../components/ui/LanguageSelectMenu';
import ErrorHelper from '../../query/utils/ErrorHelper';

enum CompanyMode {
    CREATE = 'CREATE', // Register and create new company
    JOIN = 'JOIN', // Register and join existing company with code
}

const RegisterScreen: React.FC = () => {
    const theme = useTheme();
    const intl = useIntl();
    const navigate = useNavigate();
    const loggedUser = useAuthStore((state) => state.user);
    const { pushNotification, clearNotifications } = useNotificationStore();

    const registerMutation = useRegister(onRegisterSuccess, onRegisterError);

    const [step, setStep] = useState(0);

    const schemas = SchemaProvider.getRegisterSchema(intl);
    const registerYupSchema = schemas.userData;
    const companySetupSchema = schemas.companySetup;

    const headingColor = useColorModeValue('white', 'gray.100');
    const textColor = useColorModeValue('gray.300', 'gray.300');

    const handleFinish = (values: InferType<typeof companySetupSchema> & InferType<typeof registerYupSchema>) => {
        registerMutation.mutate({
            firstName: values.firstName,
            surname: values.surname,
            userName: values.email,
            password: values.password,
            ...(values.companyMode === CompanyMode.CREATE ? { companyName: values.companyName } : { inviteCode: values.inviteCode }),
        });
    };

    function onRegisterSuccess(newUser: User) {
        navigate('/result', {
            state: {
                title: intl.formatMessage({ id: 'registerScreen.signup.successTitle' }),
                text: intl.formatMessage({ id: 'confirmEmailText' }),
                type: 'success',
            },
        });
    }

    function onRegisterError(error: AxiosError<any>) {
        if (ErrorHelper.isUserNameTakenError(error)) {
            pushNotification({
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                description: intl.formatMessage({ id: 'userNameTaken' }),
                type: 'error',
            });
        } else {
            pushNotification({
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                description: intl.formatMessage({ id: 'somethingWentWrong.tryLater' }),
                type: 'error',
            });
        }
    }

    const steps = [
        {
            title: intl.formatMessage({ id: 'registerScreen.account.title' }),
            description: intl.formatMessage({ id: 'registerScreen.account.text' }),
        },
        {
            title: intl.formatMessage({ id: 'registerScreen.company.title' }),
            description: intl.formatMessage({ id: 'registerScreen.company.text' }),
        },
    ];

    useEffect(() => {
        return () => {
            clearNotifications(); //Clear norifications
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loggedUser) {
            navigate('/home');
        }
    }, [loggedUser, navigate]);

    return (
        <>
            <Box minH="100vh" justifyContent={'center'} display={'flex'} bg={useColorModeValue('gray.100', 'gray.900')}>
                <Box w={'100%'} maxW={'1000'} p={{ base: 4, md: 8 }}>
                    <Box>
                        <NotificationList />
                    </Box>
                    <Card w={'100%'} borderRadius={'lg'}>
                        <Flex minH={600} direction={{ base: 'column', md: 'row' }}>
                            <Box maxW={{ md: '380px' }} bg={theme.colors.primary[500]} p="5" borderRadius={'lg'}>
                                <Flex justifyContent={'space-between'}>
                                    <Heading color={headingColor} size="lg">
                                        {intl.formatMessage({ id: 'login.register' })}
                                    </Heading>
                                    <LanguageSelectMenu />
                                </Flex>
                                <Text color={textColor} mt="4">
                                    {intl.formatMessage({ id: 'registerScreen.signup.subtitle' })}
                                </Text>
                                <Image
                                    display={{ base: 'none', md: 'block' }}
                                    justifySelf={'center'}
                                    src={`${process.env.PUBLIC_URL}/logo512.png`}
                                    alt="Supervizer logo"
                                />
                            </Box>
                            <Box p="5" display={'flex'} flex={1} flexDir={'column'}>
                                <FormStepperVisualizer steps={steps} step={step} />
                                <Divider my={4} />

                                <FormikStepper
                                    onSubmit={handleFinish}
                                    isLoading={registerMutation.isLoading}
                                    initialValues={{
                                        firstName: '',
                                        surname: '',
                                        email: '',
                                        password: '',
                                        confirmPassword: '',
                                        companyName: '',
                                        companyMode: CompanyMode.CREATE,
                                        betaKey: '',
                                        gdprAccepted: false,
                                        termsOfUseAccepted: false,
                                    }}
                                    onStepChange={(step) => {
                                        setStep(step);
                                    }}
                                    finalStepSubmitText={intl.formatMessage({ id: 'login.register' })}
                                >
                                    <FormikStep validationSchema={registerYupSchema}>
                                        {({ errors, touched, handleChange, handleBlur, values }) => (
                                            <VStack spacing={{ base: 2 }}>
                                                <FormikTextInput
                                                    errors={errors}
                                                    touched={touched}
                                                    name="email"
                                                    fieldName="email"
                                                    inputMode="email"
                                                    type="email"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    label={intl.formatMessage({ id: 'email' })}
                                                    values={values}
                                                />

                                                <SimpleGrid w={'100%'} columns={{ base: 1, md: 2 }} spacing={4}>
                                                    <FormikTextInput
                                                        errors={errors}
                                                        touched={touched}
                                                        name="firstName"
                                                        fieldName="firstName"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        label={intl.formatMessage({ id: 'firstName' })}
                                                        values={values}
                                                    />
                                                    <FormikTextInput
                                                        errors={errors}
                                                        touched={touched}
                                                        name="surname"
                                                        fieldName="surname"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        label={intl.formatMessage({ id: 'surname' })}
                                                        values={values}
                                                    />
                                                    <FormikPasswordInput
                                                        errors={errors}
                                                        touched={touched}
                                                        name="password"
                                                        fieldName="password"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        label={intl.formatMessage({ id: 'password' })}
                                                        values={values}
                                                    />
                                                    <FormikPasswordInput
                                                        errors={errors}
                                                        touched={touched}
                                                        name="confirmPassword"
                                                        fieldName="confirmPassword"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        label={intl.formatMessage({ id: 'confirmPassword' })}
                                                        values={values}
                                                    />
                                                </SimpleGrid>
                                                <FormikCheckBox
                                                    size={'sm'}
                                                    errors={errors}
                                                    touched={touched}
                                                    name="gdprAccepted"
                                                    fieldName="gdprAccepted"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    label={intl.formatMessage({ id: 'iAccept' })}
                                                    linkTo="/policy"
                                                    linkText={intl.formatMessage({ id: 'privacyPolicy' })}
                                                    values={values}
                                                />

                                                <FormikCheckBox
                                                    size={'sm'}
                                                    errors={errors}
                                                    touched={touched}
                                                    name="termsOfUseAccepted"
                                                    fieldName="termsOfUseAccepted"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    label={intl.formatMessage({ id: 'iAccept' })}
                                                    linkTo="/terms-of-use"
                                                    linkText={intl.formatMessage({ id: 'termsAndConditions' })}
                                                    values={values}
                                                />
                                            </VStack>
                                        )}
                                    </FormikStep>
                                    <FormikStep validationSchema={companySetupSchema}>
                                        {({ errors, touched, handleChange, handleBlur, values }) => (
                                            <RadioGroup onChange={handleChange('companyMode')} value={values['companyMode']}>
                                                <VStack spacing={2}>
                                                    <Card
                                                        width={'100%'}
                                                        variant={values['companyMode'] === CompanyMode.CREATE ? 'outline' : 'filled'}
                                                        onClick={() => handleChange('companyMode')(CompanyMode.CREATE)}
                                                        borderRadius={'xl'}
                                                    >
                                                        <Radio pt={2} pl={2} value={CompanyMode.CREATE}>
                                                            <Text fontWeight={600}>{intl.formatMessage({ id: 'createCompany' })}</Text>
                                                        </Radio>
                                                        <CardBody>
                                                            <FormikTextInput
                                                                errors={errors}
                                                                touched={touched}
                                                                name="companyName"
                                                                fieldName="companyName"
                                                                handleChange={handleChange}
                                                                handleBlur={handleBlur}
                                                                label={intl.formatMessage({ id: 'companyName' })}
                                                                values={values}
                                                                isDisabled={values['companyMode'] === CompanyMode.JOIN}
                                                            />
                                                        </CardBody>
                                                    </Card>

                                                    <Card
                                                        width={'100%'}
                                                        variant={values['companyMode'] === CompanyMode.JOIN ? 'outline' : 'filled'}
                                                        onClick={() => handleChange('companyMode')(CompanyMode.JOIN)}
                                                        borderRadius={'xl'}
                                                    >
                                                        <Radio pt={2} pl={2} value="JOIN">
                                                            <Text fontWeight={600}>
                                                                {intl.formatMessage({ id: 'joinCompany' })}{' '}
                                                                <Tooltip label={intl.formatMessage({ id: 'insertInviteCodeText' })}>
                                                                    <InfoOutlineIcon />
                                                                </Tooltip>
                                                            </Text>
                                                        </Radio>
                                                        <CardBody>
                                                            <FormikTextInput
                                                                errors={errors}
                                                                touched={touched}
                                                                name="inviteCode"
                                                                fieldName="inviteCode"
                                                                handleChange={handleChange}
                                                                handleBlur={handleBlur}
                                                                label={intl.formatMessage({ id: 'inviteCode' })}
                                                                values={values}
                                                                isDisabled={values['companyMode'] === 'CREATE'}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </VStack>
                                            </RadioGroup>
                                        )}
                                    </FormikStep>
                                </FormikStepper>
                                <Box alignSelf={'end'} pt={4}>
                                    <Text textAlign={'end'}>
                                        {intl.formatMessage({ id: 'registerScreen.signup.alreadyHaveAccount' })}{' '}
                                        <Link as={RouterLink} to={'/login'} fontWeight={600}>
                                            {intl.formatMessage({ id: 'logIn' })}
                                        </Link>
                                    </Text>
                                </Box>
                            </Box>
                        </Flex>
                    </Card>
                </Box>
            </Box>
        </>
    );
};

export default RegisterScreen;
