import { Button, Box, List, Flex, Center } from '@chakra-ui/react';
import { InfiniteData } from 'react-query';
import { BasePagination } from '../../types';
import { useIntl } from 'react-intl';
import { LoadingCard } from '../ui/LoadingCard';

interface InfiniteListProps<T> {
    data?: InfiniteData<BasePagination<T>>;
    fetchNextPage: () => void;
    hasNextPage: boolean | undefined;
    isFetchingNextPage: boolean;
    renderItem: (item: T, index: number) => JSX.Element;
    isLoading?: boolean;
    loadingComponent?: JSX.Element;
}

function InfiniteList<T>({
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    renderItem,
    isLoading,
    loadingComponent,
}: InfiniteListProps<T>) {
    const intl = useIntl();
    const items = data?.pages.flatMap((page) => page.data);

    if (isLoading && !isFetchingNextPage) {
        if (loadingComponent) {
            return loadingComponent;
        } else {
            return (
                <Center p={5}>
                    <LoadingCard />
                </Center>
            );
        }
    }

    if (!items?.length) {
        return (
            <Center>
                <Box>{intl.formatMessage({ id: 'noData' })}</Box>
            </Center>
        );
    }

    return (
        <Box>
            <List>{items.map(renderItem)}</List>
            {hasNextPage && (
                <Flex p={2} alignItems={'center'} justifyContent={'center'}>
                    <Button colorScheme="primary" onClick={fetchNextPage} isLoading={isFetchingNextPage}>
                        {intl.formatMessage({ id: 'viewMore' })}
                    </Button>
                </Flex>
            )}
        </Box>
    );
}

export default InfiniteList;
