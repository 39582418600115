import { FormControl, FormLabel, IconButton, Input, InputGroup, InputRightElement, Text, useDisclosure } from '@chakra-ui/react';
import { memo, useRef } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';

type Props = React.ComponentProps<typeof Input> & {
    label: string;
    values: Record<string, any>;
    fieldName: string;
    handleChange: (field: string) => any;
    errors: Record<string, any>;
    handleBlur: (field: string) => any;
    touched: Record<string, any>;
    afterChange?: (value: string) => void;
};

const FormikTextInput: React.FC<Props> = ({
    values,
    label,
    fieldName,
    handleBlur,
    handleChange,
    errors,
    touched,
    afterChange,
    ...restProps
}) => {
    const { isOpen, onToggle } = useDisclosure();
    const inputRef = useRef<HTMLInputElement>(null);
    // const mergeRef = useMergeRefs(inputRef, ref);

    const handleInput = (text: string) => {
        handleChange(fieldName)(text);
        if (afterChange) {
            afterChange(text);
        }
    };

    const onClickReveal = () => {
        onToggle();
        if (inputRef.current) {
            inputRef.current.focus({ preventScroll: true });
        }
    };

    return (
        <FormControl isInvalid={!!(errors[fieldName] && touched[fieldName])}>
            <FormLabel htmlFor={fieldName}>{label}</FormLabel>
            <InputGroup>
                <InputRightElement>
                    <IconButton
                        variant="text"
                        aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                        icon={isOpen ? <HiEyeOff /> : <HiEye />}
                        onClick={onClickReveal}
                    />
                </InputRightElement>
                <Input
                    id={fieldName}
                    ref={inputRef}
                    isInvalid={!!(errors[fieldName] && touched[fieldName])}
                    onBlur={handleBlur(fieldName)}
                    onChange={(e) => handleInput(e.target.value)}
                    errorBorderColor="red.500"
                    inputMode="text"
                    type={isOpen ? 'text' : 'password'}
                    autoComplete="current-password"
                    value={values[fieldName]}
                    {...restProps}
                ></Input>
            </InputGroup>
            {!!(errors[fieldName] && touched[fieldName]) ? (
                <Text color="red.500" fontSize="sm">
                    {errors[fieldName] as string}
                </Text>
            ) : null}
        </FormControl>
    );
};

export default memo(FormikTextInput);
