import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import './FeatureTabs.css';
import { FeatureLogAttendanceTab } from './Tabs/FeatureLogAttendanceTab';
import { FeatureAttendanceList } from './Tabs/FeatureAttendanceList';
import { FeatureAttendanceReport } from './Tabs/FeatureAttendanceReport';
import { useIntl } from 'react-intl';

export const FeatureTabs: React.FC = () => {
    const intl = useIntl();

    return (
        <Tabs align='center' colorScheme={'whiteAlpha'}  isManual variant="enclosed">
            <TabList mx={{base: 0, xl: 10}}>
                <Tab className='feature_tab_header'>{intl.formatMessage({id: 'about.tabs.attendance'})}</Tab>
                <Tab className='feature_tab_header' >{intl.formatMessage({id: 'about.tabs.attendanceList'})}</Tab>
                <Tab className='feature_tab_header' >{intl.formatMessage({id: 'about.tabs.attendanceReport'})}</Tab>
            </TabList>
            <TabPanels h={{base: undefined, md: '90vh'}}>
                <TabPanel>
                    <FeatureLogAttendanceTab />
                </TabPanel> 
                <TabPanel>
                    <FeatureAttendanceList />
                </TabPanel>
                <TabPanel>
                    <FeatureAttendanceReport />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};
