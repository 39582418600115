import { FormikContextType, FormikProps, FormikValues } from 'formik';
import React, { ReactNode } from 'react';

export type FormikStepProps = {
    children: (formikProps: FormikContextType<FormikValues>) => ReactNode;
    formikProps?: FormikProps<FormikValues>;
    validationSchema?: any;
};

const FormikStep: React.FC<FormikStepProps> = ({ children, formikProps }) => {
    return <>{children(formikProps!)}</>;
};

export default FormikStep;