import {
    Box,
    Card,
    CardBody,
    Flex,
    Heading,
    HStack,
    Icon,
    useDisclosure,
    VStack,
    Text,
    IconButton,
    Center,
    Button,
    Tooltip,
} from '@chakra-ui/react';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCreateAttendanceReport, useGetUserProfileDetail } from '../../query/resource-hooks/company';
import SecondPageHeader from '../../components/ui/SecondPageHeader';
import { useIntl } from 'react-intl';
import { SimpleAttendanceReportModal } from '../../components/attendance/reports/SimpleReportModal';
import { useEffect, useState } from 'react';
import { ReportWorkDayCard } from '../../components/attendance/reports/ReportWorkDayCard';
import { PairedShiftVisualizer } from '../../components/attendance/reports/PairedShiftVisualizer';
import { PairedShift } from '../../types';
import { FiCalendar, FiUser } from 'react-icons/fi';
import { BsClock } from 'react-icons/bs';
import { LoadingCard } from '../../components/ui/LoadingCard';
import { PlusSquareIcon } from '@chakra-ui/icons';
import { UpdateAttendanceModal } from '../../components/attendance/UpdateAttendanceModal';
import UserPolicy from '../../policies/UserPolicy';
import { useAuthStore } from '../../store/auth/authStore';

const EmployeeAttendanceReportScreen: React.FC = () => {
    const routeParams = useParams<{ id: string }>();
    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();
    const intl = useIntl();
    const loggedUser = useAuthStore((s) => s.user);
    const user = useGetUserProfileDetail(+routeParams.id!);
    const { mutate, isLoading, isError, data } = useCreateAttendanceReport();

    const attendanceReportDisclosure = useDisclosure();

    const [lastDateFrom, setLastDateFrom] = useState<string | null>(null);
    const [lastDateTo, setLastDateTo] = useState<string | null>(null);

    const [selectedShift, setSelectedShift] = useState<PairedShift | null>(null);
    const [selectedShiftIndex, setSelectedShiftIndex] = useState<number | null>(null);
    const [selectedDayIndex, setSelectedDayIndex] = useState<number | null>(null);
    const addAttendanceDisclosure = useDisclosure();

    useEffect(() => {
        if (
            search.get('dateFrom') &&
            search.get('dateTo') &&
            !isLoading &&
            (search.get('dateFrom') !== lastDateFrom || search.get('dateTo') !== lastDateTo)
        ) {
            setLastDateTo(search.get('dateTo'));
            setLastDateFrom(search.get('dateFrom'));

            mutate({
                dateFrom: search.get('dateFrom')!,
                dateTo: search.get('dateTo')!,
                userIds: [routeParams.id!],
            });
        }
    }, [isLoading, lastDateFrom, lastDateTo, mutate, routeParams.id, search]);

    function handleDataUpdate() {
        setSelectedShift(null);
        setSelectedShiftIndex(null);
        // Refetch data on update
        mutate({
            dateFrom: search.get('dateFrom')!,
            dateTo: search.get('dateTo')!,
            userIds: [routeParams.id!],
        });
    }

    if (user.error || isError) {
        return <Navigate to="/404" />;
    }

    if (isLoading) {
        return (
            <Box>
                <SecondPageHeader title={intl.formatMessage({ id: 'attendanceReport.title' })} onBack={() => navigate(-1)} />
                <Center>
                    <LoadingCard />
                </Center>
            </Box>
        );
    }

    return (
        <Box>
            <SecondPageHeader title={intl.formatMessage({ id: 'attendanceReport.title' })} onBack={() => navigate(-1)} />

            <SimpleAttendanceReportModal
                isOpen={attendanceReportDisclosure.isOpen}
                onClose={attendanceReportDisclosure.onClose}
                user={user.data!}
                onDateRangeSubmit={(dateFrom, dateTo) => {
                    setSearch({ dateFrom, dateTo });
                    attendanceReportDisclosure.onClose();
                }}
            />

            <UpdateAttendanceModal
                isOpen={addAttendanceDisclosure.isOpen}
                onClose={addAttendanceDisclosure.onClose}
                preselectedUser={user.data}
                onSuccess={() => handleDataUpdate()}
                preselectedDate={selectedShift?.arrival?.date ?? selectedShift?.departure?.date ?? undefined}
            />

            <Card m={4}>
                <CardBody>
                    <HStack justifyContent={'space-between'}>
                        <Flex pb={3} alignItems={'center'}>
                            <Icon as={FiUser} mr={2} />
                            <Heading size={'md'}>{user.data?.fullName}</Heading>
                        </Flex>
                        <IconButton aria-label="change date" icon={<FiCalendar />} onClick={attendanceReportDisclosure.onOpen} />
                    </HStack>
                    <Flex alignItems={'center'}>
                        <Icon as={FiCalendar} mr={2} />
                        {search.get('dateTo') && search.get('dateFrom') && (
                            <Text>{`${intl.formatDate(search.get('dateFrom')!)} - ${intl.formatDate(search.get('dateTo')!)} `}</Text>
                        )}
                    </Flex>
                    <Tooltip label={intl.formatMessage({ id: 'attendanceReport.totalTime' })}>
                        <Flex width={'max-content'} alignItems={'center'}>
                            <Icon as={BsClock} mr={2} />
                            <Text>{data?.[0]?.totalHours} h</Text>
                        </Flex>
                    </Tooltip>
                </CardBody>
            </Card>

            <HStack width={'100%'} alignItems={'start'}>
                <VStack pl={2} spacing={2} mx={4}>
                    <Tooltip label={intl.formatMessage({ id: 'attendance.manual.label' })}>
                        <Tooltip label={intl.formatMessage({ id: 'attendance.manual.help' })}>
                            <Button
                                onClick={addAttendanceDisclosure.onOpen}
                                size={'sm'}
                                alignSelf={'start'}
                                borderRadius={'full'}
                                leftIcon={<PlusSquareIcon />}
                                isDisabled={!UserPolicy.canCreateManualAttendance(loggedUser!)}
                                boxShadow={'md'}
                            >
                                {intl.formatMessage({ id: 'tab.Add' })}
                            </Button>
                        </Tooltip>
                    </Tooltip>
                    {data?.[0]?.reports.map((report, index) => {
                        return (
                            <ReportWorkDayCard
                                onDayClick={() => setSelectedDayIndex(index)}
                                isSelected={selectedDayIndex === index}
                                workDay={report}
                                key={report.date + '_' + index}
                                selectedShiftIndex={selectedDayIndex === index ? selectedShiftIndex! : undefined}
                                onShiftClick={(shift, index) => {
                                    setSelectedShift(shift);
                                    setSelectedShiftIndex(index);
                                }}
                            />
                        );
                    })}
                </VStack>

                <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    justifyItems={'center'}
                    alignItems={'start'}
                    position="sticky"
                    top="20"
                    maxHeight="90vh"
                    overflowY="auto"
                >
                    {selectedShift && (
                        <PairedShiftVisualizer onDataUpdate={handleDataUpdate} onDataDelete={handleDataUpdate} shift={selectedShift} />
                    )}
                </Box>
            </HStack>
        </Box>
    );
};

export default EmployeeAttendanceReportScreen;
