import axios, { AxiosRequestConfig } from 'axios';
import { getToken } from '../store/auth/authStore';

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use((config) => {
    const token = getToken();

    if (token) {
        config.headers.Authorization = token;
    }

    return config;
});

export function createImageUrl(url: string) {
    if (url) {
        return url.startsWith('http') ? url : `${'http://127.0.0.1:3333'}${url}`;
    }

    return '';
}

export function addParametersToString(parameters: Record<string, any>) {
    const params = new URLSearchParams();

    for (const key in parameters) {
        if (Array.isArray(parameters[key])) {
            parameters[key].forEach((value: string) => {
                params.append(key, value);
            });
        } else {
            params.append(key, parameters[key]);
        }
    }

    return `?${params.toString()}`;
}

export default {
    get: async <T = any>(url: string, config?: AxiosRequestConfig) => axiosInstance.get<T>(url, config),

    post: async <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => axiosInstance.post<T>(url, data, config),

    delete: async <T = any>(url: string, config?: AxiosRequestConfig) => axiosInstance.delete<T>(url, config),

    put: async <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => axiosInstance.put<T>(url, data, config),
};
