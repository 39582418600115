import { Box, Card, Center, HStack, Icon, Radio, Text, useTheme } from '@chakra-ui/react';
import { useGetZonesPagination } from '../../query/resource-hooks/company';
import { Zone } from '../../types';
import { FiMap } from 'react-icons/fi';
import InfiniteSelectList from '../../components/lists/InfiniteSelectList';
import { useState } from 'react';
import SearchInput from '../../components/ui/SearchInput';

interface ZoneListItemProps {
    zone: Zone;
    onClick?: () => void;
}

const ZoneSelectListItem: React.FC<ZoneListItemProps> = ({ zone, onClick }) => {
    const theme = useTheme();

    return (
        <Radio
            onClick={onClick}
            _hover={{
                bg: theme.colors.primary[500],
                color: 'white',
            }}
            cursor={'pointer'}
            borderRadius={'lg'}
            value={zone.id.toString()}
        >
            <HStack p={1}>
                <Icon as={FiMap} color="green.500" />
                <Text fontWeight={600} fontSize={'md'}>
                    {zone.name}
                </Text>
            </HStack>
        </Radio>
    );
};

export type ZonesSelectListProps = {
    onSelect?: (zone: Zone) => void;
    selectedZoneId?: number;
};

const ZonesSelectList: React.FC<ZonesSelectListProps> = ({ onSelect }) => {
    const [search, setSearch] = useState('');

    const zonesQuery = useGetZonesPagination({ search: search });

    return (
        <Box textAlign="center">
            <Center>
                <Card my={2} mx={{ base: 2, md: 0 }} width={'100%'} maxWidth={'lg'} borderRadius={'full'}>
                    <Box>
                        <SearchInput onSearch={setSearch} />
                    </Box>
                </Card>
            </Center>
            <Card m={{ base: 0, md: 4 }} p={4} sx={{ maxH: { base: '50vh', lg: '50vh' }, overflowY: 'auto' }}>
                <InfiniteSelectList<Zone>
                    data={zonesQuery.data}
                    fetchNextPage={zonesQuery.fetchNextPage}
                    hasNextPage={zonesQuery.hasNextPage}
                    isFetchingNextPage={zonesQuery.isFetchingNextPage}
                    renderItem={(zone, index) => <ZoneSelectListItem zone={zone} key={index} />}
                    isLoading={zonesQuery.isLoading || zonesQuery.isRefetching}
                    onSelect={onSelect}
                />
            </Card>
        </Box>
    );
};

export default ZonesSelectList;
