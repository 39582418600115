import {
    Badge,
    Box,
    Card,
    CardBody,
    Flex,
    Heading,
    Icon,
    Step,
    StepIndicator,
    Stepper,
    StepSeparator,
    StepStatus,
    StepTitle,
    Tooltip,
} from '@chakra-ui/react';
import { useGetAttendanceById } from '../../../query/resource-hooks/company';
import { AttendanceLoginDetail, PairedShift } from '../../../types';
import { AttendanceDetailCard } from '../AttendanceDetailCard';
import { FiCalendar, FiLogIn, FiLogOut } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { LoadingCard } from '../../ui/LoadingCard';
import { BsClock } from 'react-icons/bs';

type PairedShiftVisualizerProps = {
    shift: PairedShift;
    onDataUpdate?: () => void;
    onDataDelete?: () => void;
};

export const PairedShiftVisualizer: React.FC<PairedShiftVisualizerProps> = ({ shift, onDataUpdate, onDataDelete }) => {
    const intl = useIntl();
    const arrivalQuery = useGetAttendanceById(shift.arrival?.id);
    const departureQuery = useGetAttendanceById(shift.departure?.id);

    if (arrivalQuery.isLoading || departureQuery.isLoading) {
        return <LoadingCard />;
    }

    return (
        <Box pr={2}>
            <Card m={3}>
                <CardBody>
                    <Box>
                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                            <Flex alignItems={'center'}>
                                <Icon as={FiCalendar} mr={2} />
                                <Heading size="xs">{intl.formatDate(shift.arrival?.date || shift.departure?.date)}</Heading>
                            </Flex>
                            <Tooltip label={intl.formatMessage({id: 'attendanceReport.timeInShift'})}>

                            <Badge borderRadius={'xl'}>{shift.hoursInShift ?? 0} h</Badge>
                            </Tooltip>
                        </Flex>
                        <Flex alignItems={'center'} pt={1}>
                            <Icon as={BsClock} mr={1} />
                            <Heading size="xs">
                                {`${shift.arrival ? intl.formatDate(shift.arrival.date, { hour: 'numeric', minute: 'numeric' }) : '-'} - ${shift.departure ? intl.formatDate(shift.departure.date, { hour: 'numeric', minute: 'numeric' }) : '-'}`}
                            </Heading>
                        </Flex>
                    </Box>
                </CardBody>
            </Card>
            <Stepper index={2} orientation="vertical" gap="0">
                <Step key={'STEP_1'}>
                    <StepIndicator>
                        <StepStatus complete={<FiLogIn />} incomplete={<FiLogIn />} active={<FiLogIn />} />
                    </StepIndicator>
                    <Box pb={4}>
                        {arrivalQuery.data ? (
                            <AttendanceDetailCard
                                onDeleteSuccess={onDataDelete}
                                onUpdateSuccess={onDataUpdate}
                                attendance={arrivalQuery.data as AttendanceLoginDetail}
                            />
                        ) : (
                            <Box mb={10}>
                                <StepTitle>Príchod neznámi</StepTitle>
                            </Box>
                        )}
                    </Box>

                    <StepSeparator />
                </Step>

                <Step key={'STEP_2'}>
                    <StepIndicator>
                        <StepStatus complete={<FiLogOut />} incomplete={<FiLogOut />} active={<FiLogOut />} />
                    </StepIndicator>
                    <Box pb={4}>
                        {departureQuery.data ? (
                            <AttendanceDetailCard
                                onDeleteSuccess={onDataDelete}
                                onUpdateSuccess={onDataUpdate}
                                attendance={departureQuery.data as AttendanceLoginDetail}
                            />
                        ) : (
                            <Box>
                                <StepTitle>Odchod neznámi</StepTitle>
                            </Box>
                        )}
                    </Box>

                    <StepSeparator />
                </Step>
            </Stepper>
        </Box>
    );
};
