import { AxiosError } from 'axios';
import { Feature, Role, User } from '../../types';
import { Formik, Form } from 'formik';
import SchemaProvider from '../../utils/SchemaProvider';
import { useIntl } from 'react-intl';
import FormikTextInput from '../formik/FormikTextInput';
import { FormikSelect } from '../formik/FormikSelect';
import UserPolicy from '../../policies/UserPolicy';
import FormikPasswordInput from '../formik/FormikPasswordInput';
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    FormLabel,
    HStack,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { AccountMode } from '../../types/enums/AccountMode';
import { InferType } from 'yup';
import { useCreateEmployee, useUpdateEmployee } from '../../query/resource-hooks/company';
import { useAuthStore } from '../../store/auth/authStore';
import { ComponentProps, useRef } from 'react';
import ErrorHelper from '../../query/utils/ErrorHelper';
import ZoneSelectButton from '../ui/ZoneSelectButton';

export type EmployeeFormProps = Omit<ComponentProps<typeof Drawer>, 'children'> & {
    onSuccess?: (employee: User) => void;
    onError?: (error: AxiosError) => void;
    updatedUser?: User;
};

export const EmployeeForm: React.FC<EmployeeFormProps> = ({ updatedUser, onSuccess, onError, isOpen, onClose }) => {
    const intl = useIntl();
    const useCreateUser = useCreateEmployee(handleSuccess, handleError);
    const useUpdateUser = useUpdateEmployee(handleSuccess, handleError);
    const toast = useToast();
    const loggedUser = useAuthStore((state) => state.user)!;

    const firstField = useRef<HTMLInputElement | null>(null);

    const validationSchema = updatedUser ? SchemaProvider.getEmployeeUpdateSchema(intl) : SchemaProvider.getNewEmployeeSchema(intl);
    function handleSubmit(values: InferType<typeof validationSchema>) {
        if (updatedUser) {
            useUpdateUser.mutate({
                data: {
                    id: updatedUser.id,
                    firstName: values.firstName,
                    surname: values.surname,
                    role: values.role as Role,
                    password: values.password,
                    settings: {
                        accountMode: (values.mode as AccountMode) || null,
                        ...(values.role === Role.TEAM_ACCOUNT && { defaultZoneId: values.defaultZoneId }),
                    },
                },
            });
            return;
        } else {
            const createSchema = SchemaProvider.getNewEmployeeSchema(intl);
            const createData = values as InferType<typeof createSchema>;

            useCreateUser.mutate({
                userName: createData.userName,
                password: createData.password,
                firstName: createData.firstName,
                ...(createData.role !== Role.TEAM_ACCOUNT && {
                    surname: createData.surname,
                }),
                role: createData.role as Role,
                mode: createData.mode as AccountMode,
                defaultZoneId: createData.defaultZoneId,
            });
        }
    }

    function handleSuccess(data: User) {
        toast({
            title: intl.formatMessage({ id: updatedUser ? 'employeeUpdated' : 'employeeCreated' }),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
        onSuccess?.(data);
        handleClose();
    }

    function handleError(error: AxiosError) {
        const errorMessage = ErrorHelper.mapRequestErrorToIntlKey(error);

        toast({
            title: intl.formatMessage({ id: errorMessage }),
            status: 'error',
            duration: 8000,
            isClosable: true,
            position: 'top',
        });
        onError?.(error);
    }

    function handleClose() {
        useCreateUser.reset();
        useUpdateUser.reset();
        onClose();
    }

    return (
        <Drawer size={'md'} initialFocusRef={firstField} isOpen={isOpen} onClose={handleClose} placement="right">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                    {intl.formatMessage({ id: updatedUser ? 'user.updateUser' : 'user.createUser' })}
                </DrawerHeader>
                <DrawerBody>
                    <Formik
                        initialValues={{
                            userName: updatedUser?.userName || '',
                            firstName: updatedUser?.firstName || '',
                            surname: updatedUser?.surname || '',
                            role: updatedUser?.role || Role.EMPLOYEE,
                            password: '',
                            confirmPassword: '',
                            mode: updatedUser?.settings?.accountMode || '',
                            defaultZoneId: updatedUser?.settings?.defaultZoneId || null
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
                            <Form>
                                {updatedUser && <Text fontSize={'sm'}>*{intl.formatMessage({ id: 'user.updateUserFormInfo' })}</Text>}
                                <VStack>
                                    <FormikSelect
                                        fieldName="role"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        label={intl.formatMessage({ id: 'role' })}
                                        options={UserPolicy.assignableRoles(loggedUser).map((role) => ({
                                            value: role as string,
                                            label: intl.formatMessage({ id: role }),
                                        }))}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        variant={'filled'}
                                        afterChange={(value: string) => {
                                            if (value === Role.TEAM_ACCOUNT) {
                                                setFieldValue('mode', updatedUser?.settings?.accountMode || AccountMode.ATTENDANCE);
                                            } else {
                                                setFieldValue('mode', undefined);
                                                setFieldValue('defaultZoneId', null);
                                            }
                                        }}
                                    />

                                    {!updatedUser && (
                                        <FormikTextInput
                                            inputRef={firstField}
                                            name="userName"
                                            fieldName="userName"
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            label={intl.formatMessage({ id: 'user.username' })}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errors={errors}
                                            values={values}
                                            touched={touched}
                                        />
                                    )}

                                    <FormikTextInput
                                        inputRef={updatedUser && firstField}
                                        name="firstName"
                                        fieldName="firstName"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={
                                            values.role === Role.TEAM_ACCOUNT
                                                ? intl.formatMessage({ id: 'user.accountName' })
                                                : intl.formatMessage({ id: 'firstName' })
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                    />

                                    {values.role !== Role.TEAM_ACCOUNT && (
                                        <FormikTextInput
                                            name="surname"
                                            fieldName="surname"
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            label={intl.formatMessage({ id: 'surname' })}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errors={errors}
                                            values={values}
                                            touched={touched}
                                        />
                                    )}

                                    {values.role === Role.TEAM_ACCOUNT && (
                                        <>
                                            <FormikSelect
                                                fieldName="mode"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                label={intl.formatMessage({ id: 'accountMode' })}
                                                options={[
                                                    {
                                                        value: AccountMode.ATTENDANCE,
                                                        label: intl.formatMessage({ id: 'accountMode.' + AccountMode.ATTENDANCE }),
                                                    },
                                                    ...(!loggedUser.company?.billingAccount ||
                                                    UserPolicy.hasFeature(loggedUser.company.billingAccount, Feature.ADMINISTRATION)
                                                        ? [
                                                              {
                                                                  value: AccountMode.VEHICLE_MANAGEMENT,
                                                                  label: intl.formatMessage({
                                                                      id: 'accountMode.' + AccountMode.VEHICLE_MANAGEMENT,
                                                                  }),
                                                              },
                                                          ]
                                                        : []),
                                                ]}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                variant={'filled'}
                                            />
                                            {(!values.mode || values.mode === AccountMode.ATTENDANCE) && (
                                                <Box width={'100%'}>
                                                    <FormLabel>{`${intl.formatMessage({ id: 'defaultZone' })} (${intl.formatMessage({ id: 'optional' }).toLocaleLowerCase()})`}</FormLabel>
                                                    <ZoneSelectButton
                                                        canDelete
                                                        onDelete={() => setFieldValue('defaultZoneId', null)}
                                                        onSelect={(zone) => {
                                                            setFieldValue('defaultZoneId', zone.id);
                                                        }}
                                                        initialZone={updatedUser?.settings?.defaultZone}
                                                    />
                                                    {!!(errors.defaultZoneId && touched.defaultZoneId) ? (
                                                        <Text color="red.500" fontSize="sm">
                                                            {errors.defaultZoneId as string}
                                                        </Text>
                                                    ) : null}
                                                </Box>
                                            )}
                                        </>
                                    )}

                                    {(!updatedUser || UserPolicy.canUpdatePassword(loggedUser, updatedUser)) && (
                                        <>
                                            <FormikPasswordInput
                                                errors={errors}
                                                touched={touched}
                                                name="password"
                                                fieldName="password"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                label={intl.formatMessage({ id: 'password' })}
                                                values={values}
                                            />
                                            <FormikPasswordInput
                                                errors={errors}
                                                touched={touched}
                                                name="confirmPassword"
                                                fieldName="confirmPassword"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                label={intl.formatMessage({ id: 'confirmPassword' })}
                                                values={values}
                                            />
                                        </>
                                    )}
                                    <HStack justifyContent={'center'} mt={2}>
                                        <Button
                                            type="submit"
                                            colorScheme="green"
                                            isLoading={useCreateUser.isLoading || useUpdateUser.isLoading}
                                            isDisabled={useCreateUser.isLoading || useUpdateUser.isLoading}
                                        >
                                            {intl.formatMessage({ id: updatedUser ? 'save' : 'create' })}
                                        </Button>
                                    </HStack>
                                </VStack>
                            </Form>
                        )}
                    </Formik>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
