import { FormControl, FormLabel, Input, InputGroup, Text } from '@chakra-ui/react';
import { memo } from 'react';

type Props = React.ComponentProps<typeof Input> & {
    label: string;
    values: Record<string, any>;
    fieldName: string;
    handleChange: (field: string) => any;
    errors: Record<string, any>;
    handleBlur: (field: string) => any;
    touched: Record<string, any>;
    inputRef?: React.RefObject<HTMLInputElement>;
    afterChange?: (value: string) => void;
};

const FormikTextInput: React.FC<Props> = ({
    values,
    label,
    fieldName,
    handleBlur,
    handleChange,
    errors,
    touched,
    afterChange,
    inputRef,
    ...restProps
}) => {
    const isDateTimeInput = restProps.type && ['datetime-local'].includes(restProps.type);

    const handleInput = (text: string) => {
        handleChange(fieldName)(text);

        if (afterChange) {
            afterChange(text);
        }
    };

    const formatDateForInput = (stringDate: string) => {
        if (!stringDate) {
            return '';
        }

        const date = new Date(stringDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JS months are 0-indexed
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    return (
        <FormControl isDisabled={restProps.isDisabled} isInvalid={!!(errors[fieldName] && touched[fieldName])}>
            <FormLabel htmlFor={fieldName}>{label}</FormLabel>
            <InputGroup>
                <Input
                    id={fieldName}
                    isInvalid={!!(errors[fieldName] && touched[fieldName])}
                    onBlur={handleBlur(fieldName)}
                    onChange={(e) => handleInput(e.target.value)}
                    errorBorderColor="red.500"
                    inputMode="text"
                    value={isDateTimeInput ? formatDateForInput(values[fieldName]) : values[fieldName]}
                    ref={inputRef}
                    {...restProps}
                ></Input>
            </InputGroup>
            {!!(errors[fieldName] && touched[fieldName]) ? (
                <Text color="red.500" fontSize="sm">
                    {errors[fieldName] as string}
                </Text>
            ) : null}
        </FormControl>
    );
};

export default memo(FormikTextInput);
