import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement, Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

type SearchProps = React.ComponentProps<typeof Input> & {
    onSearch: (value: string) => void;
};

const SearchInput: React.FC<SearchProps> = ({ onSearch, ...props }) => {
    const intl = useIntl();
    const [displayedValue, setDisplayedValue] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => {
            onSearch(displayedValue);
        }, 1000); // 1 second debounce delay

        return () => {
            clearTimeout(timer);
        };
    }, [displayedValue, onSearch]);

    return (
        <Box borderRadius={'full'} overflow="hidden">
            <InputGroup justifyContent={'center'}>
                <InputLeftElement pointerEvents="none" h="100%">
                    <SearchIcon />
                </InputLeftElement>
                <Input
                    onChange={(e) => setDisplayedValue(e.target.value)}
                    size={'lg'}
                    {...props}
                    type="search"
                    placeholder={intl.formatMessage({ id: 'search' })}
                    borderRadius={'full'}
                />
            </InputGroup>
        </Box>
    );
};

export default SearchInput;
