import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    SimpleGrid,
    Text,
    useBreakpointValue,
    useColorModeValue,
    useDisclosure,
    useTheme,
    VStack,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { AboutLayout } from '../../components/about/AboutHeader';
import './About.css';
import { Link } from 'react-router-dom';
import { FiSun, FiZap, FiLock, FiMap, FiArchive, FiCast, FiKey, FiUsers, FiSend, FiEye } from 'react-icons/fi';
import { Article } from '../../components/about/Article';
import AboutFooter from '../../components/about/Footer';
import mobileBannerMockup from '../../images/mobileBannerMockup.webp';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { StartTimeline } from '../../components/about/StartTimeline/StartTimeLine';
import { FeatureTabs } from '../../components/about/FeatureTabs/FeatureTabs';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { PricingTable } from '../../components/about/Pricing/PricingTable';
import { References } from '../../components/about/References/References';
import { SectionTitle } from '../../components/about/SectionTitle';
import { ContactModal } from '../../components/about/contact/ContactModal';

const AboutPage: React.FC = () => {
    const intl = useIntl();
    const theme = useTheme();

    // Custom event for AOS scroll animation start - to be same on all browsers
    useEffect(() => {
        const timer = setTimeout(() => {
            document.dispatchEvent(new Event('appLoaded'));
        }, 300);

        return () => clearTimeout(timer);
    }, []);

    // little workaround - create-react-app does not support inport images to css (or thats what I think) - for now fixed with inline css
    const bannerStyleBreak = useBreakpointValue({
        base: {
            height: '200px',
            width: '100%',
            backgroundSize: 'cover',
            backgroundImage: `linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 7%), url(${mobileBannerMockup})`,
        },
        sm: {},
    });

    const contactModalDisclosure = useDisclosure();

    const bluredCircleSize = useBreakpointValue({ 'base': 0, 'md': 200, 'lg': 300, 'xl': 400, '2xl': 1000 });
    return (
        <AboutLayout>
            {/* HERO */}
            <Box
                justifyContent={'center'}
                display={'flex'}
                p={{ base: 0, md: 10 }}
                backgroundColor={'black'}
                position="relative"
                className="block--skewed-left"
                overflow={'hidden'}
            >
                <Box
                    position="absolute"
                    bottom={(bluredCircleSize ?? 100) * -0.8}
                    right={(bluredCircleSize ?? 100) / -2}
                    width={bluredCircleSize}
                    height={bluredCircleSize}
                    background="rgba(255, 255, 255, 0.5)"
                    borderRadius="50%"
                    filter={`blur(${200}px)`}
                    zIndex={-1}
                    display={{ base: 'none', md: 'block' }}
                />
                <SimpleGrid className="container" mb={4} columns={{ base: 1, md: 2 }}>
                    <Flex
                        order={{ base: 2, md: 0 }}
                        flexDir={'column'}
                        px={{ base: 10, md: 0 }}
                        pb={{ base: 5, md: 10, lg: 20 }}
                        align={{ base: 'center', md: 'start' }}
                        justifyContent={'center'}
                        data-aos="fade-left"
                    >
                        <Heading my={{ base: 2, md: 5 }} size={'2xl'} color={'white'}>
                            {intl.formatMessage({ id: 'about.title' })}
                        </Heading>

                        <Text fontSize={'lg'} fontWeight={600} color={'gray.400'}>
                            {intl.formatMessage({ id: 'about.subtitle' })}
                        </Text>
                        <Button
                            textTransform={'uppercase'}
                            mt={5}
                            borderRadius={'full'}
                            height={'50px'}
                            px={10}
                            colorScheme="red"
                            as={Link}
                            to={'/register'}
                            className="animated-button"
                        >
                            {intl.formatMessage({ id: 'about.startButton' })}
                        </Button>
                        <HStack gap={5} mt={3}>
                            <a href="https://play.google.com/store/apps/details?id=com.supervizer">
                                <Image maxH={50} src={`${process.env.PUBLIC_URL}/images/google-play-icon.svg`} alt="Google Play Store" />
                            </a>
                            <a href="https://apps.apple.com/sk/app/supervizer/id6479220108">
                                <Image maxH={50} src={`${process.env.PUBLIC_URL}/images/apple-store-icon.svg`} alt="Apple app store" />
                            </a>
                        </HStack>
                    </Flex>
                    <Center data-aos="zoom-in" order={{ base: 0, md: 2 }} style={bannerStyleBreak} justifyContent={'center'}>
                        <Image
                            maxWidth={{ base: 200, md: 320 }}
                            display={{ base: 'none', sm: 'flex' }}
                            src={`${process.env.PUBLIC_URL}/images/bannerMockup.webp`}
                            alt={'banner image'}
                        />
                    </Center>
                </SimpleGrid>
            </Box>

            {/* FEATURES */}
            <SectionTitle
                title={intl.formatMessage({ id: 'about.knowledge' })}
                subtitle={intl.formatMessage({ id: 'about.knowledge.subtitle' })}
            />

            <Box>
                <Box className="container">
                    <SimpleGrid mb={5} px={7} columns={{ base: 1, md: 2 }} spacing={10}>
                        <Box data-aos="zoom-in-right">
                            <Article
                                icon={<Icon color={theme.colors.primary[500]} boxSize={8} as={FiZap} />}
                                text={intl.formatMessage({ id: 'about.easy.text' })}
                                title={intl.formatMessage({ id: 'about.easy.subtitle' })}
                            />
                        </Box>

                        <Box data-aos="zoom-in-left">
                            <Image
                                className="feature__image feature__image__right"
                                src={`${process.env.PUBLIC_URL}/images/simple_use.svg`}
                            />
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid mb={5} px={7} columns={{ base: 1, md: 2 }} spacing={10}>
                        <Box data-aos="zoom-in-right" order={{ md: 0, base: 2 }}>
                            <Image className="feature__image" src={`${process.env.PUBLIC_URL}/images/accessible.svg`} />
                        </Box>
                        <Box data-aos="zoom-in-left">
                            <Article
                                icon={<Icon color={theme.colors.primary[500]} boxSize={8} as={FiSun} />}
                                text={intl.formatMessage({ id: 'about.accessible.text' })}
                                title={intl.formatMessage({ id: 'about.accessible.subtitle' })}
                            />
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid mb={5} px={7} columns={{ base: 1, md: 2 }} spacing={10}>
                        <Box data-aos="zoom-in-right">
                            <Article
                                icon={<Icon color={theme.colors.primary[500]} boxSize={8} as={FiLock} />}
                                text={intl.formatMessage({ id: 'about.face.text' })}
                                title={intl.formatMessage({ id: 'about.face.subtitle' })}
                            />
                        </Box>
                        <Box data-aos="zoom-in-left">
                            <Image className="feature__image__right" src={`${process.env.PUBLIC_URL}/images/face_recognition.svg`} />
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid mb={5} px={7} columns={{ base: 1, md: 2 }} spacing={10}>
                        <Box data-aos="zoom-in-right" order={{ md: 0, base: 2 }}>
                            <Image className="feature__image" src={`${process.env.PUBLIC_URL}/images/zone_map.svg`} />
                        </Box>
                        <Box data-aos="zoom-in-left">
                            <Article
                                icon={<Icon color={theme.colors.primary[500]} boxSize={8} as={FiMap} />}
                                text={intl.formatMessage({ id: 'about.position.text' })}
                                title={intl.formatMessage({ id: 'about.position.subtitle' })}
                            />
                        </Box>
                    </SimpleGrid>
                </Box>
            </Box>

            <Box backgroundColor={'black'} className="block--skewed-left" minH={'100vh'}>
                <Box data-aos="zoom-in-up">
                    <Heading py={10} color={theme.colors.whiteAlpha[900]} textAlign={'center'}>
                        {intl.formatMessage({ id: 'about.whatYouFind' })}
                    </Heading>
                    <Box width={'100%'}>
                        <FeatureTabs />
                    </Box>
                </Box>
            </Box>

            <SectionTitle title={intl.formatMessage({ id: 'about.howItWorks' })} />
            <HStack px={2} justify={'center'} data-aos="zoom-in-up">
                <Box
                    textAlign={'center'}
                    boxShadow={'md'}
                    maxW={'900px'}
                    background={useColorModeValue(theme.colors.whiteAlpha[800], theme.colors.gray[700])}
                    p={2}
                    borderRadius={'sm'}
                >
                    <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.400')}>
                        {intl.formatMessage({ id: 'about.timeline.header.whatItIs' })}
                    </Text>
                    <Divider py={1} />
                    <Text color={useColorModeValue('gray.600', 'gray.400')}>
                        {intl.formatMessage({ id: 'about.timeline.header.webApp' })}
                    </Text>
                </Box>
            </HStack>
            <Box className=".block--skewed-right">
                <Box className="container">
                    <StartTimeline />
                </Box>
            </Box>
            <HStack justify={'center'}>
                <Box
                    mb={4}
                    maxW={'900px'}
                    boxShadow={'md'}
                    background={useColorModeValue(theme.colors.whiteAlpha[800], theme.colors.gray[700])}
                    borderRadius={'sm'}
                    p={2}
                >
                    <SimpleGrid columns={{ base: 1, md: 2 }}>
                        <Box>
                            <Heading size={'md'}>{intl.formatMessage({ id: 'docs.needHelp.title' })}</Heading>
                            <Text fontSize={'sm'} fontWeight={600}>
                                {intl.formatMessage({ id: 'docs.needHelp.text' })}
                            </Text>
                        </Box>
                        <Center justifyContent={'center'}>
                            <Button
                                variant={'outline'}
                                rightIcon={<ChevronRightIcon />}
                                to={'/docs/home'}
                                textTransform={'uppercase'}
                                borderRadius={'full'}
                                height={'40px'}
                                px={6}
                                as={Link}
                            >
                                {intl.formatMessage({ id: 'about.findOutMore' })}
                            </Button>
                        </Center>
                    </SimpleGrid>
                </Box>
            </HStack>

            <Box backgroundColor={'black'} className="block--skewed-left">
                <Box className="container" px={5} pt={8}>
                    <Heading data-aos="zoom-in-up" color={'white'} textAlign={'center'}>
                        {intl.formatMessage({ id: 'about.features.title' })}
                    </Heading>
                    <SimpleGrid gap={10} pt={5} columns={{ base: 1, md: 2 }}>
                        <Box
                            position="relative"
                            overflow="hidden"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            order={{ md: 0, base: 2 }}
                            data-aos="zoom-in"
                        >
                            <Image
                                maxW={'700px'}
                                src={`${process.env.PUBLIC_URL}/images/introClip.png`}
                                objectFit="cover"
                                objectPosition="center"
                                justifySelf="center"
                            />
                        </Box>
                        <VStack gap={6} data-aos="zoom-in-left">
                            <Article
                                icon={<Icon color={theme.colors.primary[500]} boxSize={8} as={FiArchive} />}
                                text={intl.formatMessage({ id: 'about.informationWithYou.text' })}
                                title={intl.formatMessage({ id: 'about.informationWithYou.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                            <Article
                                icon={<Icon color={theme.colors.primary[500]} boxSize={8} as={FiCast} />}
                                text={intl.formatMessage({ id: 'about.functionsWithYou.text' })}
                                title={intl.formatMessage({ id: 'about.functionsWithYou.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                            <Article
                                icon={<Icon color={theme.colors.primary[500]} boxSize={8} as={FiKey} />}
                                text={intl.formatMessage({ id: 'about.accessRights.text' })}
                                title={intl.formatMessage({ id: 'about.accessRights.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                        </VStack>
                    </SimpleGrid>
                    <SimpleGrid pt={5} gap={10} columns={{ base: 1, md: 2 }}>
                        <VStack data-aos="zoom-in-right" gap={6}>
                            <Article
                                icon={<Icon color={theme.colors.primary[500]} boxSize={8} as={FiUsers} />}
                                text={intl.formatMessage({ id: 'about.teamAccount.text' })}
                                title={intl.formatMessage({ id: 'about.teamAccount.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                            <Article
                                icon={<Icon color={theme.colors.primary[500]} boxSize={8} as={FiSend} />}
                                text={intl.formatMessage({ id: 'about.shareInformation.text' })}
                                title={intl.formatMessage({ id: 'about.shareInformation.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                            <Article
                                icon={<Icon color={theme.colors.primary[500]} boxSize={8} as={FiEye} />}
                                text={intl.formatMessage({ id: 'about.overview.text' })}
                                title={intl.formatMessage({ id: 'about.overview.title' })}
                                textColor="gray.400"
                                titleColor="white"
                            />
                        </VStack>
                        <Box
                            data-aos="zoom-in"
                            position="relative"
                            overflow="hidden"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            order={{ md: 0, base: 2 }}
                        >
                            <Image
                                maxW={'700px'}
                                src={`${process.env.PUBLIC_URL}/images/introClip.png`}
                                objectFit="cover"
                                objectPosition="center"
                                justifySelf="center"
                            />
                        </Box>
                    </SimpleGrid>
                </Box>
            </Box>

            <HStack spacing={2} my={10} maxWidth={'100%'} data-aos="zoom-in-up">
                <Divider borderColor={'gray.400'} />
                <Box data-aos="fade-up" width={'100%'} minWidth={'fit-content'}>
                    <Heading color={theme.colors.primary[500]} textAlign={'center'}>
                        {intl.formatMessage({ id: 'pricing.title' })}
                    </Heading>
                    <Text fontSize={'lg'} fontWeight={'bold'} textAlign={'center'}>
                        {intl.formatMessage({ id: 'pricing.testPeriod.title' })}
                    </Text>
                </Box>
                <Divider borderColor={'gray.400'} />
            </HStack>
            <Box className="container" data-aos="zoom-in-up" textAlign={'center'}>
                <Text px={2} fontWeight={'600'}>
                    {intl.formatMessage({ id: 'pricing.testPeriod.text' })}
                </Text>
            </Box>
            <Box className="container" maxWidth={'700px'} mb={12} data-aos="zoom-in-up">
                <PricingTable />
            </Box>

            <HStack spacing={2} my={10} maxWidth={'100%'} data-aos="zoom-in-up">
                <Divider borderColor={'gray.400'} />
                <Box data-aos="fade-up" width={'100%'} minWidth={'fit-content'}>
                    <Heading color={theme.colors.primary[500]} textAlign={'center'}>
                        {intl.formatMessage({ id: 'about.references.title' })}
                    </Heading>
                    <Text fontSize={'lg'} fontWeight={'bold'} textAlign={'center'}>
                        {intl.formatMessage({ id: 'about.references.subtitle' })}
                    </Text>
                </Box>
                <Divider borderColor={'gray.400'} />
            </HStack>
            <Box className="container" mb={12} data-aos="zoom-in-up">
                <References />
            </Box>

            <Box className="container">
                <Box mx={5} className="callout-signup" p={5} bg={theme.colors.primary[500]} borderRadius={'md'}>
                    <SimpleGrid columns={{ base: 1, md: 2 }}>
                        <Flex
                            pb={{ base: 3, md: 0 }}
                            flexDir={'column'}
                            display={'flex'}
                            align={{ base: 'center', md: 'start' }}
                            justifyContent={'center'}
                        >
                            <Heading size={'lg'} color={'white'}>
                                {intl.formatMessage({ id: 'about.readyToStart.title' })}
                            </Heading>
                            <Text align={{ base: 'center', md: 'left' }} size={'lg'} fontWeight={600} color={'gray.200'}>
                                {intl.formatMessage({ id: 'about.readyToStart.text' })}
                            </Text>
                        </Flex>
                        <Center justifyContent={'center'}>
                            <VStack>
                                <Button
                                    to={'/register'}
                                    textTransform={'uppercase'}
                                    borderRadius={'full'}
                                    height={'50px'}
                                    px={10}
                                    colorScheme="red"
                                    as={Link}
                                >
                                    {intl.formatMessage({ id: 'login.register' })}
                                </Button>
                                <Button onClick={contactModalDisclosure.onOpen} variant={'ghost'} colorScheme="whiteAlpha" textColor={'gray.200'}>
                                    {intl.formatMessage({ id: 'contactUs.title' })}
                                </Button>
                            </VStack>
                        </Center>
                    </SimpleGrid>
                </Box>
            </Box>

            <AboutFooter />
            <ContactModal isOpen={contactModalDisclosure.isOpen} onClose={contactModalDisclosure.onClose} />
        </AboutLayout>
    );
};

export default AboutPage;
