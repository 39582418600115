import { create } from 'zustand';

export type SuNotification = {
    title: string;
    description?: string;
    type: 'success' | 'error' | 'info' | 'warning';
    code?: string;
    actionText?: string;
    action?: () => void;
};

type NotificationStore = {
    notifications: SuNotification[];
    pushNotification: (notification: SuNotification) => void;
    removeNotification: (index: number) => void;
    clearNotifications: () => void;
};

const useNotificationStore = create<NotificationStore>((set) => ({
    notifications: [],
    pushNotification: (notification: SuNotification) =>
        set((state) => {
            if (state.notifications.some((n) => n.code === notification.code)) {
                return state;
            }

            return {
                notifications: [...state.notifications, notification],
            };
        }),
    removeNotification: (index: number) => set((state) => ({ notifications: state.notifications.filter((_, i) => i !== index) })),
    clearNotifications: () => set({ notifications: [] }),
}));

export { useNotificationStore };
