import { BasePaginationFilter, DeviceData, RegisteredDevice, RegisteredDevicePagination } from '../../types';
import apiClient, { addParametersToString } from '../apiClient';

class DeviceService {
    async getUserDevices(userId: number, filter?: BasePaginationFilter) {
        return (
            await apiClient.get<RegisteredDevicePagination>(
                `/api/company/employees/${userId}/devices${addParametersToString(filter || {})}`
            )
        ).data;
    }

    async getDeviceDetails(userId: number, deviceId: number) {
        return (await apiClient.get<RegisteredDevice>(`/api/company/employees/${userId}/devices/${deviceId}`)).data;
    }

    async updateRegisteredDevice(userId: number, deviceId: number, data: DeviceData) {
        return (await apiClient.put<RegisteredDevice>(`/api/company/employees/${userId}/devices/${deviceId}`, data)).data;
    }

    async logoutDevice(userId: number, deviceId: number) {
        (await apiClient.delete<void>(`/api/company/employees/${userId}/devices/${deviceId}`));
    }
}

export default new DeviceService();
