import { useNavigate } from 'react-router-dom';
import SecondPageHeader from '../../components/ui/SecondPageHeader';
import { useAuthStore } from '../../store/auth/authStore';
import { useIntl } from 'react-intl';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { UserProfileCard } from '../../components/employee/UserProfileCard';
import { useGetCompanyDetail, useGetUserProfileDetail } from '../../query/resource-hooks/company';
import CompanyCard from '../../components/company/CompanyCard';

const ProfileScreen: React.FC = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const user = useAuthStore((s) => s.user);
    const userDetail = useGetUserProfileDetail(user?.id!);
    const companyDetail = useGetCompanyDetail();
    return (
        <>
            <SecondPageHeader title={intl.formatMessage({ id: 'profile' })} onBack={() => navigate(-1)} />
            <Box display={'flex'} width={'100%'} pb={4} justifyContent={'center'} px={{ base: 0, xl: 2 }} pt={4}>
                <SimpleGrid minW={300} width={'100%'} gap={4} columns={{ base: 1, xl: 2 }}>
                    <Box display={'flex'} width={'100%'} justifyContent={{ base: 'center', xl: 'end' }}>
                        <UserProfileCard onPhotoUpdate={() => userDetail.refetch()} height={'100%'} user={userDetail?.data || user || undefined} />
                    </Box>
                    <Box display={'flex'} width={'100%'} justifyContent={{ base: 'center', xl: 'start' }}>
                        <CompanyCard width={'100%'} company={companyDetail.data!} />
                    </Box>
                </SimpleGrid>
            </Box>
        </>
    );
};

export default ProfileScreen;
