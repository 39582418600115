export enum StripeSubscriptionStatus {
    INCOMPLETE = 'incomplete',
    INCOMPLETE_EXPIRED = 'incomplete_expired',
    TRIALING = 'trialing',
    ACTIVE = 'active',
    PAST_DUE = 'past_due',
    CANCELLED = 'canceled',
    UNPAID = 'unpaid',
  }
  
export enum InternalSubscriptionStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    INITIALIZING = 'initializing',
}
