import SidebarWithHeader from "../components/navbar/Navbar"
import { Outlet } from "react-router-dom"


const BaseLayout: React.FC = () => {

    return (
        <SidebarWithHeader>
            <Outlet />
        </SidebarWithHeader>
    )
}

export default BaseLayout;