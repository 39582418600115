import React, { ReactNode, useEffect, useState } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    useTheme,
    useColorMode,
    Heading,
    Button,
} from '@chakra-ui/react';
import { FiHome, FiMenu, FiChevronDown, FiCalendar, FiUsers, FiMap, FiArchive } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FaBuilding, FaEuroSign, FaMoon, FaSun } from 'react-icons/fa';
import { useAuthStore } from '../../store/auth/authStore';
import { useIntl } from 'react-intl';
import { User } from '../../types';
import { MdLanguage } from 'react-icons/md';
import { useSettingsStore } from '../../store/ui/SettingsStore';
import LanguageSelectModal from '../ui/LanguageSelectModal';
import NotificationList from '../ui/NotificationsList';
import { ArrowBackIcon, SettingsIcon } from '@chakra-ui/icons';

interface LinkItemProps {
    name: string;
    icon: IconType;
    href?: string;
}

const LinkItems: Array<LinkItemProps> = [
    { name: 'home.title', icon: FiHome, href: '/docs/home' },
    { name: 'tab.attendance', icon: FiCalendar, href: '/docs/attendance' },
    { name: 'tab.Employees', icon: FiUsers, href: 'docs/employees' },
    { name: 'tab.Zones', icon: FiMap, href: 'docs/zones' },
    { name: 'billing.subscription', icon: FaEuroSign, href: 'docs/subscription' },
    { name: 'company', icon: FaBuilding, href: 'docs/company' },
    { name: 'feature.administration.title', icon: FiArchive, href: 'docs/administration' },
];

export default function DocsSidebarWithHeader({ children }: { children: ReactNode }) {
    const user = useAuthStore((state) => state.user)!;
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent isLoggedUser={user !== null} onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent>
                    <SidebarContent isLoggedUser={user !== null} onClose={onClose} />
                </DrawerContent>
            </Drawer>

            <Box position={'fixed'} width="full" zIndex="sticky">
                <MobileNav user={user} onOpen={onOpen} />
            </Box>
            <Box ml={{ base: 0, md: 60 }} pt={20}>
                <Box px={2}>
                    <NotificationList />
                </Box>
                {children}
            </Box>
        </Box>
    );
}

const MenuColorSwitcher = () => {
    const { toggleColorMode } = useColorMode();
    const intl = useIntl();
    const text = useColorModeValue(intl.formatMessage({ id: 'colorMode.dark' }), intl.formatMessage({ id: 'colorMode.light' }));
    const SwitchIcon = useColorModeValue(FaMoon, FaSun);

    return (
        <MenuItem onClick={toggleColorMode} icon={<SwitchIcon />} aria-label={`Switch to ${text} mode`}>
            {text}
        </MenuItem>
    );
};

interface SidebarProps extends BoxProps {
    onClose: () => void;
    isLoggedUser?: boolean;
}

const SidebarContent = ({ onClose, isLoggedUser, ...rest }: SidebarProps) => {
    const intl = useIntl();
    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}
        >
            <Flex direction="column" h="full" justifyContent="space-between">
                <Box>
                    <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                        <Link to={'/'}>
                            <Avatar src={`${process.env.PUBLIC_URL}/logo_square.jpg`}></Avatar>
                        </Link>
                        <Flex flexDir={'column'}>
                            <Text pl={1} fontSize="xl" fontFamily="monospace" fontWeight="bold">
                                Supervizer
                            </Text>
                            <Text noOfLines={1} pl={1} fontSize="md" color="gray.400">
                                Návody
                            </Text>
                        </Flex>
                        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
                    </Flex>
                    {LinkItems.map((link) => (
                        <NavItem key={link.name} icon={link.icon} onClick={onClose} href={link.href}>
                            {intl.formatMessage({ id: link.name })}
                        </NavItem>
                    ))}
                </Box>
                <Box>
                    <Button
                        as={Link}
                        to={isLoggedUser ? '/home' : '/'}
                        py={4}
                        leftIcon={<Icon as={ArrowBackIcon} />}
                        width={'full'}
                        variant={'ghost'}
                    >
                        {intl.formatMessage({ id: 'docs.backToApp' })}
                    </Button>
                </Box>
            </Flex>
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    href?: string;
    children: ReactText;
}
const NavItem = ({ icon, children, href, ...rest }: NavItemProps) => {
    const theme = useTheme();
    const selectedColor = useColorModeValue(theme.colors.gray[200], theme.colors.gray[700]);

    return (
        <NavLink to={href || '#'} style={{ textDecoration: 'none' }}>
            {({ isActive }) => (
                <Flex
                    align="center"
                    p="4"
                    mx="4"
                    borderRadius="lg"
                    role="group"
                    cursor="pointer"
                    bg={isActive ? selectedColor : undefined}
                    boxShadow={isActive ? 'lg' : undefined}
                    _hover={{
                        bg: theme.colors.secondary[500],
                        color: 'black',
                    }}
                    {...rest}
                >
                    {icon && <Icon mr="4" fontSize="16" as={icon} />}
                    {children}
                </Flex>
            )}
        </NavLink>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
    user: User;
}
const MobileNav = ({ onOpen, user, ...rest }: MobileProps) => {
    const navigation = useNavigate();
    const intl = useIntl();
    const location = useLocation();

    const languageDisclosure = useDisclosure();

    const language = useSettingsStore((state) => state.language);

    const [urlStart, setUrlStart] = useState('');

    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean);

        if (pathSegments.length > 0) {
            setUrlStart(pathSegments[1]); // Step 5: Update the state with the first word
        }
    }, [location]);

    const menuBgColor = useColorModeValue('white', 'gray.900');
    const menuBorderColor = useColorModeValue('gray.200', 'gray.700');
    const menuIconColor = useColorModeValue('primary.500', 'primary.300');

    const getHeadingByUrl = () => {
        switch (urlStart) {
            case 'attendance':
                return intl.formatMessage({ id: 'tab.attendance' });
            case 'employees':
                return intl.formatMessage({ id: 'tab.Employees' });
            case 'zones':
                return intl.formatMessage({ id: 'tab.Zones' });
            case 'home':
                return intl.formatMessage({ id: 'home.title' });
            case 'company':
                return intl.formatMessage({ id: 'company' });
            case 'subscription':
                return intl.formatMessage({ id: 'billing.subscription' });
            case 'administration':
                return intl.formatMessage({ id: 'feature.administration.title' });
            default:
                return '';
        }
    };

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}
        >
            <LanguageSelectModal isOpen={languageDisclosure.isOpen} onClose={languageDisclosure.onClose} />

            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <HStack flex={1} justifyContent={{ base: 'center', md: 'start' }}>
                <Heading size={'md'}>
                    <Text fontSize={'sm'} fontFamily={'monospace'} fontWeight={400}>
                        {intl.formatMessage({ id: 'docs.title' })}
                    </Text>
                    {getHeadingByUrl()}
                </Heading>
            </HStack>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                            {user ? (
                                <HStack>
                                    <Avatar size={'sm'} name={user?.surname || user?.firstName} />
                                    <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                                        <Text fontSize="sm">{`${user?.firstName} ${user?.surname || ''}`}</Text>
                                        <Text fontSize="xs" color="gray.600">
                                            {intl.formatMessage({ id: user.role })}
                                        </Text>
                                    </VStack>
                                    <Box display={{ base: 'none', md: 'flex' }}>
                                        <FiChevronDown />
                                    </Box>
                                </HStack>
                            ) : (
                                <SettingsIcon color={menuIconColor} boxSize={'20px'} />
                            )}
                        </MenuButton>
                        <MenuList bg={menuBgColor} borderColor={menuBorderColor}>
                            <MenuItem onClick={() => navigation(user ? '/home' : '/')}>
                                {intl.formatMessage({ id: 'docs.backToApp' })}
                            </MenuItem>
                            <MenuDivider />
                            <MenuColorSwitcher />
                            <MenuItem onClick={languageDisclosure.onOpen} icon={<MdLanguage />} command={language || ''}>
                                {intl.formatMessage({ id: 'language' })}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};
