import { Box, Button, Heading, Text, useTheme } from '@chakra-ui/react';
import { InfoIcon, CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';

type ResultScreenProps = {
    text: string;
    title: string;
    actionText?: string;
    onAction?: () => void;
    type?: 'success' | 'error' | 'info';
};

export const InfoResult: React.FC<ResultScreenProps> = ({ text, title, onAction, actionText, type = 'info' }) => {
    const theme = useTheme();

    const renderIcon = () => {
        switch (type) {
            case 'success':
                return <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
            case 'error': 
                return  <WarningTwoIcon boxSize={'50px'} color={'red.500'} />
            default:
                return <InfoIcon boxSize={'50px'} color={theme.colors.primary[500]} />
        }
    }

    return (
        <Box textAlign="center" py={10} px={6}>

            {renderIcon()}
            <Heading color={type === 'error' ? 'red.500' : undefined} as="h2" size="xl" mt={6} mb={2}>
                {title}
            </Heading>
            <Text color={'gray.500'}>{text}</Text>
            {onAction && actionText && (
                <Button marginTop={3} background={theme.colors.primary[500]} onClick={() => onAction()}>
                    {actionText}
                </Button>
            )}
        </Box>
    );
};
