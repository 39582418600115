import {
    Button,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
} from '@chakra-ui/react';
import { ComponentProps, useState } from 'react';
import { User } from '../../../types';
import { adjustDateToUserTimezone, RangeDatepicker } from '../../calendarPicker';
import { useIntl } from 'react-intl';

type SimpleReportModalProps = Omit<ComponentProps<typeof Modal>, 'children'> & {
    user: User;
    onDateRangeSubmit?: (dateFrom: string, dateTo: string) => void;
};

export const SimpleAttendanceReportModal: React.FC<SimpleReportModalProps> = ({ isOpen, onClose, user, onDateRangeSubmit }) => {
    const intl = useIntl();

    const getInitDates = (): Date[] => {
        const currentDate = new Date();
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59);
        return [startOfMonth, endOfMonth];
    };

    const [selectedDates, setSelectedDates] = useState<Date[]>(getInitDates());

    function handleSubmit() {
        if (selectedDates.length < 2) {
            return;
        }

        onDateRangeSubmit?.(
            adjustDateToUserTimezone(selectedDates[0]).toISOString(),
            new Date(adjustDateToUserTimezone(selectedDates[1]).setUTCHours(23, 59, 59)).toISOString()
        );
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{intl.formatMessage({ id: 'attendanceReport.forUser' }, { name: user?.fullName ?? '' })}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack>
                        <FormLabel> {intl.formatMessage({ id: 'period' })}</FormLabel>
                        <RangeDatepicker
                            selectedDates={selectedDates}
                            onDateChange={(dates) => {
                                setSelectedDates(dates);
                            }}
                        />
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleSubmit}>Vytvoriť</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
