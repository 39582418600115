import { createBrowserRouter, Navigate } from 'react-router-dom';
import HomeScreen from '../screens/home/HomeScreen';
import AttendanceList from '../screens/attendance/AttendanceList';
import EmployeesList from '../screens/employees/EmployeesList';
import LoginScreen from '../screens/auth/LoginScreen';
import { PrivateRoutes } from './PrivateRoutes';
import AccessDeniedForUserScreen from '../screens/results/AccesDeniedForUserScreen';
import ZonesList from '../screens/zones/ZonesList';
import BaseLayout from './BaseLayout';
import PageNotFound from '../screens/results/PageNotFound';
import EmployeeDetailScreen from '../screens/employees/EmployeeDetail';
import AttendanceDetailScreen from '../screens/attendance/AttendanceDetailScreen';
import AboutPage from '../screens/about/About';
import ZoneDetailScreen from '../screens/zones/ZoneDetailScreen';
import ProfileScreen from '../screens/profile/ProfileScreen';
import RegisterScreen from '../screens/register/RegisterScreen';
import CommonResultScreen from '../screens/results/CommonResultScreen';
import TokenPage from '../screens/results/TokenPage';
import PolicyScreen from '../screens/policy/Policy';
import SuErrorBoundary from '../screens/results/ErrorBoundary';
import CompanyDetailScreen from '../screens/company/CompanyDetailScreen';
import EmployeeAttendanceReportScreen from '../screens/employees/EmploteeAttendanceReport';
import TermsScreen from '../screens/terms/Terms';
import AttendanceLoginHistoryScreen from '../screens/attendance/history/AttendanceLoginHistoryScreen';
import { DocsLayout } from './DocsLayout';
import { DocsHome } from '../screens/docs/DocsHome/DocsHome';
import { DocsAttendance } from '../screens/docs/Attendance/DocsAttendance';
import { DocsEmployees } from '../screens/docs/Employees/DocsEmployees';
import { DocsZones } from '../screens/docs/Zones/DocsZones';
import { DocsSubscription } from '../screens/docs/Subscription/DocsSubscription';
import { DocsCompany } from '../screens/docs/Company/DocsCompany';
import { DocsAdministration } from '../screens/docs/Administration/DocsAdministration';
import ArchivedEmployeesList from '../screens/employees/archive/ArchivedEmployeesList';
import ArchivedZonesList from '../screens/zones/archive/ArchivedZonesList';
import ArchivedZoneDetailScreen from '../screens/zones/archive/ArchivedZoneDetail';
import DeviceListScreen from '../screens/devices/DeviceListScreen';

const router = createBrowserRouter([
    {
        path: '/',
        element: <AboutPage />,
        ErrorBoundary: () => <SuErrorBoundary />,
        index: true,
    },
    {
        path: '/about',
        element: <AboutPage />,
        ErrorBoundary: () => <SuErrorBoundary />,
        index: true,
    },
    {
        path: '/register',
        element: <RegisterScreen />,
        ErrorBoundary: () => <SuErrorBoundary />,
    },
    {
        path: '/login',
        element: <LoginScreen />,
        ErrorBoundary: () => <SuErrorBoundary />,
    },
    {
        path: '/no-access',
        element: <AccessDeniedForUserScreen />,
        ErrorBoundary: () => <SuErrorBoundary />,
    },
    {
        path: '/result',
        element: <CommonResultScreen />,
        ErrorBoundary: () => <SuErrorBoundary />,
    },
    {
        path: '/account-management/token/:token',
        element: <TokenPage />,
        ErrorBoundary: () => <SuErrorBoundary />,
    },
    {
        path: '/policy',
        element: <PolicyScreen />,
        ErrorBoundary: () => <SuErrorBoundary />,
    },
    {
        path: '/terms-of-use',
        element: <TermsScreen />,
        ErrorBoundary: () => <SuErrorBoundary />,
    },
    {
        element: <PrivateRoutes />,
        ErrorBoundary: () => <SuErrorBoundary />,
        children: [
            {
                element: <BaseLayout />,
                children: [
                    {
                        path: '/home',
                        element: <HomeScreen />,
                    },
                    {
                        path: '/profile',
                        element: <ProfileScreen />,
                    },
                    {
                        path: '/company',
                        element: <CompanyDetailScreen />,
                    },
                    {
                        path: '/attendance',
                        element: <AttendanceList />,
                    },
                    {
                        path: '/attendance/detail/:attendanceId',
                        element: <AttendanceDetailScreen />,
                    },
                    {
                        path: '/attendance/detail/:attendanceId/history',
                        element: <AttendanceLoginHistoryScreen />,
                    },
                    {
                        path: '/employees',
                        element: <EmployeesList />,
                    },
                    {
                        path: '/employees/archive',
                        element: <ArchivedEmployeesList />,
                    },
                    {
                        path: '/employees/detail/:id',
                        element: <EmployeeDetailScreen />,
                    },
                    {
                        path: '/employees/detail/:id/attendance/report',
                        element: <EmployeeAttendanceReportScreen />,
                    },
                    {
                        path: '/employees/detail/:id/devices',
                        element: <DeviceListScreen />,
                    },
                    {
                        path: '/employees/detail/:id/attendance/:attendanceId',
                        element: <AttendanceDetailScreen />,
                    },
                    {
                        path: '/employees/detail/:id/attendance/:attendanceId/history',
                        element: <AttendanceLoginHistoryScreen />,
                    },
                    {
                        path: '/zones',
                        element: <ZonesList />,
                    },
                    {
                        path: '/zones/archive',
                        element: <ArchivedZonesList />,
                    },
                    {
                        path: '/zones/archive/detail/:zoneId',
                        element: <ArchivedZoneDetailScreen />,
                    },
                    {
                        path: '/zones/detail/:zoneId',
                        element: <ZoneDetailScreen />,
                    },
                    {
                        path: '/zones/detail/:zoneId/attendance/:attendanceId',
                        element: <AttendanceDetailScreen />,
                    },
                    {
                        path: '/zones/detail/:zoneId/attendance/:attendanceId/history',
                        element: <AttendanceLoginHistoryScreen />,
                    }
                ],
            },
        ],
    },
    {
        element: <DocsLayout />,
        ErrorBoundary: () => <SuErrorBoundary />,
        children: [
            {
                path: '/docs',
                element: <Navigate to={'/docs/home'} />,
            },
            {
                path: '/docs/home',
                element: <DocsHome />,
            },
            {
                path: '/docs/attendance',
                element: <DocsAttendance />,
            },
            {
                path: '/docs/employees',
                element: <DocsEmployees />,
            },
            {
                path: '/docs/zones',
                element: <DocsZones />,
            },
            {
                path: '/docs/subscription',
                element: <DocsSubscription />,
            },
            {
                path: '/docs/company',
                element: <DocsCompany />,
            },
            {
                path: '/docs/administration',
                element: <DocsAdministration />,
            }
        ]
    },
    {
        path: '*',
        element: <PageNotFound />,
    },
]);

export default router;
