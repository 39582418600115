export enum Client {
    IOS = 'IOS',
    ANDROID = 'ANDROID',
    WEB = 'WEB',
}
  
export enum DeviceType {
MOBILE = 'MOBILE',
DESKTOP = 'DESKTOP',
TABLET = 'TABLET',
OTHER = 'OTHER',
}
