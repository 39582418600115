'use client';

import { ArrowBackIcon, EmailIcon } from '@chakra-ui/icons';
import {
    Container,
    Stack,
    Flex,
    Heading,
    Text,
    Button,
    Image,
    useColorModeValue,
    Wrap,
    WrapItem,
    Link as PageLink,
    useDisclosure,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useAuthStore } from '../../../store/auth/authStore';
import { ContactModal } from '../../../components/about/contact/ContactModal';
import { BsPencil } from 'react-icons/bs';

export const DocsHomeHero: React.FC = () => {
    const intl = useIntl();
    const user = useAuthStore((state) => state.user)!;
    const contactUsModelDisclosure = useDisclosure();
    const helpItems = [
        {
            title: intl.formatMessage({ id: 'attendance' }),
            href: '/docs/attendance',
        },
        {
            title: intl.formatMessage({ id: 'plural.EMPLOYEE' }),
            href: '/docs/employees',
        },
        {
            title: 'Roly',
            href: '/docs/employees#roles',
        },
        {
            title: intl.formatMessage({ id: 'TEAM_ACCOUNT' }),
            href: '/docs/employees#team_account',
        },
        {
            title: intl.formatMessage({ id: 'zones' }),
            href: '/docs/zones',
        },
        {
            title: intl.formatMessage({ id: 'billing.subscription' }),
            href: '/docs/subscription',
        },
        {
            title: intl.formatMessage({ id: 'company' }),
            href: '/docs/company',
        },
    ];
    return (
        <>
            <Container maxW={'7xl'}>
                <Stack align={'center'} spacing={{ base: 8, md: 10 }} py={{ base: 20, md: 28 }} direction={{ base: 'column', md: 'row' }}>
                    <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                        <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
                            <Text as={'span'} position={'relative'}>
                                Supervizer návody,
                            </Text>
                            <br />
                            <Text as={'span'} color={useColorModeValue('secondary.600', 'secondary.200')}>
                                s čím vám môžeme pomôcť?
                            </Text>
                        </Heading>
                        <Text color={useColorModeValue('gray.700', 'gray.400')}>
                            V tejto časti aplikácie nájdete návody k všetkým funkciám aplikácie.
                        </Text>
                        <Text color={useColorModeValue('gray.700', 'gray.400')}>
                            Máte viac otázok? Neváhajte nás kontaktovať na{' '}
                            <PageLink
                                isExternal
                                href="mailto:info.supervizer@gmail.com"
                                color={useColorModeValue('primary.500', 'primary.300')}
                            >
                                info.supervizer@gmail.com
                                <EmailIcon mx="2px" />
                            </PageLink>
                            alebo vyplňte
                            <Button
                                rightIcon={<BsPencil />}
                                size={'small'}
                                px={2}
                                pl={2}
                                variant={'link'}
                                onClick={contactUsModelDisclosure.onOpen}
                            >
                                tento formulár
                            </Button>
                        </Text>
                        <Stack spacing={{ base: 4, sm: 6 }} direction={{ base: 'column', sm: 'row' }}>
                            <Button
                                as={Link}
                                to={user ? '/home' : '/'}
                                rounded={'full'}
                                size={'lg'}
                                fontWeight={'normal'}
                                px={6}
                                colorScheme={'secondary'}
                                leftIcon={<ArrowBackIcon h={4} w={4} color={'gray.800'} />}
                                textColor={'gray.700'}
                            >
                                {intl.formatMessage({ id: 'docs.backToApp' })}
                            </Button>
                        </Stack>
                        <Wrap spacing={3}>
                            {helpItems.map((item) => {
                                return (
                                    <WrapItem key={item.title}>
                                        <Button as={Link} to={item.href} borderRadius={'full'}>
                                            {item.title}
                                        </Button>
                                    </WrapItem>
                                );
                            })}
                            <WrapItem></WrapItem>
                        </Wrap>
                    </Stack>
                    <Flex flex={1} justify={'center'} align={'center'} position={'relative'} w={'full'}>
                        <Image src={`${process.env.PUBLIC_URL}/images/docs_files.svg`} alt={'banner image'} />
                    </Flex>
                </Stack>
                <ContactModal isOpen={contactUsModelDisclosure.isOpen} onClose={contactUsModelDisclosure.onClose} />
            </Container>
        </>
    );
};
