import { Button, Box, useTheme, Flex, Center, RadioGroup, Stack } from '@chakra-ui/react';
import { InfiniteData } from 'react-query';
import { BasePagination } from '../../types';
import { useIntl } from 'react-intl';
import { LoadingCard } from '../ui/LoadingCard';
import React from 'react';

interface InfiniteSelectListProps<T> {
    data?: InfiniteData<BasePagination<T>>;
    fetchNextPage: () => void;
    hasNextPage: boolean | undefined;
    isFetchingNextPage: boolean;
    renderItem: (item: T, index: number) => JSX.Element;
    isLoading?: boolean;
    onSelect?: (item: T) => void;
}

function InfiniteSelectList<T extends { id: string | number}>({
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    renderItem,
    isLoading,
    onSelect,
}: InfiniteSelectListProps<T>) {
    const intl = useIntl();
    const theme = useTheme();
    const items = data?.pages.flatMap((page) => page.data);

    const [value, setValue] = React.useState<string | undefined>(undefined)

    if (isLoading && !isFetchingNextPage) {
        return (
            <Center p={5}>
                <LoadingCard />
            </Center>
        );
    }

    if (!items?.length) {
        return (
            <Center>
                <Box>{intl.formatMessage({ id: 'noData' })}</Box>
            </Center>
        );
    }
    const handleRadioSelect = (value: string) => {
        setValue(value)
        if (value) {
            const foundItem = items.find((item) => item.id.toString() === value)
            if (foundItem && onSelect) {
                onSelect(foundItem)
            }
        }
    }

    return (
        <Box>
            <RadioGroup onChange={handleRadioSelect} value={value}>
                <Stack>{items.map(renderItem)}</Stack>
            </RadioGroup>
            {hasNextPage && (
                <Flex p={2} alignItems={'center'} justifyContent={'center'}>
                    <Button backgroundColor={theme.colors.primary[500]} onClick={fetchNextPage} isLoading={isFetchingNextPage}>
                        {intl.formatMessage({ id: 'viewMore' })}
                    </Button>
                </Flex>
            )}
        </Box>
    );
}

export default InfiniteSelectList;
