import { Box, Button, Center, Heading, Icon, Stack, useDisclosure, useToast, Wrap, WrapItem } from '@chakra-ui/react';
import { createSearchParams, Navigate, useNavigate, useParams } from 'react-router-dom';
import { UserProfileCard } from '../../components/employee/UserProfileCard';
import { useDeleteEmployee, useGetAttendancePagination, useGetUserProfileDetail } from '../../query/resource-hooks/company';
import SecondPageHeader from '../../components/ui/SecondPageHeader';
import { useIntl } from 'react-intl';
import AttendanceTable from '../attendance/AttendanceTable';
import { AttendanceTableItem } from '../../components/lists/AttendanceTableItem';
import { SimpleAttendanceReportModal } from '../../components/attendance/reports/SimpleReportModal';
import { FiCalendar } from 'react-icons/fi';
import { AttendanceListFilter } from '../../components/attendance/AttendanceListFilter';
import { useState } from 'react';
import { LoginDirection, Role } from '../../types';
import { adjustDateToUserTimezone } from '../../components/calendarPicker';
import { DeleteIcon, EditIcon, PlusSquareIcon } from '@chakra-ui/icons';
import { UpdateAttendanceModal } from '../../components/attendance/UpdateAttendanceModal';
import { EmployeeForm } from '../../components/employee/EmployeeForm';
import UserPolicy from '../../policies/UserPolicy';
import { useAuthStore } from '../../store/auth/authStore';
import { DeleteAlertDialog } from '../../components/ui/DeleteAlertDialog';
import { MdDevices } from 'react-icons/md';

const EmployeeDetailScreen: React.FC = () => {
    const routeParams = useParams<{ id: string }>();
    const navigate = useNavigate();
    const intl = useIntl();
    const loggedUser = useAuthStore((s) => s.user);
    const user = useGetUserProfileDetail(+routeParams.id!);
    const toastContext = useToast();

    const deleteUser = useDeleteEmployee(
        () => {
            toastContext({
                status: 'success',
                title: intl.formatMessage({ id: 'data.deleted' }),
                duration: 3000,
                isClosable: true,
                position: 'top',
            });
            navigate('/employees');
        },
        (_error) => {
            toastContext({
                status: 'error',
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                duration: 3000,
                isClosable: true,
                position: 'top',
            });
            deleteUser.reset();
        }
    );

    const attendanceReportDisclosure = useDisclosure();
    const addAttendanceDisclosure = useDisclosure();
    const editUserDisclosure = useDisclosure();
    const deleteUserDisclosure = useDisclosure();

    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const [filterLoginDirection, setFilterLoginDirection] = useState<LoginDirection>(LoginDirection.NOT_SET);

    const attendanceQuery = useGetAttendancePagination({
        userIds: [+routeParams.id!],
        ...(selectedDates[0] && { dateFrom: adjustDateToUserTimezone(selectedDates[0]).toISOString() }),
        ...(selectedDates[1] && { dateTo: new Date(adjustDateToUserTimezone(selectedDates[1]).setUTCHours(23, 59, 59)).toISOString() }),
        ...(filterLoginDirection !== LoginDirection.NOT_SET && { direction: filterLoginDirection }),
        ...(user.data?.role === Role.TEAM_ACCOUNT && { includeCreatedBy: true }),
    });

    const handleDateRangeSubmit = (dateFrom: string, dateTo: string) => {
        return navigate({
            pathname: 'attendance/report',
            search: createSearchParams({
                dateFrom,
                dateTo,
            }).toString(),
        });
    };

    if (user.error) {
        return <Navigate to="/404" />;
    }

    return (
        <>
            <SecondPageHeader
                title={intl.formatMessage({ id: 'tab.employees.detail.title' })}
                onBack={() => navigate(-1)}
                menuListItems={[
                    {
                        onClick: attendanceReportDisclosure.onOpen,
                        title: intl.formatMessage({ id: 'attendanceReport.title' }),
                        icon: <Icon as={FiCalendar} />,
                    },
                    ...(user.data && UserPolicy.canEditUser(loggedUser!, user.data)
                        ? [
                              {
                                  onClick: editUserDisclosure.onOpen,
                                  title: intl.formatMessage({ id: 'edit' }),
                                  icon: <Icon as={EditIcon} />,
                              },
                          ]
                        : []),
                    ...(user.data && UserPolicy.canDeleteUser(loggedUser!, user.data?.role)
                        ? [
                              {
                                  onClick: deleteUserDisclosure.onOpen,
                                  title: intl.formatMessage({ id: 'delete' }),
                                  icon: <Icon as={DeleteIcon} />,
                              },
                          ]
                        : []),
                ]}
            />
            <SimpleAttendanceReportModal
                isOpen={attendanceReportDisclosure.isOpen}
                onClose={attendanceReportDisclosure.onClose}
                user={user.data!}
                onDateRangeSubmit={handleDateRangeSubmit}
            />
            <Center>
                <UserProfileCard onPhotoUpdate={() => user.refetch()} user={user.data} />
            </Center>
            <Center mt={4} px={2}>
                <Wrap>
                    <WrapItem>
                        <Button borderRadius={'full'} onClick={attendanceReportDisclosure.onOpen} leftIcon={<Icon as={FiCalendar} />}>
                            {intl.formatMessage({ id: 'attendanceReport.title' })}
                        </Button>
                    </WrapItem>
                    {user.data && UserPolicy.canEditUser(loggedUser!, user.data) && (
                        <>
                            <WrapItem>
                                <Button borderRadius={'full'} onClick={editUserDisclosure.onOpen} leftIcon={<Icon as={EditIcon} />}>
                                    {intl.formatMessage({ id: 'edit' })}
                                </Button>
                            </WrapItem>
                            <WrapItem>
                                <Button borderRadius={'full'} onClick={() => navigate(`devices`)} leftIcon={<Icon as={MdDevices} />}>
                                    {intl.formatMessage({ id: 'device.list.title' })}
                                </Button>
                            </WrapItem>
                        </>
                    )}
                    {user.data && UserPolicy.canDeleteUser(loggedUser!, user.data.role) && (
                        <>
                            <WrapItem>
                                <Button
                                    borderRadius={'full'}
                                    onClick={deleteUserDisclosure.onOpen}
                                    colorScheme="red"
                                    leftIcon={<Icon as={DeleteIcon} />}
                                >
                                    {intl.formatMessage({ id: 'delete' })}
                                </Button>
                            </WrapItem>
                        </>
                    )}
                </Wrap>
            </Center>
            <Stack flexDir={{ base: 'column', lg: 'row' }} width={'100%'}>
                <Box m={{ base: 1, md: 4 }} order={{ base: 2, lg: 1 }} width={'100%'}>
                    <Box p={2} display={'flex'} flexDir={'row'} justifyContent={'space-between'}>
                        <Heading size="md">{intl.formatMessage({ id: 'attendance' })}</Heading>
                        <Button
                            ml={2}
                            size={'sm'}
                            onClick={addAttendanceDisclosure.onOpen}
                            borderRadius={'full'}
                            leftIcon={<PlusSquareIcon />}
                            isDisabled={!UserPolicy.canCreateManualAttendance(loggedUser!)}
                        >
                            {intl.formatMessage({ id: 'tab.Add' })}
                        </Button>
                    </Box>
                    <AttendanceTable
                        fetchNextPage={attendanceQuery.fetchNextPage}
                        hasNextPage={attendanceQuery.hasNextPage}
                        isFetchingNextPage={attendanceQuery.isFetchingNextPage}
                        isLoading={attendanceQuery.isLoading && attendanceQuery.isRefetching}
                        data={attendanceQuery.data}
                        renderItem={(item, showPreview) => {
                            return <AttendanceTableItem onClick={() => showPreview()} item={item} key={item.id} />;
                        }}
                        handleNavigateToDetail={(id) => navigate(`attendance/${id}`)}
                    />
                </Box>
                <Box order={{ base: 1, lg: 2 }}>
                    <AttendanceListFilter
                        onDateChange={setSelectedDates}
                        selectedDates={selectedDates}
                        filterLoginDirection={filterLoginDirection}
                        onFilterLoginDirectionChange={setFilterLoginDirection}
                        totalRecords={attendanceQuery.data?.pages?.[0].meta?.total}
                        position="sticky"
                        top="22px"
                        mt={4}
                        ml={{ base: 2, lg: 0 }}
                        mr={2}
                    />
                </Box>
            </Stack>
            <UpdateAttendanceModal
                isOpen={addAttendanceDisclosure.isOpen}
                onClose={addAttendanceDisclosure.onClose}
                onSuccess={() => attendanceQuery.refetch()}
                preselectedDate={new Date().toISOString()}
                preselectedUser={user.data}
            />
            <EmployeeForm
                isOpen={editUserDisclosure.isOpen}
                onClose={editUserDisclosure.onClose}
                updatedUser={user.data}
                onSuccess={() => user.refetch()}
            />
            <DeleteAlertDialog
                isOpen={deleteUserDisclosure.isOpen}
                onClose={deleteUserDisclosure.onClose}
                onConfirm={async () => {
                    await deleteUser.mutate(user.data?.id!);
                    deleteUserDisclosure.onClose();
                }}
                isLoading={deleteUser.isLoading}
            />
        </>
    );
};

export default EmployeeDetailScreen;
