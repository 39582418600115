import { CheckIcon } from '@chakra-ui/icons';
import { Box, List, ListIcon, ListItem, SimpleGrid, Image } from '@chakra-ui/react';

export const FeatureLogOwnAttendance: React.FC = () => {
    return (
        <Box>
            <SimpleGrid columns={{ sm: 1, md: 2 }}>
                <Box m={'auto'} px={{ base: 0, md: 2 }}>
                    <List textAlign={'left'} pl={10} spacing={3}>
                        <ListItem fontSize={'large'} fontWeight={'600'} color={'gray.200'}>
                            Zamestnanec sám vyberá zónu prihlásenia.
                        </ListItem>
                        <ListItem fontSize={'large'} fontWeight={'600'} color={'gray.200'}>
                            <ListIcon as={CheckIcon} color="green.500" />
                            Dostupné na jeho vlastnom zariadení.
                        </ListItem>
                    </List>
                </Box>

                <Box minH={{ base: 0, md: '80vh' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Image
                        borderRadius={'lg'}
                        maxH={'80vh'}
                        src={`${process.env.PUBLIC_URL}/images/mockups/add_attendance_employee.png`}
                    />
                </Box>
            </SimpleGrid>
        </Box>
    );
};
