import { Box, Heading, Text, Button, useTheme } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
    const theme = useTheme();
    const navigation = useNavigate();
    const intl = useIntl();
    return (
        <Box textAlign="center" py={10} px={6}>
            <Heading display="inline-block" as="h2" size="2xl" bgGradient="linear(to-r, primary.300, primary.600)" backgroundClip="text">
                404
            </Heading>
            <Text fontSize="18px" mt={3} mb={2}>
                Stránka sa nenašla
            </Text>
            <Text color={'gray.500'} mb={6}>
                Ups. Niečo sa pokazilo. Stránka ktorú hľadáte pravdepodobne neexistuje
            </Text>

            <Button background={theme.colors.primary[500]} variant="solid" onClick={() => navigation('/home')}>
                {intl.formatMessage({ id: 'docs.backToApp' })}
            </Button>
        </Box>
    );
}
