import { Card, CardHeader, Flex, Spinner } from "@chakra-ui/react";


type LoadingProps = React.ComponentProps<typeof Card>;

export const LoadingCard: React.FC<LoadingProps> = (props) => {
    return (
        <Card maxW={'md'} {...props}>
            <CardHeader>
                <Flex align={'center'} justify={'center'}>
                    <Spinner size="xl" />
                </Flex>
            </CardHeader>
        </Card>
    );
};
