import { Box, Skeleton } from "@chakra-ui/react";

export const AttendanceTableSkeleton = () => {
    return (
        <Box>
            {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton key={index} height="52px" my="2" borderRadius={'lg'} />
            ))}
        </Box>
    );
}