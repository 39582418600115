import { useIntl } from 'react-intl';
import { InfoResult } from '../../components/results/InfoResult';
import { useLogout } from '../../query/resource-hooks/auth';
import { useNavigate } from 'react-router-dom';

const AccessDeniedForUserScreen: React.FC = () => {
    const intl = useIntl();
    const navigation = useNavigate();
    const logoutMutation = useLogout(() => navigation('/login'));

    function handleLogout() {
        logoutMutation.mutate();
    }

    return (
        <InfoResult
            actionText={intl.formatMessage({ id: 'auth.backToLogin' })}
            text="Nemáte dostatočné oprávnenia na prístup k tejto stránke"
            title="Nemáte dostatočné povolenia"
            onAction={handleLogout}
        />
    );
};

export default AccessDeniedForUserScreen;
