import { ReactElement, useEffect, useState } from 'react';
import { InternalSubscriptionStatus, User } from '../../types';
import { SubscriptionInvalid } from '../../components/account-management/SubscriptionInvalid';
import { EmailNotVerified } from '../../components/account-management/EmailNotVerified';
import JoinCompanyScreen from '../onboarding/JoinCompanyScreen';
import { useNotificationStore } from '../../store/ui/NotificationStore';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import SetupBillingScreen from '../company/SetupBillingScreen';

type OverlayProps = {
    children: ReactElement;
    loggedUser: User;
};


/**
 * Overlay component to display some special events.
 * For now - no internet connection and subscription invalid.
 */
const Overlay: React.FC<OverlayProps> = ({ loggedUser, children }) => {
    const [showSubscriptionOverlay, setShowSubscriptionOverlay] = useState(false);
    const pushNotification = useNotificationStore((state) => state.pushNotification);
    const navigate = useNavigate();
    const intl = useIntl();

    useEffect(() => {
        if (loggedUser) {
            const billingAccount = loggedUser?.company?.billingAccount;

            // Let user in if he is exempted or super owner
            if (!billingAccount || billingAccount.isExempted) {
                setShowSubscriptionOverlay(false);
            }

            if (billingAccount && billingAccount.internalSubscriptionStatus === InternalSubscriptionStatus.INACTIVE) {
                setShowSubscriptionOverlay(true);
            }

            if (billingAccount?.actionRequired) {
                pushNotification({
                    title: intl.formatMessage({id: 'billing.setting.actionRequiredText'}),
                    type: 'warning',
                    code: 'BILLING_ACTION_REQUIRED',
                    actionText: intl.formatMessage({id: 'settings'}),
                    action: () => {
                        navigate('/company')
                    }
                })
            }
        }
    }, [intl, loggedUser, navigate, pushNotification]);

    if (loggedUser?.isRegistered && !loggedUser?.emailVerified) {
        return <EmailNotVerified />;
    }
    
    if (!loggedUser?.company) {
        return <JoinCompanyScreen />;
    }


    if (loggedUser?.company?.billingAccount?.redirectUrl) {
        return <SetupBillingScreen company={loggedUser.company} />;
    }

    if (showSubscriptionOverlay) {
        return <SubscriptionInvalid loggedUser={loggedUser} />;
    }

    return children
        
};

export default Overlay;
