import { Box, Card, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import SecondPageHeader from '../../../components/ui/SecondPageHeader';
import { useIntl } from 'react-intl';
import { useGetArchivedZoneDetail, useGetAttendancePagination } from '../../../query/resource-hooks/company';
import { AttendanceTableItem } from '../../../components/lists/AttendanceTableItem';
import AttendanceTable from '../../attendance/AttendanceTable';
import MapPreview from '../../../components/maps/MapPreview';
import { useRef, useState } from 'react';
import { AttendanceListFilter } from '../../../components/attendance/AttendanceListFilter';
import { LoginDirection } from '../../../types';
import { adjustDateToUserTimezone } from '../../../components/calendarPicker';

const ArchivedZoneDetailScreen: React.FC = () => {
    const params = useParams<{ zoneId: string }>();
    const navigate = useNavigate();
    const intl = useIntl();

    const mapParentRef = useRef<HTMLDivElement>(null);

    const zone = useGetArchivedZoneDetail(+params.zoneId!);

    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const [filterLoginDirection, setFilterLoginDirection] = useState<LoginDirection>(LoginDirection.NOT_SET);

    const attendanceQuery = useGetAttendancePagination({
        zoneIds: [+params.zoneId!],
        ...(selectedDates[0] && { dateFrom: adjustDateToUserTimezone(selectedDates[0]).toISOString() }),
        ...(selectedDates[1] && { dateTo: new Date(adjustDateToUserTimezone(selectedDates[1]).setUTCHours(23, 59, 59)).toISOString() }),
        ...(filterLoginDirection !== LoginDirection.NOT_SET && { direction: filterLoginDirection }),
    });

    if (zone.error) {
        return <Navigate to="/404" />;
    }

    return (
        <Box>
            <SecondPageHeader onBack={() => navigate(-1)} title={intl.formatMessage({ id: 'tab.zones.detail.title' })} />
            <Card m={{ base: 2, md: 5 }} height={{ base: '350px', md: '500px' }} ref={mapParentRef}>
                <MapPreview parentRef={mapParentRef} zone={zone.data} />
            </Card>
            <Center>
                <Card minW={'sm'} maxW={'md'} mx={{ base: 0, md: 5 }}>
                    <Center>
                        <Text fontSize={'lg'} fontWeight={600} pt={4}>
                            {zone.data?.name}
                        </Text>
                    </Center>
                </Card>
            </Center>

            <Stack flexDir={{ base: 'column', lg: 'row' }} width={'100%'}>
                <Box m={{ base: 2, md: 5 }} order={{ base: 2, lg: 1 }} width={'100%'}>
                    <Box>
                        <Heading size="md">{intl.formatMessage({ id: 'attendance' })}</Heading>
                    </Box>
                    <AttendanceTable
                        fetchNextPage={attendanceQuery.fetchNextPage}
                        hasNextPage={attendanceQuery.hasNextPage}
                        isFetchingNextPage={attendanceQuery.isFetchingNextPage}
                        isLoading={attendanceQuery.isLoading && attendanceQuery.isRefetching}
                        data={attendanceQuery.data}
                        renderItem={(item, showPreview) => {
                            return <AttendanceTableItem onClick={() => showPreview()} item={item} key={item.id} />;
                        }}
                        handleNavigateToDetail={(id) => navigate(`/attendance/detail/${id}`)}
                    />
                </Box>
                <Box order={{ base: 1, lg: 2 }}>
                    <AttendanceListFilter
                        onDateChange={setSelectedDates}
                        selectedDates={selectedDates}
                        filterLoginDirection={filterLoginDirection}
                        onFilterLoginDirectionChange={setFilterLoginDirection}
                        totalRecords={attendanceQuery.data?.pages?.[0].meta?.total}
                        position="sticky"
                        top="22px"
                        mt={4}
                        ml={{ base: 2, lg: 0 }}
                        mr={2}
                    />
                </Box>
            </Stack>
        </Box>
    );
};

export default ArchivedZoneDetailScreen;
