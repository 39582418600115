import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    Text,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { object, string } from 'yup';
import FormikTextInput from '../formik/FormikTextInput';
import { useSendPasswordResetEmail } from '../../query/resource-hooks/auth';
import { AxiosError } from 'axios';

type PasswordResetProps = {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
};

export const PasswordResetModal: React.FC<PasswordResetProps> = ({ isOpen, onClose, onOpen }) => {
    const intl = useIntl();
    const requestEmail = useSendPasswordResetEmail(onSuccess, onError);
    const toast = useToast();

    const handleResetPassword = async (values: { email: string }) => {
        requestEmail.mutate(values);
    };

    function onSuccess(_data: { status: string }) {
        toast({
            status: 'success',
            title: intl.formatMessage({ id: 'resetPassword.emailSent' }),
        });
        onClose();
    }

    function onError(error: AxiosError<any>) {
        const errorMessage = error.response?.data?.errors?.message;
        if (errorMessage === 'E_BAD_REQUEST: User not found') {
            toast({
                status: 'error',
                title: intl.formatMessage({ id: 'errorInvalidData' }),
            });
        } else if (errorMessage === 'E_FORBIDDEN: User is not registered or email is not verified') {
            toast({
                status: 'error',
                title: intl.formatMessage({ id: 'emailNotVerified' }),
            });
        } else {
            toast({
                status: 'error',
                title: intl.formatMessage({ id: 'passwordResetError' }),
            });
        }
    }

    const emailSchema = object({
        email: string()
            .email(intl.formatMessage({ id: 'validation.emailInvalid' }))
            .required(intl.formatMessage({ id: 'validation.emailEmpty' })),
    });

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <Formik onSubmit={handleResetPassword} initialValues={{ email: '' }} validationSchema={emailSchema}>
                        {({ errors, touched, handleChange, handleBlur, values }) => (
                            <Form>
                                <ModalHeader>{intl.formatMessage({ id: 'passwordReset' })}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Text pb={4}>{intl.formatMessage({ id: 'resetPassword.sendResetInstructions' })}</Text>
                                    <FormikTextInput
                                        errors={errors}
                                        touched={touched}
                                        name="email"
                                        fieldName="email"
                                        inputMode="email"
                                        type="email"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        label={intl.formatMessage({ id: 'email' })}
                                        values={values}
                                        isDisabled={requestEmail.isLoading}
                                    />
                                </ModalBody>

                                <ModalFooter>
                                    <Button variant="ghost" mr={3} onClick={onClose}>
                                        {intl.formatMessage({ id: 'cancel' })}
                                    </Button>
                                    <Button isLoading={requestEmail.isLoading} type="submit">{intl.formatMessage({ id: 'requestEmail' })}</Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </>
    );
};
