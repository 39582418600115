import { IntlShape } from 'react-intl';

export const Month_Names_Full = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const Month_Names_Short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const Weekday_Names_Short = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// Function to adjust date to user's timezone and return as ISO string
export function adjustDateToUserTimezone(dateInput: Date | string) {
    // Parse the input date if it's a string, or clone the date if it's a Date object
    const date = typeof dateInput === 'string' ? new Date(dateInput) : new Date(dateInput.getTime());

    // Get the user's timezone offset in minutes
    const timezoneOffset = new Date().getTimezoneOffset();

    // JavaScript's getTimezoneOffset returns the difference in minutes
    // between UTC and the local time. Note that this value is inverted
    // (e.g., for UTC+2, it returns -120). To adjust the date to the local
    // timezone, we subtract this offset.
    date.setMinutes(date.getMinutes() - timezoneOffset);

    // Return the adjusted date as an ISO string
    return date;
}

export const getShortWeekdayNames = (intl: IntlShape) => {
    const weekdays = [];
    
    for (let day = 4; day < 11; day++) {
        // Create a date object for each day of the week. The exact date doesn't matter as long as the day of the week is correct.
        const date = new Date(Date.UTC(2024, 1, day));
        // Use the formatDate method with the 'weekday' option to get the short name of the day.
        const shortName = intl.formatDate(date, { weekday: 'short' });
        weekdays.push(shortName);
    }

    return weekdays;
};

export const getShortMonthNames = (intl: IntlShape) => {
    const months = [];
    
    for (let month = 0; month < 12; month++) {
        // Create a date object for the first day of each month.
        const date = new Date(Date.UTC(2024, month, 1));
        // Use the formatDate method with the 'month' option to get the short name of the month.
        const shortName = intl.formatDate(date, { month: 'short' });
        months.push(shortName);
    }

    return months;
};
