import { Box, Button, Card, CardBody, CardHeader, Heading, useColorModeValue, VStack } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import SchemaProvider from '../../utils/SchemaProvider';
import { useIntl } from 'react-intl';
import FormikPasswordInput from '../formik/FormikPasswordInput';
import { InferType } from 'yup';
import { useResetPassword } from '../../query/resource-hooks/auth';
import { useNavigate } from 'react-router-dom';

const PasswordResetForm: React.FC<{ token: string }> = ({ token }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const passwordResetSchema = SchemaProvider.getPasswordResetFormSchema(intl);
    const resetPasswordMutation = useResetPassword(onSuccess, onError);

    const handleSubmit = (values: InferType<typeof passwordResetSchema>) => {
        resetPasswordMutation.mutate({ token, password: values.password });
    };

    function onSuccess() {
        navigate('/result', {
            state: {
                title: intl.formatMessage({ id: 'success' }),
                type: 'success',
            },
        });
    }

    function onError() {
        navigate('/result', {
            state: {
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                text: intl.formatMessage({ id: 'resetPassword.somethingWentWrong' }),
                type: 'error',
            },
        });
    }

    return (
        <Box
            justifyContent={'center'}
            alignItems={'start'}
            display="flex"
            w={'100vw'}
            h={'100vh'}
            pt={4}
            px={2}
            bg={useColorModeValue('gray.100', 'gray.900')}
        >
            <Card maxW={'60ch'} width={'100%'}>
                <CardHeader>
                    <Heading size={'md'}>{intl.formatMessage({ id: 'resetPassword.resetPassword' })}</Heading>
                </CardHeader>
                <CardBody>
                    <Formik
                        onSubmit={handleSubmit}
                        validationSchema={passwordResetSchema}
                        initialValues={{ password: '', confirmPassword: '' }}
                    >
                        {({ errors, touched, handleChange, handleBlur, values }) => (
                            <Form>
                                <VStack>
                                    <FormikPasswordInput
                                        errors={errors}
                                        touched={touched}
                                        name="password"
                                        fieldName="password"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        label={intl.formatMessage({ id: 'password' })}
                                        values={values}
                                        isDisabled={resetPasswordMutation.isLoading}
                                    />
                                    <FormikPasswordInput
                                        errors={errors}
                                        touched={touched}
                                        name="confirmPassword"
                                        fieldName="confirmPassword"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        label={intl.formatMessage({ id: 'confirmPassword' })}
                                        values={values}
                                        isDisabled={resetPasswordMutation.isLoading}
                                    />
                                    <Button isLoading={resetPasswordMutation.isLoading} type="submit">{intl.formatMessage({ id: 'resetPassword.resetPassword' })}</Button>
                                </VStack>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </Box>
    );
};

export default PasswordResetForm;
