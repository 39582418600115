import { Box, Heading, Stack, Card, CardHeader, CardBody, UnorderedList, ListItem, Text } from "@chakra-ui/react";

export const DocsCompany: React.FC = () => {
    return (
        <Box pb={12}>
            <Box maxW={'1140px'} marginX={'auto'} px={{ base: 2, md: 0 }} pt={4}>
                <Heading p={4}>Spoločnosť a jej význam</Heading>
                <Stack spacing={1}>
                    <Text>
                       Spoločnosť v systéme slúži výhradne na orhanizáciu používateľov. Jediný povynný údaj je názov spoločnosti. Spoločnosť nemusí byť reálna firma.
                    </Text>
                    <Card mt={4}>
                        <CardHeader>
                            <Heading size="md">Čo môže spoločnosť obsahovať?</Heading>
                        </CardHeader>
                        <CardBody>
                            <UnorderedList>
                                <ListItem>
                                    <Text fontWeight={600}>Názov</Text>
                                </ListItem>
                                <ListItem>
                                    <Text fontWeight={600}>Logo</Text>
                                </ListItem>
                                <ListItem>
                                    <Text fontWeight={600}>Kontaktné informácie</Text>
                                    <Text>
                                        Vašej spoločnosti môžete pridať kontaktné informácie ako telefónne číslo, email, adresa ičo, ičo dph atď. Tieto údaje momentálne nemajú využitie. Ich využitie plánujeme v ďalších rozšíreniach systému.
                                    </Text>
                                </ListItem>
                            </UnorderedList>
                        </CardBody>
                    </Card>
                </Stack>
            </Box>
        </Box>
    )

};