import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Center,
    Heading,
    HStack,
    Stack,
    Text,
    useBreakpointValue,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import SecondPageHeader from '../../components/ui/SecondPageHeader';
import { useIntl } from 'react-intl';
import { useDeleteZone, useGetAttendancePagination, useGetZoneById } from '../../query/resource-hooks/company';
import { AttendanceTableItem } from '../../components/lists/AttendanceTableItem';
import AttendanceTable from '../attendance/AttendanceTable';
import MapPreview from '../../components/maps/MapPreview';
import { useState } from 'react';
import { ZoneForm } from '../../components/zones/ZoneForm';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { DeleteAlertDialog } from '../../components/ui/DeleteAlertDialog';
import { AxiosError } from 'axios';
import { AttendanceListFilter } from '../../components/attendance/AttendanceListFilter';
import { LoginDirection } from '../../types';
import { adjustDateToUserTimezone } from '../../components/calendarPicker';

const ZoneDetailScreen: React.FC = () => {
    const params = useParams<{ zoneId: string }>();
    const navigate = useNavigate();
    const intl = useIntl();
    const toast = useToast();

    const zoneFormDisclosure = useDisclosure();
    const deleteAlertDisclosure = useDisclosure();

    const zone = useGetZoneById(+params.zoneId!);
    const deleteZone = useDeleteZone(handleDeleteSuccess, handleDeleteError);

    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const [filterLoginDirection, setFilterLoginDirection] = useState<LoginDirection>(LoginDirection.NOT_SET);

    const mapHeight = useBreakpointValue({ 'base': 200, 'md': 250, 'xl': 300, '2xl': 400 });

    const attendanceQuery = useGetAttendancePagination({
        zoneIds: [+params.zoneId!],
        ...(selectedDates[0] && { dateFrom: adjustDateToUserTimezone(selectedDates[0]).toISOString() }),
        ...(selectedDates[1] && { dateTo: new Date(adjustDateToUserTimezone(selectedDates[1]).setUTCHours(23, 59, 59)).toISOString() }),
        ...(filterLoginDirection !== LoginDirection.NOT_SET && { direction: filterLoginDirection }),
    });

    function handleDeleteZone() {
        if (zone.data) {
            deleteZone.mutate(zone.data?.id);
            deleteAlertDisclosure.onClose();
        }
    }

    function handleDeleteSuccess() {
        toast({
            title: intl.formatMessage({ id: 'data.deleted' }),
            status: 'success',
            duration: 10000,
            isClosable: true,
            position: 'top',
        });
        deleteAlertDisclosure.onClose();
        navigate(-1);
    }

    function handleDeleteError(error: AxiosError) {
        toast({
            title: intl.formatMessage({ id: 'somethingWentWrong' }),
            status: 'error',
            duration: 10000,
            isClosable: true,
            position: 'top',
        });
        deleteAlertDisclosure.onClose();
    }

    if (zone.error) {
        return <Navigate to="/404" />;
    }

    return (
        <Box>
            <SecondPageHeader
                onBack={() => navigate(-1)}
                title={intl.formatMessage({ id: 'tab.zones.detail.title' })}
                menuListItems={[
                    {
                        onClick: () => zoneFormDisclosure.onOpen(),
                        title: intl.formatMessage({ id: 'editZone' }),
                        icon: <EditIcon />,
                    },
                    {
                        onClick: () => deleteAlertDisclosure.onOpen(),
                        title: intl.formatMessage({ id: 'delete' }),
                        icon: <DeleteIcon />,
                    },
                ]}
            />
            <Box p={2}>
                <MapPreview height={mapHeight} zone={zone.data} />
            </Box>

            <Center>
                <Card minW={'sm'} maxW={'md'} mx={{ base: 0, md: 5 }}>
                    <Center>
                        <Text fontSize={'lg'} fontWeight={600} pt={4}>
                            {zone.data?.name}
                        </Text>
                    </Center>
                    <CardBody>
                        <HStack justify={'center'} flex={1}>
                            <ButtonGroup>
                                <Button onClick={zoneFormDisclosure.onOpen} leftIcon={<EditIcon />} borderRadius={'full'}>
                                    {intl.formatMessage({ id: 'editZone' })}
                                </Button>
                                <Button
                                    onClick={deleteAlertDisclosure.onOpen}
                                    leftIcon={<DeleteIcon />}
                                    borderRadius={'full'}
                                    colorScheme="red"
                                >
                                    {intl.formatMessage({ id: 'delete' })}
                                </Button>
                            </ButtonGroup>
                        </HStack>
                    </CardBody>
                </Card>
            </Center>
            <Stack flexDir={{ base: 'column', lg: 'row' }} width={'100%'}>
                <Box m={{ base: 2, md: 5 }} order={{ base: 2, lg: 1 }} width={'100%'}>
                    <Box>
                        <Heading size="md">{intl.formatMessage({ id: 'attendance' })}</Heading>
                    </Box>
                    <AttendanceTable
                        fetchNextPage={attendanceQuery.fetchNextPage}
                        hasNextPage={attendanceQuery.hasNextPage}
                        isFetchingNextPage={attendanceQuery.isFetchingNextPage}
                        isLoading={attendanceQuery.isLoading && attendanceQuery.isRefetching}
                        data={attendanceQuery.data}
                        renderItem={(item, showPreview) => {
                            return <AttendanceTableItem onClick={() => showPreview()} item={item} key={item.id} />;
                        }}
                        handleNavigateToDetail={(id) => navigate(`attendance/${id}`)}
                    />
                </Box>
                <Box order={{ base: 1, lg: 2 }}>
                    <AttendanceListFilter
                        onDateChange={setSelectedDates}
                        selectedDates={selectedDates}
                        filterLoginDirection={filterLoginDirection}
                        onFilterLoginDirectionChange={setFilterLoginDirection}
                        totalRecords={attendanceQuery.data?.pages?.[0].meta?.total}
                        position="sticky"
                        top="22px"
                        mt={4}
                        ml={{ base: 2, lg: 0 }}
                        mr={2}
                    />
                </Box>
            </Stack>
            <ZoneForm
                isOpen={zoneFormDisclosure.isOpen}
                onClose={zoneFormDisclosure.onClose}
                onSuccess={() => {
                    zone.refetch();
                }}
                updatedZone={zone.data}
            />
            <DeleteAlertDialog
                isLoading={deleteZone.isLoading}
                isOpen={deleteAlertDisclosure.isOpen}
                onClose={deleteAlertDisclosure.onClose}
                onConfirm={handleDeleteZone}
            />
        </Box>
    );
};

export default ZoneDetailScreen;
