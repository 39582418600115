import { create } from 'zustand';
import { persist } from 'zustand/middleware';


type SettingsState = {
    language: string | null,
    setLanguage: (language: string) => void,
};


const useSettingsStore = create<SettingsState>()(
    persist(
        (set) => ({
            language: null,
            setLanguage: (language) => set((s) => ({ ...s, language })),
        }),
        { name: 'SettingsStoreStorage' }
    ),
);




export { useSettingsStore };
