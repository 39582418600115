import React, { ReactNode } from 'react';
import { Box, Container, Stack, SimpleGrid, Text, Link, useColorModeValue, Image } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

// const SocialButton = ({ children, label, href }: { children: ReactNode; label: string; href: string }) => {
//     return (
//         <chakra.button
//             bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
//             rounded={'full'}
//             w={8}
//             h={8}
//             cursor={'pointer'}
//             as={'a'}
//             href={href}
//             display={'inline-flex'}
//             alignItems={'center'}
//             justifyContent={'center'}
//             transition={'background 0.3s ease'}
//             _hover={{
//                 bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
//             }}
//         >
//             <VisuallyHidden>{label}</VisuallyHidden>
//             {children}
//         </chakra.button>
//     );
// };

const AboutFooter: React.FC<{boxProps?: React.ComponentProps<typeof Box>}> = ({boxProps}) => {
    const intl = useIntl();

    return (
        <Box  {...boxProps} >
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={8}>
                    {/* <Stack align={'flex-start'}>
                        <ListHeader>Company</ListHeader>
                        <Link href={'#'}>About Us</Link>
                        <Link href={'#'}>Blog</Link>
                        <Link href={'#'}>Careers</Link>
                        <Link href={'#'}>Contact Us</Link>
                    </Stack>

                    <Stack align={'flex-start'}>
                        <ListHeader>Support</ListHeader>
                        <Link href={'#'}>Help Center</Link>
                        <Link href={'#'}>Safety Center</Link>
                        <Link href={'#'}>Community Guidelines</Link>
                    </Stack> */}

                    <Stack align={'flex-start'}>
                        <ListHeader>Legal</ListHeader>
                        {/* <Link href={'#'}>Cookies Policy</Link> */}
                        <Link as={RouterLink} to={'/policy'}>{intl.formatMessage({id: 'privacyPolicy'})}</Link>
                        <Link as={RouterLink} to={'/terms-of-use'}>{intl.formatMessage({id: 'termsAndConditions'})}</Link>
                        <Link as={RouterLink} to={'/docs/home'}>{intl.formatMessage({id: 'docs.title'})}</Link>
                    </Stack>

                    <Stack align={'flex-end'} >
                        <ListHeader>{ intl.formatMessage({id: 'about.installApp'}) }</ListHeader>
                        <a href="https://play.google.com/store/apps/details?id=com.supervizer">
                            <Image maxW={200} src={`${process.env.PUBLIC_URL}/images/google-play-icon.svg`} alt="Google Play Store" />
                        </a>
                        <a href="https://apps.apple.com/sk/app/supervizer/id6479220108">
                            <Image maxW={200} src={`${process.env.PUBLIC_URL}/images/apple-store-icon.svg`} alt="Apple app store" />
                        </a>
                    </Stack>
                </SimpleGrid>
            </Container>

            <Box borderTopWidth={1} borderStyle={'solid'} borderColor={useColorModeValue('gray.200', 'gray.700')}>
                <Container
                    as={Stack}
                    maxW={'6xl'}
                    py={4}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={4}
                    justify={{ md: 'space-between' }}
                    align={{ md: 'center' }}
                >
                    <Text> © Supervizer | {new Date().getFullYear()} All rights reserved. </Text>
                    {/* TODO SOCIAL */}
                    {/* <Stack direction={'row'} spacing={6}>
                        <SocialButton label={'Twitter'} href={'#'}>
                            <FaTwitter />
                        </SocialButton>
                        <SocialButton label={'YouTube'} href={'#'}>
                            <FaYoutube />
                        </SocialButton>
                        <SocialButton label={'Instagram'} href={'#'}>
                            <FaInstagram />
                        </SocialButton>
                    </Stack> */}
                </Container>
            </Box>
        </Box>
    );
}


export default AboutFooter;