import { useMutation, useQuery, useQueryClient } from 'react-query';
import AuthService from '../services/AuthService';
import { CACHE_KEY_TOKEN, CACHE_KEY_USER } from '../constants';
import { AxiosError } from 'axios';
import { useAuthStore } from '../../store/auth/authStore';
import { BillingAccount, User } from '../../types';
import { TokenInfo } from '../../types/accountManagement';
import { getDeviceData } from '../../utils/DeviceUtils';

const useLogin = (onLogin?: (data: { token: string }) => void, onError?: (error: AxiosError<any>) => void) => {
    const queryClient = useQueryClient();
    const { setToken } = useAuthStore();

    return useMutation<{ token: string }, AxiosError<any>, { username: string; password: string }>({
        mutationFn: (credentials) => AuthService.login(credentials.username, credentials.password),
        retry: 1,
        mutationKey: CACHE_KEY_TOKEN,
        onSuccess: async (data) => {
            queryClient.invalidateQueries(CACHE_KEY_TOKEN);
            setToken(`Bearer ${data.token}`);
            if (onLogin) {
                onLogin(data);
            }
        },
        onError: onError,
        onSettled: () => {
            queryClient.refetchQueries(CACHE_KEY_USER, { inactive: true });
        },
    });
};

const useCheckLogin = (onLoginCheck?: (user: User | null) => void) => {
    const queryClient = useQueryClient();
    const { setUser, setToken } = useAuthStore();
    const deviceData = getDeviceData();

    return useQuery<User, AxiosError<any>>({
        queryFn: () => AuthService.checkTokenValidity(deviceData),
        queryKey: CACHE_KEY_USER,
        onSuccess: async (data) => {
            queryClient.invalidateQueries(CACHE_KEY_TOKEN);
            setUser(data);
            if (onLoginCheck) {
                onLoginCheck(data);
            }
        },
        onError: (error) => {
            if (onLoginCheck) onLoginCheck(null);

            if (error.response?.data?.errors?.[0]?.message === 'E_UNAUTHORIZED_ACCESS: Unauthorized access') {
                setUser(null);
                setToken(null);
            }
        },
        retry: (failureCount, error) => {
            return error?.response?.status === 401 ? false : failureCount < 3;
        },

        keepPreviousData: false,
        cacheTime: 0,
    });
};

const useLogout = (onLogout?: () => void) => {
    const queryClient = useQueryClient();
    const { setUser, setToken } = useAuthStore();
    return useMutation<void, AxiosError>({
        mutationFn: AuthService.logout,
        onSuccess: () => {
            queryClient.invalidateQueries(CACHE_KEY_USER);
            queryClient.invalidateQueries(CACHE_KEY_TOKEN);
            setUser(null);
            setToken(null);
        },
        onError: (error) => {
            queryClient.invalidateQueries(CACHE_KEY_USER);
            queryClient.invalidateQueries(CACHE_KEY_TOKEN);
            setUser(null);
            setToken(null);
        },
        onSettled: () => {
            if (onLogout) {
                onLogout();
            }
        },
    });
};

const useRegister = (onSuccess?: (data: User) => void, onError?: (error: AxiosError<any>) => void) => {
    const queryClient = useQueryClient();
    return useMutation<
        User,
        AxiosError,
        {
            userName: string;
            password: string;
            firstName: string;
            surname: string;
            inviteCode?: string;
            companyName?: string;
        }
    >({
        mutationFn: AuthService.registerUser,
        onSuccess: (data) => {
            queryClient.invalidateQueries(CACHE_KEY_USER);
            if (onSuccess) {
                onSuccess(data);
            }
        },
        onError: onError,
        retry: 0,
    });
};

const useSendPasswordResetEmail = (onSuccess?: (data: { status: string }) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<{ status: string }, AxiosError, { email: string }>({
        mutationFn: (data) => AuthService.sendPasswordResetEmail(data.email),
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useGetTokenInfo = (token: string, onSuccess?: (data: TokenInfo) => void, onError?: (error: AxiosError<any>) => void) => {
    return useQuery<TokenInfo, AxiosError<any>>({
        queryKey: ['token', token],
        queryFn: () => AuthService.getTokenInfo(token),
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useResetPassword = (onSuccess?: (data: { status: string }) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<any, AxiosError, { token: string; password: string }>({
        mutationFn: (data) => AuthService.updatePassword(data.token, data.password),
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useVerifyEmail = (onSuccess?: (data: { status: string }) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<any, AxiosError, { token: string }>({
        mutationFn: (data) => AuthService.verifyEmail(data.token),
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useCreateBillingPortalSession = () => {
    return useMutation<{ url: string | null }, AxiosError>({
        mutationFn: AuthService.createBillingPortalSession,
    });
};

const useGetBillingInfoByToken = (
    token: string,
    onSuccess?: (data: BillingAccount) => void,
    onError?: (error: AxiosError<any>) => void
) => {
    return useQuery<BillingAccount, AxiosError<any>>({
        queryKey: ['token'],
        queryFn: () => AuthService.getBillingInfoByToken(token),
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useResendEmailVerificationEmail = (onSuccess?: (data: { status: string }) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<any, AxiosError>({
        mutationFn: () => AuthService.resendEmailVerificationEmail(),
        onSuccess: onSuccess,
        onError: onError,
    });
};

export {
    useLogin,
    useCheckLogin,
    useRegister,
    useLogout,
    useSendPasswordResetEmail,
    useCreateBillingPortalSession,
    useGetTokenInfo,
    useResetPassword,
    useVerifyEmail,
    useGetBillingInfoByToken,
    useResendEmailVerificationEmail,
};
