import { useNavigate, useParams } from 'react-router-dom';
import { DeviceList } from './DeviceLIst';
import { Box } from '@chakra-ui/react';
import SecondPageHeader from '../../components/ui/SecondPageHeader';
import { useIntl } from 'react-intl';

const DeviceListScreen: React.FC = () => {
    const routeParams = useParams<{ id: string }>();
    const navigate = useNavigate();
    const intl = useIntl();

    if (!routeParams.id) {
        navigate('/404');
    }

    return (
        <>
            <SecondPageHeader title={intl.formatMessage({ id: 'device.list.title' })} onBack={() => navigate(-1)}></SecondPageHeader>
            <Box display="flex" justifyContent="center">
                <Box maxW={1080} width={'100%'}>
                        <DeviceList userId={+routeParams.id!} />
                </Box>
            </Box>
        </>
    );
};

export default DeviceListScreen;
