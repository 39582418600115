import { Card, CardBody, CardHeader, Heading, SimpleGrid } from "@chakra-ui/react";
import { Company, Statistics } from "../../types"
import { useIntl } from "react-intl";
import { StatsCard } from "../statistics/StatisticCard";
import { FiCalendar, FiUsers, FiArchive } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

type CompanyStatisticsProps = {
    statistics?: Statistics;
    companyDetails?: Company;
}
export const CompanyStatistics: React.FC<CompanyStatisticsProps> = ({ statistics, companyDetails }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    
    return (
        <Card  maxW={'lg'} h={'100%'} variant={'elevated'}  boxShadow={'2xl'} borderRadius={'lg'}>
            <CardHeader>
                <Heading size={'md'}>
                    { intl.formatMessage({id: 'home.allStatistics'})}
                </Heading>
            </CardHeader>
            <CardBody  alignContent={'center'}>
                <SimpleGrid  gap={4} columns={{ base: 1, sm: 3 }} >
                    <StatsCard 
                        title={intl.formatMessage({id: 'tab.Employees'})}
                        icon={<FiUsers size={'2em'} />}
                        stat={companyDetails?.meta?.numberOfUsers?.toString() || '-'}
                        onClick={() => navigate('/employees')}
                    />
                    <StatsCard 
                         title={intl.formatMessage({id: 'home.logins'})}
                         icon={<FiCalendar size={'2em'} />}
                         stat={statistics?.loginsCount || '-'}
                         onClick={() => navigate('/attendance')}
                    />
                    <StatsCard 
                         title={intl.formatMessage({id: 'home.reports'})}
                         icon={<FiArchive size={'2em'} />}
                         stat={statistics?.reportsCount || '-'}
                    />
                </SimpleGrid>
            </CardBody>
        </Card>
    )
}