import { StarIcon } from '@chakra-ui/icons';
import { Box, Card, CardBody, CardHeader, Heading, ListItem, Stack, StackDivider, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';

export const DocsEmployees: React.FC = () => {

    return (
        <Box pb={12}>
            <Box maxW={'1140px'} marginX={'auto'} px={{ base: 2, md: 0 }} pt={4}>
                <Heading p={4}>Účty, roly a ich význam</Heading>
                <Text>
                    Každý člen systému (zamestnanec, správca atď) musí mať v systéme svoj účet. Členovia spoločnosti sa nemusia registrovať.
                    Jediný registrovaný účet v systéme je <b>Hlavný správca</b>. Hlavný správca po registrácii vytvára účty pre zamestnancov
                    a vedúcich.
                </Text>
                <Card my={4}>
                    <CardHeader>
                        <Heading size="md">Čo účet obsahuje?</Heading>
                    </CardHeader>
                    <CardBody>
                        <UnorderedList>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Prihasovacie údaje</Text>
                                    <Text>
                                        Prihlasovacie meno a heslo slúži na prihlásenie účtu do aplikácie. Do aplikácie je možné prihlásiť
                                        každý účet.
                                    </Text>
                                </Box>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Meno a priezvisko</Text>
                                    <Text>Identifikácia zamestnanca.</Text>
                                </Box>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Profilová fotka</Text>
                                    <Text>
                                        Profilová fotka je nepovinná časť profilu. Slúži na identifikáciu zamestnanca pri zapísanej
                                        dochádzky. Profilová fotka je vyžadovaná len pre účty zamestnancov, ktorí budú zapisovať svoju
                                        dochádzku. Ak účet fotku nemá, ako profilová fotka sa nastaví fotka z prvého prihlásenia dochádzky.
                                    </Text>
                                </Box>
                            </ListItem>
                        </UnorderedList>
                    </CardBody>
                </Card>
                <Text>
                    Zamestnanci sú v systéme rozdelený do niekoľkých <b>rolí</b>. Roly umožňujú rozdeliť prístup k funkcionalitám na základe
                    kompetencií v rámci spoločnosti.
                </Text>
                <Card mt={4} id="roles">
                    <CardHeader>
                        <Heading size="md">Roly a ich význam</Heading>
                    </CardHeader>
                    <CardBody>
                        <Stack divider={<StackDivider />} spacing="4">
                            <Box id="super_owner">
                                <Heading textDecoration={'underline'} size="xs" textTransform="uppercase">
                                    Hlaný správca
                                </Heading>
                                <Text pt="2">
                                    Rola účtu, ktorý sa zaregistrova a vytvoril spoločnosť. Rola s najvyššími právami. Jediná môže spravovať
                                    predplatné
                                </Text>
                                <Text fontWeight={600}>Práva:</Text>
                                <UnorderedList fontSize="sm">
                                    <ListItem>
                                        <Text fontSize="sm">
                                            Vytvárať, upravovať a vymazávať účty s rolami správcov, kontrolórov, zamestnancov a tímové účty.
                                        </Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Zapisovať dochádzku všetkým ostatným účtom</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Kontrolovať dochádzku všetkým ostatným účtom</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Generovať výpisi dochádzky vo webovej aplikácii</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Spravovať predplatné</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Vymazať účet spoloťnosti so všetkými dátami</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Nahrávať svoju dochádzku</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Upravovať spoločnosť</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Príklad: majiteľ spoločnosti</Text>
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                            <Box id="owner">
                                <Heading textDecoration={'underline'} size="xs" textTransform="uppercase">
                                    Správca
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Rola určená pre vedúcich pracovníkov. Môže spravovať spoloťnosť.
                                </Text>
                                <Text fontWeight={600}>Práva:</Text>
                                <UnorderedList fontSize="sm">
                                    <ListItem>
                                        <Text>Vytvárať, upravovať a vymazávať účty s rolami kontrolórov, zamestnancov a tímové účty.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Zapisovať dochádzku kontrolórom a zamestnancom.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Kontrolovať dochádzku kontrolórov a zamestnancov</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Generovať výpisi dochádzky vo webovej aplikácii</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Nahrávať svoju dochádzku</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Upravovať spoločnosť</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Príklad: spolumajteľ / vysoký manažér</Text>
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                            <Box id="admin">
                                <Heading textDecoration={'underline'} size="xs" textTransform="uppercase">
                                    Kontrolór
                                </Heading>
                                <Text pt="2">
                                    Rola určená pre nižší manažment, vedúcich pracovníkov. Určená primárne na kontrolu dochádzky
                                    zamestnancov.
                                </Text>
                                <Text fontWeight={600}>Práva:</Text>
                                <UnorderedList fontSize="sm">
                                    <ListItem>
                                        <Text>Vytvárať, upravovať a vymazávať účty s rolou zamestnanca.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Kontrolovať dochádzku zamestnancov</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Nahrávať svoju dochádzku</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Príklad: Stavbyvedúci</Text>
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                            <Box id="employee">
                                <Heading textDecoration={'underline'} size="xs" textTransform="uppercase">
                                    Zamestnanec
                                </Heading>
                                <Text pt="2">Rola určená pre radových zamestnancov ktorý si budú v systéme zaznamenávať dochádzku.</Text>
                                <Text fontWeight={600}>Práva:</Text>
                                <UnorderedList fontSize="sm">
                                    <ListItem>
                                        <Text>Nahrávať svoju dochádzku</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text>Príklad: Radový zamestnanec</Text>
                                    </ListItem>
                                </UnorderedList>
                            </Box>
                            <Box id="team_account">
                                <Heading textDecoration={'underline'} size="xs" textTransform="uppercase">
                                    <StarIcon color="yellow.500" mr={2} />
                                    Tímový účet
                                </Heading>
                                <Stack spacing={1}>
                                    <Text pt="2">
                                        Tímový účet je špeciálna rola. Účet s touto rolou môže slúžiť ako <b>terminál</b> pre zapisovanie
                                        dochádzky zamestnancov. Účet môžete prihlásiť napríklad na firemné zariadenie, ktoré majú
                                        zamestnanci k dispozícií. Nastavenia účtu sú zabezpečené heslom, teda nikto neoprávený ich nemôže
                                        meniť.
                                    </Text>
                                    <UnorderedList>
                                        <ListItem>
                                            <Text>
                                                Prostredníctvom tohto účtu sa nedá nijak spravovať žiadna časť aplikácie. Účet je určený len
                                                na zapisovanie dochádzky.
                                            </Text>
                                        </ListItem>
                                        <ListItem>
                                            <Text>
                                                Tímový účet musí mať priradenú pracovnú zónu. Priradenú zónu je možné zmeniť v nastaveniach
                                                účtu (chránené heslom), alebo z účtu <b>Hlavného správcu a správcu </b>
                                            </Text>
                                        </ListItem>
                                        <ListItem>
                                            <Text>
                                                Účet s touto rolou môžete vytvoriť rovnako ako bežného zamestnanca. V zozname rolí vyberte
                                                Tímový účet a v možnosti Mód účtu vyberte <b>Dochádzka</b>
                                            </Text>
                                        </ListItem>
                                        <ListItem>
                                            <Text>Tímový účet môže byť prihlásený na viacerích zariadeniach naraz.</Text>
                                        </ListItem>
                                    </UnorderedList>
                                    <Heading mt={2} size={'sm'}>
                                        Špeciálne nastavenia
                                    </Heading>
                                    <Text>
                                        Tímový účet obsahuje špeciálne nastavenia s názvom <b>Predvolená zóna</b>. Predvolená zóne je zóna
                                        do ktorej sa zametnanci prihlasujú prostredníctvom daného tímového účtu. Pomocou tohoto nastavenia
                                        môžete ako správca zmeniť predvolenú zónu. Aby toto nastavenie fungovalo, musíte na telefóne, na
                                        ktorom je účet prihlásený zaškrtnúť možnosť <b>Synchronizovať nastavenia</b>. Pokiaľ táto možnosť
                                        zvolená nie je, zónu môžete meniť výlučne na danom zariadení.
                                    </Text>
                                    <Card backgroundColor={'secondary.500'} color={'black'}>
                                        <CardBody>
                                            <Heading size={'sm'}>Synchronizovanie nastavení</Heading>
                                            <Text>
                                                Pokiaľ máte na zariadení s prihláseným tímovým účtom zvolenú možnosť synchronizovať
                                                nastavenia, predvolená zóna bude totožná so zónou ktorú zvolíte ako správca v nastaveniach
                                                daného účtu prostredníctvom vášho účtu správcu.
                                            </Text>
                                            <Text>
                                                Ak na takomto zariadení zmeníte zónu, bez toho aby ste vypli synchronizáciu nastavení, zmena
                                                zóny ovplyvní všetky zariadenia ktoré majú prihlásený daný tímový účet a zapnutú synchronizáciu nastavení.
                                            </Text>
                                        </CardBody>
                                    </Card>
                                </Stack>
                            </Box>
                        </Stack>
                    </CardBody>
                </Card>

                <Heading p={4}>Ako upravovať používateľov</Heading>
                <Text>
                    Používatelia s rolami <b>Hlavný správca</b> a <b>Správca</b> môžu upravovať a vymazávať účty podriadených. Úprava účtu
                    zahŕňa meno, priezvisko, rolu, profilovú fotku a heslo. Tlačidlo na úpravu účtu nájdete v detaile používateľa.
                    Zamestnanci ani kontrolóri nemôžu upravovať vlastné účty.
                </Text>

                <Heading p={4} id="archive">
                    Čo je archív používateľov a na čo slúži?
                </Heading>
                <Text>
                    Ak sa rozhodente vymazať účet zamestnanca ktorý má existujúce záznamy dochádzky, účet bude presunutý do archívu. Ak účet
                    nemá žiadnu dochádzku ani hlásenia, účet bude vymazaný permanentne.
                </Text>
                <Text>
                    Archív používateľov obsahuje všetky účty, ktoré boli vymazané. Účty v archíve sú neaktívne a nemôžu sa prihlásiť do
                    aplikácie ani byť použíté pri zapísaní dochádzky. Účty v archíve môžete obnoviť. Obnovením účtu sa vráti do aktívneho
                    stavu a môže byť použitý pri zapísaní dochádzky.
                </Text>
                <Card mt={2}>
                    <CardHeader>
                        <Heading size="md">Čo sa stane keď vymažem účet zamestnanca?</Heading>
                    </CardHeader>

                    <CardBody>
                        <Stack divider={<StackDivider />} spacing="4">
                            <Text>
                                Ak vymažete účet zamestnanca, všetky jeho fotografie sú vymazané. Dochádzka zamestnanca zostáva zachovaná.
                                Ak sa rozhodnete účet zamestnanca obnoviť v archíve, budete mať k dispozíciu všetky dáta jeho dochádzky.
                            </Text>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Prihlasovacie údaje
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Obnovený účet z archívu stratí svoje pôvodné prihlasovacie meno. Nové prihlasovacie meno zistíte po
                                    obnovení účtu v detaile zamestnanca. Ostatné údaje ostávajú nezmenené.
                                </Text>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Údaje
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Všetky údaje zamestnanca sú obnovené, s výnimkou fotografií.
                                </Text>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Spoplatnenie
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Za účty v archíve neplatíte. Účet zamestnanca sa stáva plateným až po obnovení.
                                </Text>
                            </Box>
                        </Stack>
                    </CardBody>
                </Card>
            </Box>
        </Box>
    );
};
