import { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function useAOS() {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (!isInitialized) {
            console.log('INIT AOS');
            AOS.init({
                startEvent: 'appLoaded',
                duration: 700,
                easing: "ease-out-cubic",
                disable: 'phone',
                once: true,
            });
            setIsInitialized(true);
        }
    }, [isInitialized]);

    return isInitialized;
}

export default useAOS;
