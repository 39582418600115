import { Flex, useColorModeValue, HStack, Avatar, VStack, Box, Text, Button, IconButton, Icon, Image } from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { Link, ScrollRestoration, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/auth/authStore';
import { ReactNode } from 'react';
import LanguageSelectMenu from '../ui/LanguageSelectMenu';
import { FaQuestion } from 'react-icons/fa';

export const AboutHeader = () => {
    const navigation = useNavigate();
    const intl = useIntl();
    const user = useAuthStore((state) => state.user)!;

    return (
        <Flex px={4} alignItems="center" bg={'black'} justifyContent={{ base: 'space-between' }}>
            <Box display={'flex'} alignItems={'center'}>
                <Image src={`${process.env.PUBLIC_URL}/logo_simple.png`} alt="logo" height={'30px'} />
                <IconButton
                    icon={<Icon as={FaQuestion} color={'gray.200'} />}
                    fontSize={'20px'}
                    aria-label="help"
                    variant={'link'}
                    ml={2}
                    as={Link}
                    to={'/docs/home'}
                ></IconButton>
            </Box>

            <HStack spacing={{ base: '0', md: '6' }} py={1}>
                <Flex alignItems={'center'}>
                    <LanguageSelectMenu />

                    {user ? (
                        <Button
                            onClick={() => navigation('/home')}
                            variant={'ghost'}
                            role="group"
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}
                        >
                            <HStack>
                                <Avatar size={'sm'} name={user.surname || user.firstName} />
                                <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                                    <Text
                                        _groupHover={{
                                            color: 'black',
                                        }}
                                        color={'white'}
                                        fontSize="sm"
                                    >{`${user.firstName} ${user.surname || ''}`}</Text>
                                    <Text
                                        fontSize="xs"
                                        _groupHover={{
                                            color: 'gray.800',
                                        }}
                                        color="gray.400"
                                    >
                                        {intl.formatMessage({ id: user.role })}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronRight />
                                </Box>
                            </HStack>
                        </Button>
                    ) : (
                        <Button onClick={() => navigation('/login')} variant={'text'} color={'white'}>
                            {intl.formatMessage({ id: 'logIn' })}{' '}
                        </Button>
                    )}
                </Flex>
            </HStack>
        </Flex>
    );
};

export const AboutLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Box minW={'100vw'} minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <AboutHeader />
            <ScrollRestoration />
            <Box>{children}</Box>
        </Box>
    );
};
