export const countriesByAlpha3 = {
    AUT: { name: "Austria", alpha3: "AUT" },
    BEL: { name: "Belgium", alpha3: "BEL" },
    BGR: { name: "Bulgaria", alpha3: "BGR" },
    HRV: { name: "Croatia", alpha3: "HRV" },
    CYP: { name: "Cyprus", alpha3: "CYP" },
    CZE: { name: "Czech Republic", alpha3: "CZE" },
    DNK: { name: "Denmark", alpha3: "DNK" },
    EST: { name: "Estonia", alpha3: "EST" },
    FIN: { name: "Finland", alpha3: "FIN" },
    FRA: { name: "France", alpha3: "FRA" },
    DEU: { name: "Germany", alpha3: "DEU" },
    GRC: { name: "Greece", alpha3: "GRC" },
    HUN: { name: "Hungary", alpha3: "HUN" },
    ISL: { name: "Iceland", alpha3: "ISL" },
    IRL: { name: "Ireland", alpha3: "IRL" },
    ITA: { name: "Italy", alpha3: "ITA" },
    LVA: { name: "Latvia", alpha3: "LVA" },
    LIE: { name: "Liechtenstein", alpha3: "LIE" },
    LTU: { name: "Lithuania", alpha3: "LTU" },
    LUX: { name: "Luxembourg", alpha3: "LUX" },
    MLT: { name: "Malta", alpha3: "MLT" },
    MCO: { name: "Monaco", alpha3: "MCO" },
    NLD: { name: "Netherlands", alpha3: "NLD" },
    NOR: { name: "Norway", alpha3: "NOR" },
    POL: { name: "Poland", alpha3: "POL" },
    PRT: { name: "Portugal", alpha3: "PRT" },
    ROU: { name: "Romania", alpha3: "ROU" },
    RUS: { name: "Russia", alpha3: "RUS" },
    SMR: { name: "San Marino", alpha3: "SMR" },
    SRB: { name: "Serbia", alpha3: "SRB" },
    SVK: { name: "Slovakia", alpha3: "SVK" },
    SVN: { name: "Slovenia", alpha3: "SVN" },
    ESP: { name: "Spain", alpha3: "ESP" },
    SWE: { name: "Sweden", alpha3: "SWE" },
    CHE: { name: "Switzerland", alpha3: "CHE" },
    UKR: { name: "Ukraine", alpha3: "UKR" },
    GBR: { name: "United Kingdom", alpha3: "GBR" }
  } as const;