import { Client, DeviceData, DeviceType } from "../types";

export function getDeviceData(): DeviceData {
    return {
        client: Client.WEB,
        type: getDeviceType(),
        name: getDeviceName(),
    }
}


function getDeviceName(): string {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    } else if (/Windows NT/.test(userAgent)) {
        return "Windows";
    } else if (/Macintosh/.test(userAgent)) {
        return "Mac";
    } else if (/Linux/.test(userAgent)) {
        return "Linux";
    } else {
        return "-";
    }
}


function getDeviceType(): DeviceType {
    const userAgent = navigator.userAgent;

    if (/Mobi|Android|iPhone|iPad|iPod/.test(userAgent)) {
        return DeviceType.MOBILE;
    } else {
        return DeviceType.DESKTOP;
    }
}