import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { InfoResult } from '../../components/results/InfoResult';
import { useIntl } from 'react-intl';

export type ResultScreenParams = {
    title: string;
    text?: string;
    type: 'success' | 'error' | 'info';
};

const CommonResultScreen: React.FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { state } = useLocation();

    return (
        <InfoResult
            title={state.title || intl.formatMessage({ id: 'somethingWentWrong' })}
            text={state.text || state.description || ''}
            actionText={intl.formatMessage({ id: 'returnToApp' })}
            onAction={() => navigate('/home')}
            type={state.type}
        />
    );
};

export default CommonResultScreen;
