import { Checkbox, FormControl, Link, Text } from "@chakra-ui/react";
import {Link as RouterLink} from 'react-router-dom'

type FormikCheckBoxProps = React.ComponentProps<typeof Checkbox> & {
    label: string;
    values: Record<string, any>;
    fieldName: string;
    handleChange: (field: string) => any;
    errors: Record<string, any>;
    handleBlur: (field: string) => any;
    touched: Record<string, any>;
    linkText?: string;
    linkTo?: string;
}

export const FormikCheckBox: React.FC<FormikCheckBoxProps> = ({
    values,
    label,
    fieldName,
    handleBlur,
    handleChange,
    errors,
    touched,
    linkText,
    linkTo,
    ...restProps
}) => {
    return (
        <FormControl isDisabled={restProps.isDisabled} isInvalid={!!(errors[fieldName] && touched[fieldName])}>
            <Checkbox
                id={fieldName}
                isInvalid={!!(errors[fieldName] && touched[fieldName])}
                onBlur={handleBlur(fieldName)}
                onChange={(e) => handleChange(fieldName)(e.target.checked?.valueOf()?.toString())}
                errorBorderColor="red.500"
                {...restProps}
            >
                {label}
                
            {' '}
            { linkText && linkTo && <Link fontWeight={'500'} as={RouterLink} to={linkTo} >{linkText}</Link> }
            </Checkbox> 
            {!!(errors[fieldName] && touched[fieldName]) ? (
                <Text color="red.500" fontSize="sm">
                    {errors[fieldName] as string}
                </Text>
            ) : null}
        </FormControl>
    )
};