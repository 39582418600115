import { Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton, VStack, SlideFade, Box, Button, HStack } from '@chakra-ui/react';
import { useNotificationStore } from '../../store/ui/NotificationStore';

/**
 * Component that renders list of notifications from store
 */
const NotificationList = () => {
    const notifications = useNotificationStore((state) => state.notifications);
    const removeNotification = useNotificationStore((state) => state.removeNotification);

    const closeNotification = (index: number) => {
        removeNotification(index);
    };

    const getButtonColorScheme = (notType: string) => {
        switch (notType) {
            case 'success':
                return 'green';
            case 'error':
                return 'red';
            case 'info':
                return 'primary';
            case 'warning':
                return 'yellow';
            default:
                return 'primary';
        }
    };

    return (
        <VStack py={notifications.length > 0 ? 2 : 0}>
            {notifications.map((notification, index) => (
                <Box width="100%" key={index}>
                    <SlideFade in={true} offsetY="20px">
                        <Alert flex={1} borderRadius={'lg'} variant="left-accent" display={'flex'} flexDir={{base: 'column', md: 'row' }} status={notification.type}>
                            <AlertIcon />
                            <AlertTitle mr={2}>{notification.title}</AlertTitle>
                            <AlertDescription flex={1}>{notification.description}</AlertDescription>
                            <HStack alignSelf="end" position="relative" right={0} top={{base: 2, md: 0}}>
                                {notification.actionText && notification.action && (
                                    <Button
                                        colorScheme={getButtonColorScheme(notification.type)}
                                        borderRadius={'full'}
                                        onClick={() => notification.action!()}
                                    >
                                        {notification.actionText}
                                    </Button>
                                )}
                                <CloseButton onClick={() => closeNotification(index)} />
                            </HStack>
                        </Alert>
                    </SlideFade>
                </Box>
            ))}
        </VStack>
    );
};

export default NotificationList;
