import React from 'react';
import { AttendanceLoginDetail } from '../../types';
import { Skeleton, Table, TableContainer, Td, Tr } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

export const AttendanceLoginInfoTable: React.FC<{ attendance?: AttendanceLoginDetail; isLoading: boolean }> = ({
    attendance,
    isLoading,
}) => {
    const intl = useIntl();

    if (isLoading || !attendance) {
        return <Skeleton height={250} />;
    }
    return (
        <TableContainer>
            <Table>
                {attendance?.approver && (
                    <Tr>
                        <Td fontWeight={600}>{intl.formatMessage({ id: 'field.approvedBy' })}</Td>
                        <Td>
                            {attendance.approver.firstName} {attendance.approver.surname}
                        </Td>
                    </Tr>
                )}
                {attendance?.creator && (
                    <Tr>
                        <Td fontWeight={600}>{intl.formatMessage({ id: attendance.manual ? 'attendance.manual' : 'createdBy' })}</Td>
                        <Td>
                            {attendance.creator.firstName} {attendance.creator.surname}
                        </Td>
                    </Tr>
                )}
                <Tr>
                    <Td fontWeight={600}>{intl.formatMessage({ id: 'field.date' })}</Td>
                    <Td>
                        {intl.formatDate(new Date(attendance.createdAt), {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}
                    </Td>
                </Tr>
                <Tr>
                    <Td fontWeight={600}>{intl.formatMessage({ id: 'attendance.time' })}</Td>
                    <Td>{intl.formatTime(attendance.createdAt)}</Td>
                </Tr>
                <Tr>
                    <Td fontWeight={600}>{intl.formatMessage({ id: 'type' })}</Td>
                    <Td>{intl.formatMessage({ id: attendance.direction })}</Td>
                </Tr>
                <Tr>
                    <Td fontWeight={600}>{intl.formatMessage({ id: 'positionValidation' })}</Td>
                    <Td>{intl.formatMessage({ id: `POSITION_${attendance.positionValidation}` })}</Td>
                </Tr>

                <Tr>
                    <Td fontWeight={600}>{intl.formatMessage({ id: 'faceRecognition' })}</Td>
                    <Td>{intl.formatMessage({ id: `FACE_${attendance.faceValidation}` })}</Td>
                </Tr>
                <Tr>
                    <Td fontWeight={600}>{intl.formatMessage({ id: 'note' })}</Td>
                    <Td> {attendance.note || '-'}</Td>
                </Tr>
                {attendance.isOfflineLogged && attendance.updatedAt && (
                    <Tr>
                        <Td fontWeight={600}>{intl.formatMessage({ id: 'attendance.offline.uploadedAt' })}</Td>
                        <Td> {`${intl.formatDate(attendance.updatedAt)} ${intl.formatTime(attendance.uploadedAt!)}`} </Td>
                    </Tr>
                )}
            </Table>
        </TableContainer>
    );
};
