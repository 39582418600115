import { useGetAttendanceLoginHistory } from '../../../query/resource-hooks/company';
import { AttendanceLoginHistory } from '../../../types';
import { AttendanceHistoryItem } from './AttendanceHistoryItem';
import InfiniteList from '../../lists/InfiniteList';

const AttendanceHistoryList: React.FC<{ loginId: number }> = ({ loginId }) => {
    const zonesQuery = useGetAttendanceLoginHistory(loginId, {});

    return (
        <InfiniteList<AttendanceLoginHistory>
            data={zonesQuery.data}
            fetchNextPage={zonesQuery.fetchNextPage}
            hasNextPage={zonesQuery.hasNextPage}
            isFetchingNextPage={zonesQuery.isFetchingNextPage}
            renderItem={(item, index) => <AttendanceHistoryItem item={item} key={index} />}
            isLoading={zonesQuery.isLoading || zonesQuery.isRefetching}
        />
    );
};

export default AttendanceHistoryList;
