import {
    Avatar,
    Box,
    useTheme,
    ListItem,
    VStack,
    Text,
    HStack,
    Divider,
    Card,
    useDisclosure,
    IconButton,
    CardBody,
    Tooltip,
    useToast,
} from '@chakra-ui/react';
import { User } from '../../../types';
import { useIntl } from 'react-intl';
import { useArchivedEmployeesQuery, useRestoreArchivedUser } from '../../../query/resource-hooks/company';
import InfiniteList from '../../../components/lists/InfiniteList';
import { FiRefreshCcw } from 'react-icons/fi';
import { useAuthStore } from '../../../store/auth/authStore';
import { useState } from 'react';
import { ConfirmActionDialog } from '../../../components/ui/ConfirmActionDialog';
import UserPolicy from '../../../policies/UserPolicy';
import SecondPageHeader from '../../../components/ui/SecondPageHeader';
import { useNavigate } from 'react-router-dom';

const EmployeeListItem: React.FC<{ user: User; onClick?: () => void }> = ({ user, onClick }) => {
    const intl = useIntl();
    const theme = useTheme();
    return (
        <>
            <ListItem
                _hover={{
                    bg: theme.colors.primary[500],
                    color: 'white',
                }}
                padding={1}
                role="group"
                cursor="pointer"
                borderRadius="lg"
                onClick={onClick}
            >
                <HStack>
                    <Avatar name={user.surname || user.firstName} />
                    <VStack align={'start'} spacing={0}>
                        <Text fontWeight={'600'}>{`${user?.surname || ''} ${user.firstName}`}</Text>
                        <Text fontSize={'small'}>{user.role ? intl.formatMessage({ id: user.role }) : '-'}</Text>
                    </VStack>
                </HStack>
            </ListItem>
            <Divider />
        </>
    );
};

const ArchivedEmployeesList: React.FC = () => {
    const intl = useIntl();
    const loggedUser = useAuthStore((s) => s.user);
    const navigate = useNavigate();
    const restoreDialogDisclosure = useDisclosure();
    const employeesQuery = useArchivedEmployeesQuery({});

    const toastContext = useToast();

    const restoreUser = useRestoreArchivedUser(
        () => {
            employeesQuery.refetch();
            restoreDialogDisclosure.onClose();
            toastContext({
                status: 'success',
                title: intl.formatMessage({ id: 'archive.restore.success' }),
                position: 'top',
                duration: 2500,
            });
            restoreUser.reset();
            setUserToDelete(null);
            restoreDialogDisclosure.onClose();
        },
        (error) => {
            toastContext({
                status: 'error',
                title: intl.formatMessage({ id: 'archive.restore.error' }),
                description: error.message,
                position: 'top',
                duration: 5000,
                isClosable: true,
            });
            restoreUser.reset();
            setUserToDelete(null);
            restoreDialogDisclosure.onClose();
        }
    );

    const [userToDelete, setUserToDelete] = useState<User | null>(null);

    return (
        <>
            <Box textAlign="center">
                <SecondPageHeader onBack={() => navigate(-1)} title={intl.formatMessage({ id: 'archive.employees.title' })} />
                <HStack justifyContent={'end'} mx={{ base: 2, md: 4 }} align={'center'}>
                    <Tooltip label={intl.formatMessage({ id: 'refresh' })}>
                        <IconButton
                            icon={<FiRefreshCcw />}
                            aria-label="refresh"
                            m={2}
                            variant={'text'}
                            onClick={() => employeesQuery.refetch()}
                        />
                    </Tooltip>
                </HStack>

                <Card mx={{ base: 2, md: 4 }}>
                    <CardBody>
                        <InfiniteList<User>
                            data={employeesQuery.data}
                            fetchNextPage={employeesQuery.fetchNextPage}
                            hasNextPage={employeesQuery.hasNextPage}
                            isFetchingNextPage={employeesQuery.isFetchingNextPage}
                            isLoading={employeesQuery.isLoading || employeesQuery.isRefetching}
                            renderItem={(user, index) => (
                                <EmployeeListItem
                                    onClick={() => {
                                        if (UserPolicy.canRestoreUser(loggedUser!, user)) {
                                            setUserToDelete(user);
                                            restoreDialogDisclosure.onOpen();
                                        } else {
                                            toastContext({
                                                variant: 'error',
                                                title: intl.formatMessage({ id: 'policy.noAuthority' }),
                                                position: 'top',
                                                duration: 5000,
                                                isClosable: true,
                                            });
                                        }
                                    }}
                                    key={index}
                                    user={user}
                                />
                            )}
                        />
                    </CardBody>
                </Card>
            </Box>
            <ConfirmActionDialog
                isOpen={restoreDialogDisclosure.isOpen}
                onClose={() => {
                    setUserToDelete(null);
                    restoreDialogDisclosure.onClose();
                }}
                confirmButtonText={intl.formatMessage({ id: 'archive.restore' })}
                title={intl.formatMessage({ id: 'archive.employees.restore.title' })}
                text={intl.formatMessage(
                    { id: 'archive.employees.restore.text' },
                    {
                        name: userToDelete?.fullName,
                        role: userToDelete ? intl.formatMessage({ id: userToDelete?.role }) : '',
                        deletedAt: intl.formatDate(userToDelete?.deletedAt!),
                    }
                )}
                onConfirm={() => {
                    if (userToDelete) {
                        restoreUser.mutate(userToDelete.id);
                    }
                }}
                isLoading={restoreUser.isLoading}
            />
        </>
    );
};

export default ArchivedEmployeesList;
