import { Box, Card, CardHeader, Heading, SimpleGrid } from '@chakra-ui/react';
import { useGetCompanyDetail, useGetCompanyStatistics } from '../../query/resource-hooks/company';
import CompanyCard from '../../components/company/CompanyCard';
import { CompanyStatistics } from '../../components/company/CompanyStatistics';
import AttendanceChart from '../../components/attendance/AttendanceChart';
import { useIntl } from 'react-intl';

const HomeScreen: React.FC = () => {
    const intl = useIntl();
    const company = useGetCompanyDetail();
    const statistics = useGetCompanyStatistics({});
    return (
        <Box display={'flex'} width={'100%'} justifyItems={'center'} justifyContent={'center'} py={4} px={{base: 2, xl: 0}}>
            <SimpleGrid minW={300} gap={4} columns={{ base: 1, xl: 2 }}>
                <Box h={'100%'}>
                    <CompanyCard onLogoUpdate={() => company.refetch()} company={company.data!} />
                </Box>
                <Box>
                    <CompanyStatistics statistics={statistics.data!} companyDetails={company.data!} />
                </Box>
                <Box gridColumn={{ base: 'span 1', xl: 'span 2' }}>
                    <Card height={400} boxShadow={'lg'} borderRadius={'lg'}>
                        <CardHeader>
                            <Heading size={'md'}>{intl.formatMessage({ id: 'attendance' })}</Heading>
                        </CardHeader>
                        <AttendanceChart statistics={statistics.data!} />
                    </Card>
                </Box>
            </SimpleGrid>
        </Box>
    );
};

export default HomeScreen;
