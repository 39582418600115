import React, { ReactNode } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    useTheme,
    useColorMode,
    Button,
    Tooltip,
    Image,
} from '@chakra-ui/react';
import { FiHome, FiMenu, FiChevronDown, FiCalendar, FiUsers, FiMap } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { FaMoon, FaSun } from 'react-icons/fa';
import './Navbar.css';
import { useLogout } from '../../query/resource-hooks/auth';
import { useAuthStore } from '../../store/auth/authStore';
import { useIntl } from 'react-intl';
import { User } from '../../types';
import { MdLanguage } from 'react-icons/md';
import { useSettingsStore } from '../../store/ui/SettingsStore';
import LanguageSelectModal from '../ui/LanguageSelectModal';
import NotificationList from '../ui/NotificationsList';
import { BsQuestion } from 'react-icons/bs';
import { QuestionIcon } from '@chakra-ui/icons';
import { Breadcrumbs } from './Breadcrumbs';

interface LinkItemProps {
    name: string;
    icon: IconType;
    href?: string;
}

const LinkItems: Array<LinkItemProps> = [
    { name: 'home.title', icon: FiHome, href: '/home' },
    { name: 'tab.attendance', icon: FiCalendar, href: '/attendance' },
    { name: 'tab.Employees', icon: FiUsers, href: '/employees' },
    { name: 'tab.Zones', icon: FiMap, href: '/zones' },
];

export default function SidebarWithHeader({ children }: { children: ReactNode }) {
    const user = useAuthStore((state) => state.user)!;
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box>
            <Box width="full" zIndex={'sticky'}>
                <Header user={user} onOpen={onOpen} />
            </Box>

            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent>
                    <SidebarContent companyName={user?.company?.name} onClose={onClose} />
                </DrawerContent>
            </Drawer>

            <Flex>
                <Box>
                    <SidebarContent companyName={user?.company?.name} onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
                </Box>
                <Box
                    bg={useColorModeValue('gray.100', 'gray.900')}
                    height="calc(100vh - 84px)"
                    overflowY="auto"
                    flex="1"
                    borderRadius="22"
                    mr={{ base: 0, md: 2 }}
                >
                    <Box px={2}>
                        <NotificationList />
                    </Box>
                    {children}
                </Box>
            </Flex>
        </Box>
    );
}

const MenuColorSwitcher = () => {
    const { toggleColorMode } = useColorMode();
    const intl = useIntl();
    const text = useColorModeValue(intl.formatMessage({ id: 'colorMode.dark' }), intl.formatMessage({ id: 'colorMode.light' }));
    const SwitchIcon = useColorModeValue(FaMoon, FaSun);

    return (
        <MenuItem onClick={toggleColorMode} icon={<SwitchIcon />} aria-label={`Switch to ${text} mode`}>
            {text}
        </MenuItem>
    );
};
interface SidebarProps extends BoxProps {
    onClose: () => void;
    companyName?: string;
}

const SidebarContent = ({ onClose, companyName, ...rest }: SidebarProps) => {
    const intl = useIntl();
    return (
        <Box transition="3s ease" w={{ base: 'full', md: 60 }} h="full" {...rest}>
            <Flex direction="column" h="full" justifyContent="space-between">
                <Box pt={{base: 0, md: 4}}>
                    <Flex display={{ base: 'flex', md: 'none' }} h="20" alignItems="center" mx="8" justifyContent="space-between">
                        <Link to={'/'}>
                            <Image src={`${process.env.PUBLIC_URL}/logo_simple.png`} alt="logo" height={'50px'} />
                        </Link>
                        <Flex flexDir={'column'}>
                            <Text pl={1} fontSize="xl" fontFamily="monospace" fontWeight="bold">
                                Supervizer
                            </Text>
                            <Text noOfLines={1} pl={1} fontSize="md" color="gray.400">
                                {companyName}
                            </Text>
                        </Flex>
                        <CloseButton onClick={onClose} />
                    </Flex>
                    {LinkItems.map((link) => (
                        <NavItem key={link.name} icon={link.icon} onClick={onClose} href={link.href}>
                            {intl.formatMessage({ id: link.name })}
                        </NavItem>
                    ))}
                </Box>
                <Box>
                    <Button
                        as={Link}
                        to={'/docs/home'}
                        py={4}
                        rightIcon={<Icon as={BsQuestion} boxSize={6} />}
                        width={'full'}
                        variant={'ghost'}
                    >
                        {intl.formatMessage({ id: 'docs.help' })}
                    </Button>
                </Box>
            </Flex>
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    href?: string;
    children: ReactNode;
}
const NavItem = ({ icon, children, href, ...rest }: NavItemProps) => {
    const theme = useTheme();
    const selectedColor = useColorModeValue(theme.colors.gray[200], theme.colors.gray[700]);

    return (
        <NavLink to={href || '#'} style={{ textDecoration: 'none' }}>
            {({ isActive }) => (
                <Flex
                    align="center"
                    p="4"
                    mx="4"
                    borderRadius="lg"
                    role="group"
                    cursor="pointer"
                    bg={isActive ? selectedColor : undefined}
                    boxShadow={isActive ? 'lg' : undefined}
                    _hover={{
                        bg: theme.colors.primary[500],
                        color: 'white',
                    }}
                    {...rest}
                >
                    {icon && (
                        <Icon
                            mr="4"
                            fontSize="16"
                            _groupHover={{
                                color: 'white',
                            }}
                            as={icon}
                        />
                    )}
                    {children}
                </Flex>
            )}
        </NavLink>
    );
};

interface HeaderProps extends FlexProps {
    onOpen: () => void;
    user: User;
}

const Header = ({ onOpen, user, ...rest }: HeaderProps) => {
    const navigation = useNavigate();
    const logoutMutation = useLogout(() => navigation('/login'));
    const intl = useIntl();

    const languageDisclosure = useDisclosure();

    const language = useSettingsStore((state) => state.language);

    function handleLogout() {
        logoutMutation.mutate();
    }

    return (
        <Flex
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            justifyContent={{ base: 'space-between', md: 'space-between' }}
            {...rest}
        >
            <LanguageSelectModal isOpen={languageDisclosure.isOpen} onClose={languageDisclosure.onClose} />

            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <HStack justifyContent={{ base: 'center', md: 'start' }}  overflowX="auto" >
                <Box w={60} display={{ base: 'none', md: 'block' }}>
                    <Flex h="20" alignItems="center" mr="4" pl={'4'} mt={2}  bg={useColorModeValue('gray.100', 'gray.900')} borderRadius={22}>
                        <Link to={'/'}>
                            <Image src={`${process.env.PUBLIC_URL}/logo_simple.png`} alt="logo" height={'50px'} />
                        </Link>
                        <Flex flexDir={'column'} pl={2}>
                            <Text fontSize="xl" fontFamily="monospace" fontWeight="bold">
                                Supervizer
                            </Text>
                            <Tooltip label={user?.company?.name ?? ''} aria-label="Company name">
                                <Text noOfLines={1} fontSize="md" color="gray.500">
                                    {user?.company?.name ?? ''}
                                </Text>
                            </Tooltip>
                        </Flex>
                    </Flex>
                </Box>
                <Box paddingX={{ base: 2, md: 0 }}  overflowX="auto">
                    <Breadcrumbs />
                </Box>
            </HStack>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar size={'sm'} name={user.surname || user.firstName} />
                                <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                                    <Text fontSize="sm">{`${user.firstName} ${user.surname || ''}`}</Text>
                                    <Text fontSize="xs" color="gray.600">
                                        {intl.formatMessage({ id: user.role })}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList bg={useColorModeValue('white', 'gray.900')} borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            <MenuItem onClick={() => navigation('/profile')}>{intl.formatMessage({ id: 'profile' })}</MenuItem>
                            <MenuItem onClick={() => navigation('/company')}>{intl.formatMessage({ id: 'company' })}</MenuItem>
                            <MenuDivider />
                            <MenuColorSwitcher />
                            <MenuItem onClick={languageDisclosure.onOpen} icon={<MdLanguage />} command={language || ''}>
                                {intl.formatMessage({ id: 'language' })}
                            </MenuItem>
                            <MenuItem icon={<QuestionIcon />} onClick={() => navigation('/docs')}>
                                {intl.formatMessage({ id: 'docs.title' })}
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => handleLogout()}>{intl.formatMessage({ id: 'logOut' })}</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};
