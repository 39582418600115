import {
    Box,
    Button,
    ButtonGroup,
    Card,
    Center,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    Skeleton,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tooltip,
    Tr,
    useColorModeValue,
    useDisclosure,
    useTheme,
    useToast,
} from '@chakra-ui/react';
import { AttendanceLogin, AttendanceLoginDetail } from '../../types';
import { LoadingCard } from '../ui/LoadingCard';
import { useIntl } from 'react-intl';
import { createImageUrl } from '../../query/apiClient';
import { FaceRecognitionIcon, ManualAttendanceIcon, PositionValidationIcon } from '../lists/AttendanceTableItem';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import UserPolicy from '../../policies/UserPolicy';
import { useAuthStore } from '../../store/auth/authStore';
import { UpdateAttendanceModal } from './UpdateAttendanceModal';
import { AxiosError } from 'axios';
import { DeleteAlertDialog } from '../ui/DeleteAlertDialog';
import { useDeleteAttendance } from '../../query/resource-hooks/company';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FiLogIn, FiLogOut } from 'react-icons/fi';
type AttendanceDetailCardProps = {
    attendance?: AttendanceLoginDetail;
    onUpdateSuccess?: (attendance: AttendanceLogin) => void;
    onDeleteSuccess?: () => void;
    onUpdateError?: (error: AxiosError) => void;
    onDeleteError?: (error: AxiosError) => void;
};

export const AttendanceDetailCard: React.FC<AttendanceDetailCardProps> = ({
    attendance,
    onUpdateError,
    onUpdateSuccess,
    onDeleteError,
    onDeleteSuccess,
}) => {
    const intl = useIntl();
    const toast = useToast();
    const deleteAttendance = useDeleteAttendance(handleDeleteSuccess, handleDeleteError);
    const theme = useTheme();

    const [imageLoaded, setImageLoaded] = useState(false);

    const loggedUser = useAuthStore((state) => state.user);
    const usedColor = useColorModeValue('white', 'gray.900');
    const updateRecordDisclosure = useDisclosure();
    const deleteAlertDisclosure = useDisclosure();

    function handleDeleteAttendance() {
        deleteAttendance.mutate(attendance?.id!);
        deleteAlertDisclosure.onClose();
    }

    function handleDeleteSuccess() {
        toast({
            title: intl.formatMessage({ id: 'data.deleted' }),
            status: 'success',
            duration: 10000,
            isClosable: true,
            position: 'top',
        });
        deleteAlertDisclosure.onClose();
        onDeleteSuccess?.();
    }

    function handleDeleteError(error: AxiosError) {
        toast({
            title: intl.formatMessage({ id: 'somethingWentWrong' }),
            status: 'error',
            duration: 10000,
            isClosable: true,
            position: 'top',
        });
        deleteAlertDisclosure.onClose();
        onDeleteError?.(error);
    }

    if (!attendance) {
        return (
            <Center>
                <LoadingCard width={'md'} />
            </Center>
        );
    }

    return (
        <>
            <UpdateAttendanceModal
                onSuccess={onUpdateSuccess}
                onError={onUpdateError}
                isOpen={updateRecordDisclosure.isOpen}
                onClose={updateRecordDisclosure.onClose}
                updatedRecord={attendance}
            />
            <DeleteAlertDialog
                isLoading={deleteAttendance.isLoading}
                isOpen={deleteAlertDisclosure.isOpen}
                onClose={deleteAlertDisclosure.onClose}
                onConfirm={handleDeleteAttendance}
            />
            <Stack>
                <Stack
                    borderWidth="1px"
                    borderRadius="lg"
                    direction={{ base: 'column', md: 'row' }}
                    bg={usedColor}
                    boxShadow={'2xl'}
                    padding={4}
                    minWidth={{ base: 0, md: 400 }}
                >
                    <Flex borderRadius={'md'} flex={1} bg="primary.500">
                        {attendance?.loginImageUrl && (
                            <Skeleton isLoaded={imageLoaded}>
                                <Image
                                    onLoad={() => setImageLoaded(true)}
                                    borderRadius={'md'}
                                    maxH={500}
                                    maxW={500}
                                    aspectRatio={1}
                                    objectFit="cover"
                                    boxSize="100%"
                                    src={createImageUrl(attendance?.loginImageUrl)}
                                />
                            </Skeleton>
                        )}
                    </Flex>
                    <Stack flex={1} flexDirection="column" alignItems="center" p={1} pt={2}>
                        <Heading
                            _hover={{ textDecoration: 'underline' }}
                            as={Link}
                            to={'/employees/detail/' + attendance.userId}
                            fontSize={'2xl'}
                            fontFamily={'body'}
                        >
                            {attendance.user?.firstName} {attendance.user?.surname || ''}
                        </Heading>
                        {attendance?.creator && attendance.createdBy !== attendance.userId && (
                            <Text fontWeight={600} color={'gray.500'} size="sm" mb={4}>
                                {intl.formatMessage({ id: 'createdBy' })}: {attendance.creator.firstName} {attendance.creator.surname}
                            </Text>
                        )}
                        <Tooltip label={intl.formatMessage({ id: 'zone' })}>
                            <Text
                                _hover={{ textDecoration: 'underline' }}
                                as={Link}
                                to={'/zones/detail/' + attendance.zoneId}
                                fontSize={'lg'}
                                fontWeight={600}
                            >
                                {attendance.zone?.name}
                            </Text>
                        </Tooltip>
                        <Text fontWeight={600} fontSize={'lg'} mb={2}>
                            {`${intl.formatDate(attendance.createdAt)} ${intl.formatTime(attendance.createdAt)}`}
                        </Text>
                        <HStack>
                            <Tooltip label={intl.formatMessage({ id: attendance.direction })}>
                                <Box>
                                    <Icon
                                        as={attendance.direction === 'IN' ? FiLogIn : FiLogOut}
                                        color={attendance.direction === 'IN' && theme.colors.primary[500]}
                                        boxSize="6"
                                    />
                                </Box>
                            </Tooltip>
                            <PositionValidationIcon item={attendance} />
                            <FaceRecognitionIcon item={attendance} />
                            <ManualAttendanceIcon item={attendance} />
                        </HStack>

                        <Flex height={'100%'} alignItems={'end'}>
                            <ButtonGroup>
                                <Button
                                    isDisabled={!UserPolicy.canEditAttendance(loggedUser!, attendance)}
                                    onClick={updateRecordDisclosure.onOpen}
                                    leftIcon={<EditIcon />}
                                    borderRadius={'full'}
                                >
                                    {intl.formatMessage({ id: 'edit' })}
                                </Button>
                                <Button
                                    isDisabled={!UserPolicy.canEditAttendance(loggedUser!, attendance)}
                                    onClick={deleteAlertDisclosure.onOpen}
                                    leftIcon={<DeleteIcon />}
                                    borderRadius={'full'}
                                    colorScheme="red"
                                >
                                    {intl.formatMessage({ id: 'delete' })}
                                </Button>
                            </ButtonGroup>
                        </Flex>
                    </Stack>
                </Stack>
                <Card mt={2} boxShadow={'2xl'}>
                    <TableContainer>
                        <Table size="sm">
                            <Tbody>
                                {attendance?.approver && (
                                    <Tr>
                                        <Td fontWeight={600}>{intl.formatMessage({ id: 'field.approvedBy' })}</Td>
                                        <Td>
                                            {attendance.approver.firstName} {attendance.approver.surname}
                                        </Td>
                                    </Tr>
                                )}
                                <Tr>
                                    <Td fontWeight={600}>{intl.formatMessage({ id: 'type' })}</Td>
                                    <Td>{intl.formatMessage({ id: attendance.direction })}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontWeight={600}>{intl.formatMessage({ id: 'positionValidation' })}</Td>
                                    <Td>{intl.formatMessage({ id: `POSITION_${attendance.positionValidation}` })}</Td>
                                </Tr>

                                <Tr>
                                    <Td fontWeight={600}>{intl.formatMessage({ id: 'faceRecognition' })}</Td>
                                    <Td>{intl.formatMessage({ id: `FACE_${attendance.faceValidation}` })}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Card>
            </Stack>
        </>
    );
};
