import { useIntl } from 'react-intl';
import { useVerifyEmail } from '../../query/resource-hooks/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Spinner } from '@chakra-ui/react';

export const EmailVerification: React.FC<{ token: string }> = ({ token }) => {
    const intl = useIntl();
    const verifyEmail = useVerifyEmail(onSuccess, onError);
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            verifyEmail.mutate({ token });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onSuccess() {
        navigate('/result', {
            state: {
                title: intl.formatMessage({ id: 'emailVerified' }),
                text: intl.formatMessage({ id: 'emailVerifiedText' }),
                type: 'success',
            },
        });
    }

    function onError() {
        navigate('/result', {
            state: {
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                text: intl.formatMessage({ id: 'somethingWentWrong.tryLater' }),
                type: 'error',
            },
        });
    }

    return (
        <Box textAlign="center" py={10} px={6}>
            <Spinner />
            <Heading as="h2" size="xl" mt={6} mb={2}>
                {intl.formatMessage({ id: 'emailVerifying' })}
            </Heading>
        </Box>
    );
};

