import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useGetAttendanceById } from '../../query/resource-hooks/company';
import { Box, Card, Flex } from '@chakra-ui/react';
import SecondPageHeader from '../../components/ui/SecondPageHeader';
import { useIntl } from 'react-intl';
import { CalendarIcon } from '@chakra-ui/icons';
import { AttendancePreview } from '../../components/attendance/AttendancePreview';
import { useAuthStore } from '../../store/auth/authStore';
import { AttendanceLoginInfoTable } from '../../components/attendance/AttendanceLoginInfoTable';

const AttendanceDetailScreen: React.FC = () => {
    const routeParams = useParams<{ attendanceId: string }>();
    const intl = useIntl();
    const attendanceQuery = useGetAttendanceById(+routeParams.attendanceId!);
    const navigate = useNavigate();
    const loggedUser = useAuthStore((s) => s.user);

    if (attendanceQuery.error) {
        return <Navigate to={'/404'} />;
    }

    return (
        <>
            <SecondPageHeader
                title={intl.formatMessage({ id: 'tab.attendance.detail.title' })}
                onBack={() => navigate(-1)}
                menuListItems={[
                    {
                        title: intl.formatMessage({ id: 'title.history' }),
                        onClick: () => navigate(`history`),
                        icon: <CalendarIcon />,
                    },
                ]}
            />
            <Box px={2} mb={{base: 4, md: 20}}>
                <AttendancePreview
                    loggedUser={loggedUser!}
                    isLoading={attendanceQuery.isLoading}
                    onDeleteSuccess={() => navigate(-1)}
                    onUpdateSuccess={() => attendanceQuery.refetch()}
                    attendance={attendanceQuery.data}
                />
            </Box>
            <Flex justify={'center'}>
                <Card maxW={900} w={'100%'}>
                    <Box >
                        <AttendanceLoginInfoTable attendance={attendanceQuery.data} isLoading={attendanceQuery.isLoading} />
                    </Box>
                </Card>
            </Flex>
        </>
    );
};

export default AttendanceDetailScreen;
