import L, { LatLngBounds } from 'leaflet';
import { Circle, MapContainer, Marker, Polygon, Popup, TileLayer } from 'react-leaflet';
import { Zone } from '../../types';
import { LatLngExpression } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import './MapPreview.css';
import 'leaflet/dist/leaflet.css';
import { useEffect, useRef, useState } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';

type MapPreviewProps = {
    coordinates?: { latitude: number; longitude: number };
    accuracy?: number;
    zone?: Zone;
    markerText?: string;
    className?: string;
    parentRef?: React.RefObject<HTMLDivElement>;
    height?: number;
};

const MapPreview: React.FC<MapPreviewProps> = ({ coordinates, zone, className, markerText, parentRef, height, accuracy}) => {
    const [mapReady, setMapReady] = useState(false);
    const mapRef = useRef<L.Map>(null);

    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconAnchor: [12, 41],
    });

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.invalidateSize();
        }
    }, [height]);

    useEffect(() => {
        if (mapRef.current && zone?.coordinates && zone.coordinates?.length > 0) {
            if (coordinates) {
                zone.coordinates.push(coordinates);
            }

            let southWest = { ...zone.coordinates[0] };
            let northEast = { ...zone.coordinates[0] };

            zone.coordinates.forEach(({ latitude, longitude }) => {
                if (latitude < southWest.latitude) {
                    southWest.latitude = latitude;
                }
                if (longitude < southWest.longitude) {
                    southWest.longitude = longitude;
                }
                if (latitude > northEast.latitude) {
                    northEast.latitude = latitude;
                }
                if (longitude > northEast.longitude) {
                    northEast.longitude = longitude;
                }
            });

            mapRef.current.fitBounds(
                new LatLngBounds(
                    { lat: southWest.latitude, lng: southWest.longitude },
                    { lat: northEast.latitude, lng: northEast.longitude }
                )
            );
        }
    }, [zone?.coordinates, mapReady, coordinates]);

    if (!zone || (!parentRef?.current && !height)) {
        return <Skeleton height="100%" width="100%" borderRadius={10} />;
    }

    L.Marker.prototype.options.icon = DefaultIcon;

    const zonePoints: LatLngExpression[] | undefined = zone?.coordinates.map((point) => [point.latitude, point.longitude]);

    return (
        <section className={className}>
            <Box style={{ height: `${parentRef?.current?.offsetHeight ?? height}px`, borderRadius: 6 }}>
                <MapContainer
                    ref={mapRef}
                    style={{ height: `100%`, borderRadius: 6 }}
                    center={[49.389198, 19.301421]}
                    zoom={7}
                    whenReady={() => {
                        setMapReady(true);
                    }}
                >
                    <TileLayer
                        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {coordinates && (
                        <Marker position={[coordinates.latitude, coordinates.longitude]}>
                            {markerText && <Popup> {markerText} </Popup>}
                        </Marker>
                    )}
                    {zone && zonePoints && (
                        <Polygon positions={zonePoints}>
                            <Popup>{zone.name}</Popup>
                        </Polygon>
                    )}
                    {
                        accuracy && coordinates && (
                            <Circle center={[coordinates.latitude, coordinates.longitude]} radius={accuracy} />
                        )
                    }
                </MapContainer>
            </Box>
        </section>
    );
};

export default MapPreview;
