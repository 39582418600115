import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User } from '../../types';


type AuthState = {
    user: User | null;
    token: string | null;
    setToken: (token: string | null) => void;
    setUser: (user: User | null) => void;
};


const useAuthStore = create<AuthState>()(
    persist(
        (set) => ({
            user: null,
            token: null,
            setUser: (user) => set((s) => ({ ...s, user })),
            setToken: (token) => set((s) => ({ ...s, token })),
        }),
        { name: 'AuthStoreStorage' }
    ),
);



const getToken = () => useAuthStore.getState().token;

export { useAuthStore, getToken };
