import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { ComponentProps } from 'react';
import ZonesSelectList, { ZonesSelectListProps } from './ZonesSelectList';
import { useIntl } from 'react-intl';

type ZoneSelectModalProps = Omit<ComponentProps<typeof Modal>, 'children'> & ZonesSelectListProps;

export const ZonesSelectModal: React.FC<ZoneSelectModalProps> = ({ onSelect, selectedZoneId: selectedUserId, isOpen, onClose }) => {
    const intl = useIntl();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxH={'90vh'}>
                <ModalHeader>{intl.formatMessage({ id: 'selectZoneWarning' })}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <ZonesSelectList onSelect={onSelect} selectedZoneId={selectedUserId} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>OK</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
