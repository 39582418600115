import { AxiosError } from "axios";
import { AppErrorCode } from "../../types/enums/AppErrorCode";

class ErrorHelper {

    mapRequestErrorToIntlKey(error: AxiosError): string {
        const appErrorCode = this.mapRequestErrorToAppError(error);

        return this.mapAppErrorToIntlKey(appErrorCode);
    }

    mapRequestErrorToAppError(error: AxiosError): AppErrorCode {
        if (!error) {
            return AppErrorCode.SOMETHING_WENT_WRONG;
        }
        
        if (this.isUserNameTakenError(error)) {
            return AppErrorCode.USER_NAME_TAKEN;
        }

        return AppErrorCode.SOMETHING_WENT_WRONG;
    }

    mapAppErrorToIntlKey(errorCode: AppErrorCode): string {
        switch (errorCode) {
            case AppErrorCode.USER_NAME_TAKEN:
                return 'userNameTaken'
            case AppErrorCode.SOMETHING_WENT_WRONG:
                return 'somethingWentWrong'
        }
    }

    isUserNameTakenError(error: AxiosError<any>) {
        const errorPart = error?.response?.data?.errors?.[0];

        return errorPart&& errorPart?.field === 'userName' && errorPart?.rule === 'unique';
    }

}

export default new ErrorHelper();