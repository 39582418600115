import React from 'react';
import { Client, DeviceData, RegisteredDevice, User } from '../../types';
import {
    Card,
    CardBody,
    CardHeader,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
    Box,
    Text,
    Icon,
    Button,
    Checkbox,
    useToast,
} from '@chakra-ui/react';
import { getDeviceIcon } from '../../screens/devices/DeviceLIst';
import { useIntl } from 'react-intl';
import { useGetRegisteredDeviceDetailsQuery, useLogoutRegisteredDeviceMutation, useUpdateRegisteredDeviceMutation } from '../../query/resource-hooks/devices';
import { AxiosError } from 'axios';
import { LoadingCard } from '../ui/LoadingCard';

type DeviceDetailModalProps = {
    isOpen: boolean;
    onClose: () => void;
    loggedUser?: User;
    device?: RegisteredDevice | null;
    userId?: number;
    onLogout: () => void;
};

export const DeviceDetailModal: React.FC<DeviceDetailModalProps> = ({ isOpen, onClose, loggedUser, device, userId, onLogout }) => {
    const intl = useIntl();
    const updateDeviceSettings = useUpdateRegisteredDeviceMutation(onQuerySuccess, onQueryError);
    const deviceDetails = useGetRegisteredDeviceDetailsQuery({ userId: userId!, deviceId: device?.id! });
    const logoutDevice = useLogoutRegisteredDeviceMutation(onLogoutSuccess, onQueryError);
    const toast = useToast();

    async function onUpdate(newSettings: DeviceData) {
        console.log('updating');
        if (device) {
            await updateDeviceSettings.mutate({ id: device.id, userId: userId!, data: newSettings });
            
        }
    }

    function onQuerySuccess(_device: RegisteredDevice) {
        toast({
            title: intl.formatMessage({ id: 'data.updated' }),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
        deviceDetails.refetch();
    }

    function onQueryError(error: AxiosError) {
        toast({
            title: intl.formatMessage({ id: 'somethingWentWrong' }),
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
    }

    function onLogoutSuccess() {
        toast({
            title: intl.formatMessage({ id: 'device.loggedOut' }),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
        onClose();
        onLogout();
    }



    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
            <ModalContent maxWidth={'600px'}>
                <ModalHeader>{intl.formatMessage({ id: 'device.details' })}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {!deviceDetails.data ? (
                        <LoadingCard />
                    ) : (
                        <DeviceDetailBody
                            device={deviceDetails.data}
                            onUpdate={onUpdate}
                            isCurrentDevice={!!loggedUser?.device && loggedUser?.device?.id === device?.id}
                            isLoadingUpdate={updateDeviceSettings.isLoading}
                            onLogout={() => logoutDevice.mutate({ deviceId: deviceDetails.data.id, userId: userId! })}
                        />
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const DeviceDetailBody: React.FC<{
    device: RegisteredDevice;
    isCurrentDevice: boolean;
    onUpdate?: (newSettings: DeviceData) => void;
    isLoadingUpdate: boolean;
    onLogout?: () => void; 
}> = ({ device, isCurrentDevice, onUpdate, isLoadingUpdate, onLogout }) => {
    const intl = useIntl();
    
    return (
        <Box>
            <Card>
                <CardBody>
                    <HStack>
                        <Icon as={getDeviceIcon(device)} w={8} h={8} />
                        <VStack align={'start'} spacing={0} pl={2}>
                            <Text fontWeight={'600'}>{device.name}</Text>
                            {isCurrentDevice && <Text>{intl.formatMessage({ id: 'device.currentlyUsed' })}</Text>}
                            <Text fontSize={'small'}>{device.client}</Text>
                            <Text fontSize={'small'}>{device.type}</Text>
                            <Text fontSize={'small'}>
                                {intl.formatMessage({ id: 'device.loggedAt' }, { date: intl.formatDate(device.createdAt) })}
                            </Text>
                        </VStack>
                    </HStack>
                </CardBody>
            </Card>
            {device.client !== Client.WEB ? (
                <>
                    <Card mt={4}>
                        <CardHeader>
                            <Heading size="sm">{intl.formatMessage({ id: 'device.settings' })}</Heading>
                        </CardHeader>
                        <CardBody>
                            {isLoadingUpdate ? (
                                <LoadingCard />
                            ) : (
                                <Checkbox
                                    isChecked={device.syncSettings}
                                    onChange={() =>
                                        onUpdate?.({
                                            syncSettings: !device.syncSettings,
                                            client: device.client,
                                            name: device.name,
                                            type: device.type,
                                        })
                                    }
                                >
                                    {intl.formatMessage({ id: 'syncSettings' })}
                                </Checkbox>
                            )}
                        </CardBody>
                    </Card>
                    <Card mt={4}>
                        <CardHeader>
                            <Heading size="sm">{intl.formatMessage({ id: 'device.info' })}</Heading>
                        </CardHeader>
                        <CardBody>
                            <Text>
                                {`${intl.formatMessage({ id: 'device.lastSyncAt' })}: ${device.lastSyncAt ? intl.formatDate(device.lastSyncAt) : '-'}`}
                            </Text>
                        </CardBody>
                    </Card>
                </>
            ) : null}

            <Button onClick={onLogout} isDisabled={isCurrentDevice} mt={4} colorScheme="red" variant="outline">
                {intl.formatMessage({ id: 'device.logout' })}
            </Button>
        </Box>
    );
};
