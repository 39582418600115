import React from 'react';
import DocsSidebarWithHeader from '../components/docs/DocsHeader';
import { Outlet, ScrollRestoration } from 'react-router-dom';

export const DocsLayout: React.FC = () => {
    return (
        <DocsSidebarWithHeader>
            <ScrollRestoration />
            <Outlet />
        </DocsSidebarWithHeader>
    );
};
