import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import {
    Box,
    Drawer,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Text,
    Icon,
    Center,
    Avatar,
    Button,
    HStack,
} from '@chakra-ui/react';
import { ComponentProps, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';
import { useIntl } from 'react-intl';

type FileDropZoneProps = Omit<ComponentProps<typeof Drawer>, 'children'> & {
    onDrop: (acceptedFiles: File[]) => void;
    title?: string;
};

export const FileDropZone: React.FC<FileDropZoneProps> = ({ isOpen, onClose, title, onDrop }) => {
    const intl = useIntl();
    const [files, setFiles] = useState<File[]>([]);
    const [previewUrl, setPreviewUrl] = useState('');

    const handleDrop = (acceptedFiles: File[], rejections: FileRejection[]) => {
        setFiles(acceptedFiles);
        if (acceptedFiles[0]) {
            const urlTest = URL.createObjectURL(acceptedFiles[0]);
            console.log('IRL', urlTest);
            setPreviewUrl(URL.createObjectURL(acceptedFiles[0]));
        }
    };

    const handleConfirm = () => {
        onDrop(files);
        setFiles([]);
        setPreviewUrl('');
        onClose();
    };

    const clearData = () => {
        setFiles([]);
        setPreviewUrl('');
    }
    return (
        <Drawer size={'md'} isOpen={isOpen} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>{title}</DrawerHeader>
                {files.length === 0 ? (
                    <Box p={5}>
                        <Dropzone
                            accept={{
                                'image/jpeg': [],
                                'image/png': [],
                                'image/webp': [],
                            }}
                            maxFiles={1}
                            onDrop={(files, r) => handleDrop(files, r)}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <Box
                                    {...getRootProps()}
                                    p={5}
                                    border="2px dashed"
                                    borderColor="gray.300"
                                    bg="gray.50"
                                    borderRadius="md"
                                    cursor="pointer"
                                    _hover={{ bg: 'gray.100' }}
                                >
                                    <input {...getInputProps()} />
                                    <Center h="100px">
                                        <Icon as={AddIcon} w={8} h={8} color="gray.400" />
                                    </Center>
                                    <Text textAlign="center" color="gray.400">
                                        Potiahnite súbor sem alebo kliknite pre nahratie
                                    </Text>
                                </Box>
                            )}
                        </Dropzone>
                    </Box>
                ) : (
                    <>
                        <Avatar size={'10'} src={previewUrl} />
                        <HStack pt={3} justifyContent={'center'}>
                            <Button leftIcon={<DeleteIcon />} onClick={clearData} colorScheme="red">{intl.formatMessage({ id: 'delete' })}</Button>
                            <Button leftIcon={<FiUpload />} onClick={handleConfirm} colorScheme="green" isDisabled={files?.length === 0}>
                                {intl.formatMessage({ id: 'save' })}
                            </Button>
                        </HStack>
                    </>
                )}
            </DrawerContent>
        </Drawer>
    );
};
