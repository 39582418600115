import { BillingAccount, DeviceData, User } from '../../types';
import { TokenInfo } from '../../types/accountManagement';
import apiClient, { addParametersToString } from '../apiClient';

class AuthService {
    async login(userName: string, password: string) {
        return (await apiClient.post<{ token: string }>('/api/login/token', { userName, password })).data;
    }

    async checkTokenValidity(deviceData?: DeviceData) {
        return (await apiClient.get<User>(`/api/checkLogin${addParametersToString(deviceData ?? {})}`)).data;
    }

    async logout() {
        return (await apiClient.post<void>('/api/logout')).data;
    }

    async registerUser(data: { userName: string; password: string; firstName: string; surname: string; inviteCode?: string }) {
        return (await apiClient.post<User>('/api/register', data)).data;
    }

    async sendPasswordResetEmail(email: string): Promise<{ status: string }> {
        return (await apiClient.post<{ status: string }>(`/api/account-management/reset-password-email/${email}`)).data;
    }

    async resendEmailVerificationEmail(): Promise<{status: string}> {
        return (await apiClient.post('api/account-management/resend-email-verification')).data
    }

    async getTokenInfo(token: string) {
        return (await apiClient.get<TokenInfo>(`/api/account-management/info/${token}`)).data;
    }

    async verifyEmail(token: string) {
        return (await apiClient.get<TokenInfo>(`/api/account-management/email-verification/${token}`)).data;
    }

    async updatePassword(token: string, password: string) {
        return (
            await apiClient.put(`/api/account-management/reset-password/${token}`, { password, password_confirmation: password } as any)
        ).data;
    }

    async createBillingPortalSession() {
        return (await apiClient.get<{ url: string | null }>('/api/billing/portal')).data;
    }

    async getBillingInfoByToken(token: string) {
        return (
            await apiClient.get<BillingAccount>(`/api/billing/info/${token}`)
        ).data;
    }
}

export default new AuthService();
