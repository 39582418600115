import { Box, Flex, Skeleton, SkeletonText } from '@chakra-ui/react';

export const AttendancePreviewSkeleton = () => {
    return (
        <Box>
            <Box h={{ base: '200px', md: '250px', xl: '300px' }} position="relative">
                <Skeleton h={{ base: 200, md: 250, xl: 300 }} borderRadius={10} />
                <Skeleton
                    backgroundColor={'gray.200'}
                    borderWidth={10}
                    border={'solid'}
                    borderRadius={'lg'}
                    borderColor={'gray.500'}
                    zIndex={1000}
                    h={{ base: 150, md: 250, xl: 300 }}
                    w={{ base: 150, md: 250, xl: 300 }}
                    aspectRatio={1}
                    objectFit={'cover'}
                    transform={'translate(-50%, 0%)'}
                    position={'absolute'}
                    top={'70%'}
                    left={{ 'base': '50%', 'sm': '50%', 'md': '23%', 'lg': '18%', 'xl': '15%', '2xl': '14%' }}
                />
            </Box>
            <Flex ml={{ 'base': 0, 'md': '45%', 'lg': '35%', 'xl': '30%', '2xl': '28%' }} mt={{ base: '115px', md: 0 }}>
                <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
            </Flex>
        </Box>
    );
};
