import { Box, useColorModeValue } from '@chakra-ui/react';
import { LoginForm } from '../../components/auth/LoginForm';

const LoginScreen: React.FC = () => {
    return (
        <Box minH="100vh" px={5} bg={useColorModeValue('gray.100', 'gray.900')}>
            <LoginForm></LoginForm>
        </Box>
    );
};

export default LoginScreen;
