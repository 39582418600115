import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Stack,
} from '@chakra-ui/react';
import { ComponentProps } from 'react';
import { useSettingsStore } from '../../store/ui/SettingsStore';
import { useIntl } from 'react-intl';
import { languages } from '../../i18n/languages';

type LanguageSelectModalProps = Omit<ComponentProps<typeof Modal>, 'children'>;

const LanguageSelectModal: React.FC<LanguageSelectModalProps> = ({ onClose, isOpen }) => {
    const intl = useIntl();
    const setLanguage = useSettingsStore((state) => state.setLanguage);
    const currentLanguage = useSettingsStore((state) => state.language);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{intl.formatMessage({ id: 'language' })}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <RadioGroup onChange={setLanguage} value={currentLanguage || undefined}>
                            <Stack>
                                {languages.map((lang) => {
                                    return (
                                        <Radio key={lang.code} value={lang.code}>
                                            {lang.name}
                                        </Radio>
                                    );
                                })}
                            </Stack>
                        </RadioGroup>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default LanguageSelectModal;
