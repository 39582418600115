import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react';
import { ComponentProps, useRef } from 'react';
import { useIntl } from 'react-intl';

type DeleteAlertDialogProps = Omit<Omit<ComponentProps<typeof AlertDialog>, 'leastDestructiveRef'>, 'children'> & {
    onConfirm: () => void;
    isLoading?: boolean;
};

export const DeleteAlertDialog: React.FC<DeleteAlertDialogProps> = ({ onConfirm, onClose, isOpen, isLoading,...restProps }) => {
    const intl = useIntl();
    const cancelRef = useRef<HTMLButtonElement | null>(null);

    return (
        <>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} {...restProps}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {intl.formatMessage({ id: 'deleteDataTitle' })}
                        </AlertDialogHeader>

                        <AlertDialogBody>{intl.formatMessage({ id: 'deleteDataText' })}</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button isDisabled={isLoading} ref={cancelRef} onClick={onClose}>
                                {intl.formatMessage({ id: 'cancel' })}
                            </Button>
                            <Button isDisabled={isLoading} isLoading={isLoading} colorScheme="red" onClick={onConfirm} ml={3}>
                                {intl.formatMessage({ id: 'delete' })}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};
