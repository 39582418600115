import { Button, ButtonGroup } from '@chakra-ui/react';
import { LoginDirection } from '../../types';
import { useIntl } from 'react-intl';
import { FiLogIn, FiLogOut } from 'react-icons/fi';

type LoginDirectionButtonProps = {
    loginDirection: LoginDirection;
    onChange: (loginDirection: LoginDirection) => void;
    showAll?: boolean;
};

export const LoginDirectionButton: React.FC<LoginDirectionButtonProps> = ({ loginDirection, onChange, showAll }) => {
    const intl = useIntl();

    return (
        <ButtonGroup isAttached>
            <Button
                colorScheme={loginDirection === LoginDirection.IN ? 'green' : 'primary'}
                leftIcon={<FiLogIn />}
                variant={loginDirection === LoginDirection.IN ? 'solid' : 'outline'}
                onClick={() => onChange(LoginDirection.IN)}
            >
                {intl.formatMessage({ id: LoginDirection.IN })}
            </Button>
            {showAll && (
                <Button
                   
                    variant={loginDirection === LoginDirection.NOT_SET ? 'solid' : 'outline'}
                    onClick={() => onChange(LoginDirection.NOT_SET)}
                >
                    {intl.formatMessage({ id: 'attendance.direction.all' })}
                </Button>
            )}
            <Button
                colorScheme={loginDirection === LoginDirection.OUT ? 'green' : 'primary'}
                rightIcon={<FiLogOut />}
                variant={loginDirection === LoginDirection.OUT ? 'solid' : 'outline'}
                onClick={() => onChange(LoginDirection.OUT)}
            >
                {intl.formatMessage({ id: LoginDirection.OUT })}
            </Button>
        </ButtonGroup>
    );
};
