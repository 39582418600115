import { Box, Heading, Text } from '@chakra-ui/react';

type PolicyQuestionResponseProps = {
    question: string;
    response: string;
};

export const PolicyQuestionResponse: React.FC<PolicyQuestionResponseProps> = ({ question, response }) => {
    return (
        <Box>
            <Heading textDecoration={'underline'} size={'xs'}>{question}</Heading>
            <Text>
                {response}
            </Text>
        </Box>
    );
};
