import React, { useEffect, useRef, useState } from 'react';
import L, { LatLngBounds } from 'leaflet';
import { TileLayer, FeatureGroup, MapContainer, Polygon } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import osm from './osm-providers';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { Box } from '@chakra-ui/react';
import drawLocales, { Language, languages } from 'leaflet-draw-locales';
import { Marker } from '../../../types';
import { useSettingsStore } from '../../../store/ui/SettingsStore';
import { isLanguageAvailable } from '../../../i18n/languages';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
});

type MapEditorProps = {
    onDeleted?: () => void;
    onPolygonChange?: (markers: Marker[]) => void;
    existingPolygon?: Marker[];
};

type GeoJsonData = {
    geometry: {
        coordinates?: Array<Array<[number, number]>>;
        type: string;
    };
};

const MapEditor: React.FC<MapEditorProps> = ({ onDeleted, onPolygonChange, existingPolygon }) => {
    const ZOOM_LEVEL = 7;

    const [drawEnabled, setDrawEnabled] = useState(existingPolygon?.length ? false : true);
    const [featureKey, setFeatureKey] = useState(0);
    const [mapReady, setMapReady] = useState(false);
    const [editedPolygonKey, setEditedPolygonKey] = useState(1000);
    const mapRef = useRef<L.Map>(null);

    const customLocale = useSettingsStore((state) => state.language);
    const getUsedLocale = () => {
        if (customLocale) {
            return customLocale;
        }

        const browserLanguage = navigator.language?.slice(0, 2)?.toLowerCase();

        if (isLanguageAvailable(browserLanguage) && languages.includes(browserLanguage as Language)) {
            return browserLanguage;
        }

        return 'sk';
    };

    L.setOptions({ drawLocales: drawLocales(getUsedLocale() as Language) }, {});
    function remapGeoJson(geoJson: GeoJsonData): Marker[] {
        const coordinatesArray = geoJson.geometry?.coordinates;
        if (!coordinatesArray || coordinatesArray.length === 0 || !coordinatesArray[0]) {
            return [];
        }

        const result = coordinatesArray?.[0]?.map((coordinate) => ({
            latitude: coordinate[1],
            longitude: coordinate[0],
        }));

        return result;
    }

    const mapHasPolygon = () => {
        let hasPolygon = false;
        mapRef.current?.eachLayer((layer) => {
            if (layer instanceof L.Polygon) {
                hasPolygon = true;
            }
        });
        return hasPolygon;
    };

    function handleCreated(e: L.DrawEvents.Created) {
        setDrawEnabled(false);
        setFeatureKey(featureKey + 1);

        onPolygonChange?.(remapGeoJson(e.layer?.toGeoJSON()));
    }

    function handleUpdated(e: L.DrawEvents.Edited) {
        e.layers.eachLayer((layer) => {
            if (layer instanceof L.Polygon) {
                onPolygonChange?.(remapGeoJson(layer.toGeoJSON() as any as GeoJsonData));
                setEditedPolygonKey(editedPolygonKey + 1);
                console.log('polygon edited', remapGeoJson(layer.toGeoJSON() as any as GeoJsonData));
            }
        });
    }

    function handleDeleted(e: L.DrawEvents.Deleted) {
        if (mapHasPolygon()) {
            setDrawEnabled(false);
            console.log('Zone still exists...');
        } else {
            setDrawEnabled(true);
            setFeatureKey(featureKey + 1);
            console.log('Zone deleted...');
        }
        onDeleted?.();
    }

    useEffect(() => {
        if (mapRef.current && existingPolygon && existingPolygon?.length > 0) {
            let southWest = { ...existingPolygon[0] };
            let northEast = { ...existingPolygon[0] };

            existingPolygon.forEach(({ latitude, longitude }) => {
                if (latitude < southWest.latitude) {
                    southWest.latitude = latitude;
                }
                if (longitude < southWest.longitude) {
                    southWest.longitude = longitude;
                }
                if (latitude > northEast.latitude) {
                    northEast.latitude = latitude;
                }
                if (longitude > northEast.longitude) {
                    northEast.longitude = longitude;
                }
            });

            mapRef.current.fitBounds(
                new LatLngBounds(
                    { lat: southWest.latitude, lng: southWest.longitude },
                    { lat: northEast.latitude, lng: northEast.longitude }
                )
            );
        }
    }, [existingPolygon, mapReady]);

    return (
        <>
            <Box>
                <MapContainer
                    ref={mapRef}
                    center={[49.389198, 19.301421]}
                    zoom={ZOOM_LEVEL}
                    style={{ height: '60vh', borderRadius: 10 }}
                    whenReady={() => {
                        setMapReady(true);
                    }}
                >
                    <FeatureGroup interactive>
                        <EditControl
                            key={featureKey}
                            position="topright"
                            onCreated={handleCreated}
                            onDeleted={handleDeleted}
                            onEdited={handleUpdated}
                            draw={{
                                rectangle: false,
                                circle: false,
                                circlemarker: false,
                                marker: false,
                                polyline: false,
                                polygon: drawEnabled
                                    ? {
                                          allowIntersection: false,
                                      }
                                    : false,
                            }}
                        />
                        {existingPolygon?.length && (
                            <Polygon
                                key={editedPolygonKey}
                                positions={existingPolygon.map((coordinate) => [coordinate.latitude, coordinate.longitude])}
                            />
                        )}
                    </FeatureGroup>
                    <TileLayer url={osm.maptiler.url} />
                </MapContainer>
            </Box>
        </>
    );
};

export default MapEditor;
