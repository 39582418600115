import { Card, CardBody, CardHeader, Heading, HStack, IconButton, Image, Tooltip, useDisclosure } from '@chakra-ui/react';
import { Company } from '../../types';
import { createImageUrl } from '../../query/apiClient';
import { LoadingCard } from '../ui/LoadingCard';
import { useIntl } from 'react-intl';
import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import { FaPhotoVideo } from 'react-icons/fa';
import { FileDropZone } from '../ui/FileDropZone';
import { useUpdateCompany } from '../../query/resource-hooks/company';

type CompanyCardProps = ComponentProps<typeof Card> & {
    company: Company;
    onLogoUpdate?: () => void;
};

const CompanyCard: React.FC<CompanyCardProps> = ({ company, onLogoUpdate,...cardProps }) => {
    const intl = useIntl();
    const updateCompany = useUpdateCompany(() => onLogoUpdate?.());
    const editLogoDisclosure = useDisclosure();



    const handleLogoUpload = (files: File[]) => {
        updateCompany.mutate({ logo: files[0] });
    };

    if (!company) {
        return <LoadingCard></LoadingCard>;
    }

    return (
        <Card maxW={'lg'} boxShadow={'2xl'} height={'100%'} {...cardProps} borderRadius={'lg'}>
            <CardHeader>
                <HStack justifyContent={'space-between'}>
                    <Heading size={'md'}>{intl.formatMessage({ id: 'company' })}</Heading>
                    <Tooltip label={intl.formatMessage({ id: 'company.uploadLogo' })}>
                        <IconButton
                            onClick={editLogoDisclosure.onOpen}
                            colorScheme="primary"
                            variant={'text'}
                            aria-label={'edit'}
                            icon={<FaPhotoVideo />}
                        />
                    </Tooltip>
                </HStack>
            </CardHeader>
            {company?.logoUrl && (
                <Image
                    paddingY={2}
                    src={createImageUrl(company?.logoUrl)}
                    alt={company?.name}
                    fit={'contain'}
                    align={'center'}
                    maxH={'200px'}
                />
            )}
            <CardBody textAlign={'center'}>
                <Heading as={Link} _hover={{ textDecoration: 'underline' }} to={'/company'} textAlign={'center'} size="md">
                    {company?.name || ''}
                </Heading>
            </CardBody>
            <FileDropZone title={intl.formatMessage({ id: 'company.uploadLogo' })} isOpen={editLogoDisclosure.isOpen} onClose={editLogoDisclosure.onClose} onDrop={handleLogoUpload} />
        </Card>
    );
};

export default CompanyCard;
