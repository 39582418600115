import { AxiosError } from 'axios';
import SchemaProvider from '../../utils/SchemaProvider';
import { useIntl } from 'react-intl';
import FormikTextInput from '../formik/FormikTextInput';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useToast, VStack } from '@chakra-ui/react';
import { InferType } from 'yup';
import { useUpdateCompany } from '../../query/resource-hooks/company';
import { ComponentProps, useRef } from 'react';
import ErrorHelper from '../../query/utils/ErrorHelper';
import FormikStepper from '../formik/FormikStepper';
import FormikStep from '../formik/FormikStep';
import { BusinessIdentity, Company, countriesByAlpha3 } from '../../types';
import { FormikSelect } from '../formik/FormikSelect';

export type ContactFormProps = Omit<ComponentProps<typeof Drawer>, 'children'> & {
    onSuccess?: (employee: BusinessIdentity | BusinessIdentity) => void;
    onError?: (error: AxiosError) => void;
    updatedContact?: BusinessIdentity;
    isCompanyUpdate?: boolean;
};

export const ContactForm: React.FC<ContactFormProps> = ({ updatedContact, onSuccess, onError, isOpen, onClose, isCompanyUpdate }) => {
    const intl = useIntl();
    const updateCompany = useUpdateCompany(handleSuccess, handleError);
    const toast = useToast();

    const firstField = useRef<HTMLInputElement | null>(null);

    const validationSchema = SchemaProvider.getBusinessIdentitySchema(intl);
    function handleSubmit(
        values: InferType<typeof validationSchema.address> &
            InferType<typeof validationSchema.businessIdentification> &
            InferType<typeof validationSchema.contact>
    ) {
        if (isCompanyUpdate) {
            updateCompany.mutate({ ...updatedContact, ...values, logo: undefined });
        }
        // Add other scenarios for contacts
    }

    function handleSuccess(data: Company) {
        toast({
            title: intl.formatMessage({ id: updatedContact ? 'data.updated' : 'data.created' }),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
        onSuccess?.(data);
        handleClose();
    }

    function handleError(error: AxiosError) {
        const errorMessage = ErrorHelper.mapRequestErrorToIntlKey(error);

        toast({
            title: intl.formatMessage({ id: errorMessage }),
            status: 'error',
            duration: 8000,
            isClosable: true,
            position: 'top',
        });
        onError?.(error);
    }

    function handleClose() {
        updateCompany.reset();
        onClose();
    }

    return (
        <Drawer initialFocusRef={firstField} size={'md'} isOpen={isOpen} onClose={handleClose} placement="right">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                    {intl.formatMessage({ id: updatedContact ? 'contacts.update' : 'create' })}
                </DrawerHeader>
                <DrawerBody>
                    <FormikStepper showStepCounter onSubmit={handleSubmit} initialValues={updatedContact || { name: '' }}>
                        <FormikStep validationSchema={validationSchema.contact}>
                            {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
                                <VStack>
                                    <FormikTextInput
                                        inputRef={firstField}
                                        name="name"
                                        fieldName="name"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'contacts.name' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                    />
                                    <FormikTextInput
                                        name="email"
                                        fieldName="email"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'contacts.email' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                    />
                                    <FormikTextInput
                                        name="phone"
                                        fieldName="phone"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'contacts.phone' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                        inputMode="tel"
                                    />
                                </VStack>
                            )}
                        </FormikStep>
                        <FormikStep validationSchema={validationSchema.businessIdentification}>
                            {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
                                <VStack>
                                    <FormikTextInput
                                        name="organizationIdentifier"
                                        fieldName="organizationIdentifier"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'field.organizationIdentifier' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                    />
                                    <FormikTextInput
                                        name="taxId"
                                        fieldName="taxId"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'field.taxId' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                    />
                                    <FormikTextInput
                                        name="vatId"
                                        fieldName="vatId"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'field.vatId' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                    />
                                </VStack>
                            )}
                        </FormikStep>
                        <FormikStep validationSchema={validationSchema.address}>
                            {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
                                <VStack>
                                    <FormikSelect
                                        name="countryCode"
                                        fieldName="countryCode"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'field.countryCode' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                        options={[{label: '', value: ''}].concat(Object.values(countriesByAlpha3).map((country) => ({label: country.alpha3, value: country.alpha3})))}
                                        variant={'filled'}
                                    />
                                    <FormikTextInput
                                        name="street"
                                        fieldName="street"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'field.street' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                    />
                                    <FormikTextInput
                                        name="city"
                                        fieldName="city"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'field.city' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                    />
                                    <FormikTextInput
                                        name="postalCode"
                                        fieldName="postalCode"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        label={intl.formatMessage({ id: 'field.postalCode' })}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        values={values}
                                        touched={touched}
                                        inputMode="numeric"
                                    />
                                </VStack>
                            )}
                        </FormikStep>
                    </FormikStepper>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
