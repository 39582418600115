import { Box, Card, Flex, Heading, useColorModeValue, Image, Text, useTheme, Divider, Center, Button, useToast } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { Company } from '../../types';
import { LinkIcon } from '@chakra-ui/icons';

const SetupBillingScreen: React.FC<{company: Company}> = ({company}) => {
    const intl = useIntl();
    const theme = useTheme();
    const toast = useToast();

    const headingColor = useColorModeValue('white', 'gray.100');
    const textColor = useColorModeValue('gray.300', 'gray.300');

    const redirectToPayGate = () => {
        if (!company.billingAccount?.redirectUrl) {
            return toast({
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                description:  intl.formatMessage({ id: 'billing.setup.error.contactUs' }),
                status: 'error',
                duration: 10000,
                isClosable: true
            })
        }

        return window.location.href = company.billingAccount?.redirectUrl;
    }

    return (
        <Box minH="100vh" justifyContent={'center'} display={'flex'} bg={useColorModeValue('gray.100', 'gray.900')}>
            <Box w={'100%'} maxW={'1000'} p={{ base: 4, md: 8 }}>
                <Card w={'100%'} borderRadius={'lg'}>
                    <Flex minH={600} direction={{ base: 'column', md: 'row' }}>
                        <Box maxW={{ md: '380px' }} bg={theme.colors.primary[500]} p="5" borderRadius={'lg'}>
                            <Heading color={headingColor} size="md">
                                {intl.formatMessage({ id: 'billing.subscription' })}
                            </Heading>
                            <Text color={textColor} mt="4">
                                {intl.formatMessage({ id: 'billing.settings' })}
                            </Text>
                            <Image
                                display={{ base: 'none', md: 'block' }}
                                justifySelf={'center'}
                                src={`${process.env.PUBLIC_URL}/logo512.png`}
                                alt="Supervizer logo"
                            />
                        </Box>
                        <Box p="5" display={'flex'} flex={1} flexDir={'column'}> 
                            <Heading size="lg">
                                {intl.formatMessage({ id: 'billing.setup.introduction' })}
                            </Heading>
                            <Divider my={3}/>
                            <Text>
                            {intl.formatMessage({ id: 'billing.setup.text' })}
                            </Text>
                            <Center mt={4}>
                                <Button leftIcon={<LinkIcon />} onClick={() => redirectToPayGate()} >
                                    {intl.formatMessage({ id: 'billing.setup.button' })}
                                </Button>
                            </Center>
                        </Box>
                    </Flex>
                </Card>
            </Box>
        </Box>
    );
};


export default SetupBillingScreen;