import { Box, Center } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import AttendanceHistoryList from '../../../components/attendance/history/AttendanceHistoryList';
import { LoadingCard } from '../../../components/ui/LoadingCard';
import SecondPageHeader from '../../../components/ui/SecondPageHeader';
import { useIntl } from 'react-intl';

const AttendanceLoginHistoryScreen: React.FC = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const routeParams = useParams<{ attendanceId: string }>();

    if (!routeParams.attendanceId) {
        return (
            <Center>
                <LoadingCard />;
            </Center>
        );
    }

    return (
        <>
            <SecondPageHeader
                title={intl.formatMessage({ id: 'title.history' })}
                onBack={() => {
                    navigate(-1);
                }}
            />
            <Box px={{base: 2, lg: 0}} maxW={'1140px'} mx={'auto'}>
                <AttendanceHistoryList loginId={+routeParams.attendanceId!} />
            </Box>
        </>
    );
};


export default AttendanceLoginHistoryScreen;