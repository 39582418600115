import { Box, Center } from '@chakra-ui/react';
import React from 'react';
import { LoadingCard } from '../../components/ui/LoadingCard';
import { useGetTokenInfo } from '../../query/resource-hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useIntl } from 'react-intl';
import { TokenInfo } from '../../types/accountManagement';
import { TokenType } from '../../types';
import PasswordResetForm from '../../components/account-management/PasswordResetForm';
import { EmailVerification } from '../../components/account-management/EmailVerification';
import { CheckoutReturn } from '../../components/account-management/CheckoutReturn';

const TokenPage: React.FC = () => {
    const params = useParams<{ token: string }>();
    const navigate = useNavigate();
    const intl = useIntl();

    const tokenInfo = useGetTokenInfo(params.token!,onSuccess, onInfoError);

    function onInfoError(error: AxiosError<any>) {
        navigate('/result', {
            state: {
                title: intl.formatMessage({id: 'somethingWentWrong'}),
                text: intl.formatMessage({id: 'somethingWentWrong.tryLater'}),
                type: 'error',
            },
        });
    }

    function onSuccess(data: TokenInfo) {
        console.log('success', data)
    } 

    if (tokenInfo.isLoading) {
        (
            <Box textAlign="center" py={10} px={6}>
                <Center>
                    <LoadingCard />
                </Center>
            </Box>
        );
    }

    switch(tokenInfo.data?.type) {
        case TokenType.EMAIL_VERIFICATION:
            return <EmailVerification token={params.token!} />;
        case TokenType.PASSWORD_RESET:
            return <PasswordResetForm token={params.token!} />;
        case TokenType.CHECKOUT_RETURN:
            return <CheckoutReturn token={params.token!} />
        default:
            return <div>UPS this should never happend.</div>;  
    }
};

export default TokenPage;
