import {
    Box,
    Button,
    Card,
    Center,
    Container,
    Heading,
    HStack,
    IconButton,
    Image,
    Link,
    Stack,
    Text,
    useDisclosure,
    useTheme,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLogin } from '../../query/resource-hooks/auth';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useAuthStore } from '../../store/auth/authStore';
import { PasswordResetModal } from '../account-management/PasswordResetModal';
import { Form, Formik } from 'formik';
import SchemaProvider from '../../utils/SchemaProvider';
import { InferType } from 'yup';
import FormikTextInput from '../formik/FormikTextInput';
import FormikPasswordInput from '../formik/FormikPasswordInput';
import { AxiosError } from 'axios';
import NotificationList from '../ui/NotificationsList';
import { useNotificationStore } from '../../store/ui/NotificationStore';
import { ChevronLeftIcon } from '@chakra-ui/icons';

export const LoginForm = () => {
    const theme = useTheme();
    const intl = useIntl();
    const navigation = useNavigate();
    const loggedUser = useAuthStore((state) => state.user);
    const loginMutation = useLogin(() => navigation('/home'), handleLoginError);
    const pushNotification = useNotificationStore((state) => state.pushNotification);
    const clearNotifications = useNotificationStore((state) => state.clearNotifications);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const loginSchema = SchemaProvider.getLoginSchema(intl);

    useEffect(() => {
        if (loggedUser) {
            navigation('/home');
        }
    }, [loggedUser, navigation]);

    useEffect(() => {
        return () => {
            clearNotifications(); //Clear norifications
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSubmit(data: InferType<typeof loginSchema>) {
        loginMutation.mutate({ username: data.userName, password: data.password });
    }

    function handleLoginError(error: AxiosError<{ errors?: { message: string }[] }>) {
        if (error?.response?.status === 400) {
            pushNotification({ type: 'error', title: intl.formatMessage({ id: 'login.invalidCredentials' }) });
        } else {
            pushNotification({
                type: 'error',
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                description: intl.formatMessage({ id: 'somethingWentWrong.tryLater' }),
            });
        }
    }

    return (
        <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
            <NotificationList />
            <Card boxShadow={'lg'} borderRadius={'lg'}>
                <Stack>
                    <Box position={'relative'}>
                        <IconButton
                            position={'absolute'}
                            as={RouterLink}
                            to={'/about'}
                            left={0}
                            variant={'text'}
                            icon={<ChevronLeftIcon boxSize={10} color={theme.colors.primary[500]} />}
                            aria-label="back to about"
                        />
                        <Center>
                            <Image src={`${process.env.PUBLIC_URL}/logo512.png`} height={150} />
                        </Center>
                    </Box>
                    <Stack textAlign="center">
                        <Heading size={'lg'}> {intl.formatMessage({ id: 'loginWelcome' })}</Heading>
                        <Text>
                            {intl.formatMessage({ id: 'doNotHaveAccount' })}{' '}
                            <Link as={RouterLink} to={'/register'} fontWeight={600}>
                                {intl.formatMessage({ id: 'register' })}
                            </Link>
                        </Text>
                    </Stack>
                </Stack>
                <Box
                    py={{ base: '2', sm: '8' }}
                    px={{ base: '4', sm: '10' }}
                    bg={{ base: 'transparent', sm: 'bg.surface' }}
                    boxShadow={{ base: 'none', sm: 'md' }}
                    borderRadius={{ base: 'none', sm: 'xl' }}
                >
                    <Formik onSubmit={handleSubmit} validationSchema={loginSchema} initialValues={{ password: '', userName: '' }}>
                        {({ errors, touched, handleChange, handleBlur, values }) => (
                            <Form>
                                <Stack spacing="6">
                                    <Stack spacing="5">
                                        <FormikTextInput
                                            errors={errors}
                                            touched={touched}
                                            name="userName"
                                            fieldName="userName"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            label={intl.formatMessage({ id: 'usernameEmail' })}
                                            values={values}
                                            isDisabled={loginMutation.isLoading}
                                        />
                                        <FormikPasswordInput
                                            errors={errors}
                                            touched={touched}
                                            name="password"
                                            fieldName="password"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            label={intl.formatMessage({ id: 'password' })}
                                            values={values}
                                            isDisabled={loginMutation.isLoading}
                                        />
                                    </Stack>
                                    <HStack justify="end">
                                        <Button variant="text" onClick={onOpen} size="sm">
                                            {intl.formatMessage({ id: 'forgotPassword' })}
                                        </Button>
                                    </HStack>
                                    <Stack spacing="6">
                                        <Button isLoading={loginMutation.isLoading} type="submit" colorScheme='primary'>
                                            {intl.formatMessage({ id: 'logIn' })}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
            <PasswordResetModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        </Container>
    );
};
