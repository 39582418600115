import { Flex, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import React from 'react';

const EmployeesListSkeleton: React.FC = () => {
    return (
        <>
            {Array.from({ length: 10 }).map((_, index) => (
                <Flex pl={2} alignItems={'center'} height={'56px'} mb={1} key={index}>
                    <SkeletonCircle size="48px"/>
                    <SkeletonText ml={3} noOfLines={2} maxWidth={'240px'} width={'100%'}/>
                </Flex>
            ))}
        </>
    );
};

export default EmployeesListSkeleton;
