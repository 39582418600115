import { CheckIcon, SettingsIcon } from '@chakra-ui/icons';
import {
    Box,
    List,
    ListIcon,
    ListItem,
    SimpleGrid,
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    ButtonGroup,
    IconButton,
    Image,
    ScaleFade,
    Icon,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaDesktop, FaMobileAlt } from 'react-icons/fa';
import { useIntl } from 'react-intl';

export const FeatureAttendanceList: React.FC = () => {
    const intl = useIntl();

    const [deviceMode, setDeviceMode] = useState<'desktop' | 'mobile'>('desktop');

    const isMobile = () => {
        return deviceMode === 'mobile';
    };
    return (
        <Box>
            <SimpleGrid columns={{ sm: 1, md: 2 }}>
                <Box mx={'auto'} mt={{ base: 0, md: 125 }}>
                    <List textAlign={'left'} pl={10} spacing={3}>
                        <ListItem fontSize={'large'} fontWeight={'600'} color={'gray.200'}>
                            <ListIcon as={CheckIcon} color="green.500" />
                            {intl.formatMessage({ id: 'about.features.easyAccessToAttendance' })}
                        </ListItem>
                        <ListItem fontSize={'large'} fontWeight={'600'} color={'gray.200'}>
                            <ListIcon as={CheckIcon} color="green.500" />
                            {intl.formatMessage({ id: 'about.features.allOnOnePlace' })}
                        </ListItem>
                        <ListItem fontSize={'large'} fontWeight={'600'} color={'gray.200'}>
                            <ListIcon as={SettingsIcon} color="green.500" />
                            {intl.formatMessage({ id: 'about.features.manualAttendance' })}
                        </ListItem>
                        <ListItem fontSize={'large'} fontWeight={'600'} color={'gray.200'}>
                            <ListIcon as={SettingsIcon} color="green.500" />
                            {intl.formatMessage({ id: 'about.features.management' })}
                        </ListItem>
                    </List>
                </Box>

                <Box mt={{ base: 3, md: 0 }}>
                    <ButtonGroup isAttached>
                        <IconButton
                            px={10}
                            onClick={() => setDeviceMode('desktop')}
                            isActive={deviceMode === 'desktop'}
                            size={'lg'}
                            variant={'outline'}
                            aria-label="PC-screens"
                            icon={<Icon as={FaDesktop} boxSize={7} />}
                            borderRadius={'full'}
                        ></IconButton>
                        <IconButton
                            px={10}
                            onClick={() => setDeviceMode('mobile')}
                            isActive={deviceMode === 'mobile'}
                            size={'lg'}
                            variant={'outline'}
                            aria-label="phone-screens"
                            icon={<Icon as={FaMobileAlt} boxSize={7} />}
                            borderRadius={'full'}
                        ></IconButton>
                    </ButtonGroup>
                    <Tabs mt={4} align="center" variant="soft-rounded">
                        <TabList>
                            <Tab color={'gray.200'} fontSize={'lg'} _selected={{ color: 'primary.700', background: 'secondary.200' }}>
                                {intl.formatMessage({ id: 'about.list' })}
                            </Tab>
                            <Tab color={'gray.200'} fontSize={'lg'} _selected={{ color: 'primary.700', background: 'secondary.200' }}>
                                {intl.formatMessage({ id: 'about.detail' })}
                            </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Box minH={{ base: 0, md: '80vh' }} display={'flex'} alignItems={'start'} justifyContent={'center'}>
                                    <ScaleFade initialScale={0.9} in key={deviceMode}>
                                        <Image
                                            borderRadius={'lg'}
                                            maxH={'80vh'}
                                            src={
                                                isMobile()
                                                    ? `${process.env.PUBLIC_URL}/images/mockups/attendance_list-portrait.png`
                                                    : `${process.env.PUBLIC_URL}/images/mockups/attendance_web.png`
                                            }
                                        />
                                    </ScaleFade>
                                </Box>
                            </TabPanel>
                            <TabPanel>
                                <Box minH={{ base: 0, md: '80vh' }} display={'flex'} alignItems={'start'} justifyContent={'center'}>
                                    <ScaleFade initialScale={0.9} in key={deviceMode}>
                                        <Image
                                            borderRadius={'lg'}
                                            maxH={'80vh'}
                                            src={
                                                isMobile()
                                                    ? `${process.env.PUBLIC_URL}/images/mockups/attendance_detail-portrait.png`
                                                    : `${process.env.PUBLIC_URL}/images/mockups/attendance_detail_web.png`
                                            }
                                        />
                                    </ScaleFade>
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </SimpleGrid>
        </Box>
    );
};
