import { CheckIcon, SettingsIcon } from '@chakra-ui/icons';
import {
    Box,
    List,
    ListIcon,
    ListItem,
    SimpleGrid,
    Image,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';

export const FeatureAttendanceReport: React.FC = () => {
    const intl = useIntl();

    return (
        <Box>
            <SimpleGrid columns={{ sm: 1, md: 2 }}>
                <Box m={'auto'} px={{base: 0, md: 2}}>
                    <List textAlign={'left'} pl={10} spacing={3}>
                        <ListItem fontSize={'large'} fontWeight={'600'} color={'gray.200'}>
                           {intl.formatMessage({id: 'about.feature.attendanceReport.base'})}
                        </ListItem>
                        <ListItem fontSize={'large'} fontWeight={'600'} color={'gray.200'}>
                            <ListIcon as={CheckIcon} color="green.500" />
                            {intl.formatMessage({id: 'about.feature.attendanceReport.overview'})}
                        </ListItem>
                        <ListItem fontSize={'large'} fontWeight={'600'} color={'gray.200'}>
                            <ListIcon as={CheckIcon} color="green.500" />
                            {intl.formatMessage({id: 'about.feature.attendanceReport.totalTime'})}
                        </ListItem>
                        <ListItem fontSize={'large'} fontWeight={'600'} color={'gray.200'}>
                            <ListIcon as={SettingsIcon} color="green.500" />
                            {intl.formatMessage({id: 'about.feature.attendanceReport.easyEdit'})}
                        </ListItem>
                    </List>
                </Box>

                <Box mt={{ base: 4, md: 0 }}>
                    <Box minH={{ base: 0, md: '80vh' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Image
                                borderRadius={'lg'}
                                maxH={'80vh'}
                                src={`${process.env.PUBLIC_URL}/images/mockups/attendance_report_web.jpeg`}
                            />
                    </Box>
                </Box>
            </SimpleGrid>
        </Box>
    );
};
