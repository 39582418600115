import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { InfoResult } from '../../components/results/InfoResult';

export default function SuErrorBoundary() {
    const intl = useIntl();
    const navigate = useNavigate();

    return (
        <InfoResult
            title={intl.formatMessage({ id: 'somethingWentWrong' })}
            text= {intl.formatMessage({ id: 'somethingWentWrong.tryLater' })}
            actionText={intl.formatMessage({ id: 'returnToApp' })}
            onAction={() => navigate('/home')}
            type={'error'}
        />
    );

}
