import { Icon, SimpleGrid, Td, Tr, Text, useTheme, VStack, HStack, Tooltip, Box, useColorModeValue } from '@chakra-ui/react';
import { AttendanceLogin, LoginFaceValidationResult, LoginPositionValidationResult } from '../../types';
import { FaMapMarkerAlt, FaPencilRuler, FaUserCheck, FaUserClock, FaUserTimes } from 'react-icons/fa';
import { useIntl } from 'react-intl';
import { FiLogIn, FiLogOut } from 'react-icons/fi';
import { ChevronRightIcon } from '@chakra-ui/icons';
import React from 'react';
import { MdSignalWifiOff } from 'react-icons/md';

export const PositionValidationIcon: React.FC<{ item: AttendanceLogin }> = ({ item }: { item: AttendanceLogin }) => {
    const intl = useIntl();
    let icon = null;
    if (item?.positionValidation === LoginPositionValidationResult.SUCCESS) {
        icon = <Icon boxSize={6} as={FaMapMarkerAlt} color="green" />;
    } else if (LoginPositionValidationResult.FAILED || item.positionValidation === LoginPositionValidationResult.ERROR) {
        icon = <Icon boxSize={6} as={FaMapMarkerAlt} color="red" />;
    } else if (LoginPositionValidationResult.WAITING === item.positionValidation) {
        icon = <Icon boxSize={6} as={FaMapMarkerAlt} color="blue" />;
    }

    return (
        <Tooltip label={intl.formatMessage({ id: 'POSITION_' + item.positionValidation })}>
            <Box>{icon}</Box>
        </Tooltip>
    );
};

export const FaceRecognitionIcon: React.FC<{ item: AttendanceLogin }> = ({ item }: { item: AttendanceLogin }) => {
    const intl = useIntl();
    let icon = null;

    if (item?.faceValidation === LoginFaceValidationResult.SUCCESS) {
        icon = <Icon boxSize={6} as={FaUserCheck} color="green" />;
    } else if (item.faceValidation === LoginFaceValidationResult.FAILED || item.faceValidation === LoginFaceValidationResult.ERROR) {
        icon = <Icon boxSize={6} as={FaUserTimes} color="red" />;
    } else if (LoginFaceValidationResult.WAITING === item.faceValidation) {
        icon = <Icon boxSize={6} as={FaUserClock} color="blue" />;
    }

    return (
        <Tooltip label={intl.formatMessage({ id: 'FACE_' + item.faceValidation })}>
            <Box>{icon}</Box>
        </Tooltip>
    );
};

export const ManualAttendanceIcon: React.FC<{ item: AttendanceLogin; creatorName?: string }> = ({ item, creatorName }) => {
    const intl = useIntl();
    if (item?.manual) {
        return (
            <Tooltip label={`${intl.formatMessage({ id: 'attendance.manual' })}${creatorName ? ' : ' + creatorName : ''}`}>
                <Box>
                    <Icon boxSize={6} as={FaPencilRuler} />
                </Box>
            </Tooltip>
        );
    } else {
        return null;
    }
};

export const OfflineAttendanceIcon: React.FC<{ item: AttendanceLogin }> = ({ item }) => {
    const intl = useIntl();
    if (item.isOfflineLogged) {
        return (
            <Tooltip
                label={`${intl.formatMessage({ id: 'attendance.offline.detail' }, { uploadedAt: intl.formatDate(item.updatedAt), uploadedTime: intl.formatTime(item.uploadedAt!) })}`}
            >
                <Box>
                    <Icon boxSize={6} as={MdSignalWifiOff} />
                </Box>
            </Tooltip>
        );
    } else {
        return null;
    }
};

type AttendanceTableItemProps = {
    item: AttendanceLogin;
    onClick?: (data: AttendanceItemClickPayload) => void;
};

export type AttendanceItemClickPayload = { item: AttendanceLogin; nextId?: number; prevId?: number };

export const AttendanceTableItem: React.FC<AttendanceTableItemProps> = ({ item, onClick }) => {
    const intl = useIntl();
    const theme = useTheme();

    return (
        <Tr
            role="group"
            _hover={{
                bg: useColorModeValue(theme.colors.gray[200], theme.colors.gray[600]),
                boxShadow: 'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px',
            }}
            cursor={'pointer'}
            onClick={() =>
                onClick?.({
                    item,
                })
            }
            bg={useColorModeValue('white', 'gray.700')}
        >
            <Td
                border="none" // Remove any default borders
                borderLeftRadius={'lg'}
            >
                <HStack>
                    <Tooltip label={intl.formatMessage({ id: item.direction })}>
                        <Box>
                            <Icon
                                as={item.direction === 'IN' ? FiLogIn : FiLogOut}
                                color={item.direction === 'IN' && theme.colors.primary[500]}
                                boxSize="6"
                            />
                        </Box>
                    </Tooltip>
                    <VStack alignItems="start" spacing="1">
                        <SimpleGrid spacing={1} columns={{ base: 1, md: 2 }}>
                            <Text fontWeight={600} fontSize="medium">
                                {item.user?.surname}
                            </Text>
                            <Text fontWeight={600} fontSize="medium">
                                {item.user?.firstName}
                            </Text>
                        </SimpleGrid>
                        <Tooltip label={intl.formatMessage({ id: 'zone' })}>
                            <Text fontSize="sm" _groupHover={{}} color={useColorModeValue('gray.500', 'gray.300')}>
                                {item.zone?.name}
                            </Text>
                        </Tooltip>
                    </VStack>
                </HStack>
            </Td>
            <Td>
                <VStack justifyItems={'end'} spacing={1}>
                    <Text fontSize="medium">{intl.formatDate(item.createdAt)}</Text>
                    <Text fontSize="medium" fontWeight={600}>
                        {intl.formatTime(item.createdAt)}
                    </Text>
                </VStack>
            </Td>
            <Td borderRightRadius={'lg'}>
                <HStack justifyContent={'space-between'}>
                    <SimpleGrid spacing={1} columns={{ base: 2, md: 3 }}>
                        <PositionValidationIcon item={item} />
                        <FaceRecognitionIcon item={item} />
                        <ManualAttendanceIcon item={item} />
                        <OfflineAttendanceIcon item={item} />
                    </SimpleGrid>
                    <ChevronRightIcon boxSize={5} display={{ base: 'none', md: 'block' }} />
                </HStack>
            </Td>
        </Tr>
    );
};
