import { Card, Center, Flex, Heading, IconButton, Image, Skeleton, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { Role, User } from '../../types';
import { LoadingCard } from '../ui/LoadingCard';
import { useIntl } from 'react-intl';
import { createImageUrl } from '../../query/apiClient';
import { ComponentProps, useState } from 'react';
import { FileDropZone } from '../ui/FileDropZone';
import { FaPhotoVideo } from 'react-icons/fa';
import { useUpdateEmployee } from '../../query/resource-hooks/company';
import { AccountMode } from '../../types/enums/AccountMode';
import UserPolicy from '../../policies/UserPolicy';
import { useAuthStore } from '../../store/auth/authStore';

type ProfileCardProps = ComponentProps<typeof Card> & {
    user?: User;
    onPhotoUpdate?: () => void;
};

export const UserProfileCard: React.FC<ProfileCardProps> = ({ user, onPhotoUpdate, ...cardProps }) => {
    const intl = useIntl();
    const [imageLoaded, setImageLoaded] = useState(false);
    const updateEmployee = useUpdateEmployee(() => onPhotoUpdate?.());
    const authUser = useAuthStore((s) => s.user);

    const editPhotoDisclosure = useDisclosure();
    const handleImageUpload = (files: File[]) => {
        updateEmployee.mutate({ data: { id: user?.id!, image: files[0] } });
    };

    if (!user) {
        return (
            <Center>
                <LoadingCard width={'md'} />
            </Center>
        );
    }

    return (
        <Card
            borderRadius="lg"
            w={{ sm: '100%' }}
            maxW={'640px'}
            height={{ md: '20rem' }}
            direction={{ base: 'column', md: 'row' }}
            boxShadow={'2xl'}
            padding={4}
            {...cardProps}
        >
            <Flex borderRadius={'md'} maxW={'max-content'} flex={1} bg={'primary.500'}>
                {user?.profilePhotoUrl && (
                    <Skeleton isLoaded={imageLoaded}>
                        <Image
                            onLoad={() => setImageLoaded(true)}
                            borderRadius={'md'}
                            maxH={500}
                            maxW={500}
                            objectFit="cover"
                            boxSize="100%"
                            aspectRatio={1}
                            src={createImageUrl(user.profilePhotoUrl)}
                        />
                    </Skeleton>
                )}
            </Flex>
            <Stack flex={1} flexDirection="column" alignItems="center" p={1} pt={2}>
                <Flex alignSelf={'end'} justifyContent={'end'}>
                    <Tooltip label={intl.formatMessage({ id: 'updatePhoto' })}>
                        <IconButton
                            colorScheme="primary"
                            aria-label="edit photo"
                            variant={'text'}
                            onClick={editPhotoDisclosure.onOpen}
                            icon={<FaPhotoVideo />}
                            isDisabled={!UserPolicy.canEditUser(authUser!, user) || user.role === Role.TEAM_ACCOUNT}
                        ></IconButton>
                    </Tooltip>
                </Flex>
                <Heading fontSize={'2xl'} fontFamily={'body'}>
                    {user.firstName} {user.surname || ''}
                </Heading>
                <Tooltip label={intl.formatMessage({ id: 'user.username' })}>
                    <Text fontWeight={600} size="sm" mb={4}>
                        {user.userName}
                    </Text>
                </Tooltip>
                <Tooltip label={intl.formatMessage({ id: 'role' })}>
                    <Text fontWeight={600} size="sm" mb={4}>
                        {intl.formatMessage({ id: user.role })}
                    </Text>
                </Tooltip>
                {user.role === Role.TEAM_ACCOUNT && (
                    <>
                        <Tooltip label={intl.formatMessage({ id: 'accountMode' })}>
                            <Text>{`${intl.formatMessage({ id: 'accountMode' })}: ${intl.formatMessage({ id: 'accountMode.' + (user.settings?.accountMode || AccountMode.ATTENDANCE) })}`}</Text>
                        </Tooltip>
                    </>
                )}
                {user.role === Role.TEAM_ACCOUNT &&
                    (user.settings?.accountMode === AccountMode.ATTENDANCE || user.settings?.accountMode === null) && (
                        <>
                            <Text>{`${intl.formatMessage({ id: 'defaultZone' })}: ${user.settings?.defaultZone?.name ?? '-'}`}</Text>
                        </>
                    )}
                {user.role === Role.TEAM_ACCOUNT &&
                    (user.settings?.accountMode === AccountMode.VEHICLE_MANAGEMENT || user.settings?.accountMode === null) && (
                        <>
                            <Text>{`${intl.formatMessage({ id: 'defaultVehicle' })}: ${user.settings?.defaultVehicle?.name ?? '-'}`}</Text>
                        </>
                    )}
            </Stack>
            <FileDropZone
                isOpen={editPhotoDisclosure.isOpen}
                onClose={editPhotoDisclosure.onClose}
                onDrop={handleImageUpload}
                title={intl.formatMessage({ id: 'updatePhoto' })}
            />
        </Card>
    );
};
