import { AxiosError } from 'axios';
import { Zone } from '../../types';
import React, { ComponentProps } from 'react';
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    FormControl,
    FormHelperText,
    HStack,
    useToast,
} from '@chakra-ui/react';
import MapEditor from './editor/MapEditor';
import { useIntl } from 'react-intl';
import SchemaProvider from '../../utils/SchemaProvider';
import { Form, Formik } from 'formik';
import FormikTextInput from '../formik/FormikTextInput';
import { InferType } from 'yup';
import { useCreateZone, useUpdateZone } from '../../query/resource-hooks/company';

export type ZoneFormProps = Omit<ComponentProps<typeof Drawer>, 'children'> & {
    onSuccess?: (zone: Zone) => void;
    onError?: (error: AxiosError) => void;
    updatedZone?: Zone;
};

export const ZoneForm: React.FC<ZoneFormProps> = ({ onSuccess, onError, isOpen, updatedZone, onClose, ...rest }) => {
    const intl = useIntl();

    const validationSchema = SchemaProvider.getZoneSchema(intl);
    const createZone = useCreateZone(handleSuccess, handleError);
    const updateZone = useUpdateZone(handleSuccess, handleError);
    const toast = useToast();

    function handleClose() {
        createZone.reset();
        onClose();
    }

    function handleSuccess(zone: Zone) {
        toast({
            title: intl.formatMessage({ id: updatedZone ? 'zoneUpdated' : 'zoneCreated' }),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
        });
        onSuccess?.(zone);
        handleClose();
    }

    function handleError(error: AxiosError) {
        toast({
            title: intl.formatMessage({ id: 'somethingWentWrong' }),
            status: 'error',
            duration: 10000,
            isClosable: true,
            position: 'top'
        });
        onError?.(error);
    }

    const handleSubmit = (values: InferType<typeof validationSchema>) => {
        if (updatedZone) {
            updateZone.mutate({
                zoneId: updatedZone.id,
                name: values.name,
                markers: values.polygon
            })
        } else {
            createZone.mutate({
                name: values.name,
                markers: values.polygon,
            });
        }
    };

    return (
        <Drawer size={'xl'} isOpen={isOpen} onClose={handleClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth={'1px'}>{intl.formatMessage({ id: updatedZone ? 'editZone' : 'createZone' })}</DrawerHeader>
                <DrawerBody>
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={{
                            name: updatedZone?.name ?? '',
                            polygon: updatedZone?.coordinates ?? [],
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
                            <Form>
                                <FormControl>
                                    <MapEditor
                                        onDeleted={() => {
                                            setFieldValue('polygon', []);
                                        }}
                                        onPolygonChange={(markers) => {
                                            // console.log('MARKERS', markers);
                                            handleBlur('polygon');
                                            setFieldValue('polygon', markers);
                                        }}
                                        existingPolygon={values.polygon}
                                    />
                                    {errors.polygon && (
                                        <Box>
                                            <FormHelperText color={'red.500'}>{errors.polygon as string}</FormHelperText>
                                        </Box>
                                    )}
                                    <Box pt={4}>
                                        <FormikTextInput
                                            errors={errors}
                                            name="name"
                                            fieldName="name"
                                            touched={touched}
                                            values={values}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            label={intl.formatMessage({ id: 'zoneName' })}
                                        />
                                    </Box>
                                    <HStack justifyContent={'center'} mt={2}>
                                        <Button
                                            type="submit"
                                            colorScheme="green"
                                            isLoading={createZone.isLoading}
                                            isDisabled={createZone.isLoading}
                                        >
                                            {intl.formatMessage({ id: updatedZone ? 'save' : 'create' })}
                                        </Button>
                                    </HStack>
                                </FormControl>
                            </Form>
                        )}
                    </Formik>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
