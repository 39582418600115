import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Divider,
    Heading,
    ListItem,
    OrderedList,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import { FeatureAttendanceReport } from '../../../components/about/FeatureTabs/Tabs/FeatureAttendanceReport';
import { FeatureLogAttendanceTab } from '../../../components/about/FeatureTabs/Tabs/FeatureLogAttendanceTab';
import { FeatureLogOwnAttendance } from '../../../components/about/FeatureTabs/Tabs/FeatureLogOwnAttendance';

export const DocsAttendance: React.FC = () => {
    return (
        <Box pb={12}>
            <Box maxW={'1140px'} marginX={'auto'} px={{ base: 2, md: 0 }} pt={4}>
                <Heading p={4}>Aké záznamy v syséme nájdem a čo obsahujú?</Heading>
                <Text>Systém obsahuje dva typy záznamov dochádzky:</Text>
                <Card mt={4}>
                    <CardHeader>
                        <Heading size="md">1. Záznam dochádzky vytvorený zamestnancom v mobilnej aplikácii</Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>
                            Zamestnanci si v mobilnej aplikácií nahlasujú svoje príchody a odchody z práce. Po každom nahlásení systém
                            dochádzku zaeviduje a vy ju môžete následne sledovať. Pri každom zázname dochádzky vidíte tieto dáta:
                        </Text>

                        <OrderedList>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Dátum a čas</Text>
                                </Box>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Priradená zóna</Text>
                                    <Text>
                                        Každé prihlásenie dochádzky v systéme má priradenú pracovnú zónu, teda pracovisko, kde by sa mal
                                        zamestnanec nachádzať.
                                    </Text>
                                </Box>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Poloha</Text>
                                    <Text>
                                        Systém zaznamená polohu GPS zamestnanca pri nahlásení dochádzky. Poloha je overená voči vami
                                        zakreslenej pracovnej zóny.
                                    </Text>
                                </Box>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Forografia</Text>
                                    <Text>
                                        Záznam dochádzky obsahuje fotografiu zamestnanca vyhotovenú pri zapísanej dochádzky. Fotografia je
                                        automaticky analyzovaná - výsledkom je rozpoznanie tváre, ktoré uľahčuje kontrolu dochádzky.
                                    </Text>
                                </Box>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Informácie o zariadení</Text>
                                    <Text>Systém zaznamená informácie z akého zariadenia bola dochádzka zapísaná.</Text>
                                </Box>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Voliteľná poznámka</Text>
                                    <Text>Dodatočná informácia k dochádzke.</Text>
                                </Box>
                            </ListItem>
                        </OrderedList>
                        <Divider py={2} />
                        <Heading size={'md'} px={4} py={2}>
                            Ako sa záznam vytvára?
                        </Heading>
                        <Text>Záznam je možné vytvoriť v mobilnej aplikácii s prihláseným tímovým účtom alebo účtom zamestnanca</Text>
                    </CardBody>

                    <Box background={'black'} borderRadius={'md'} pt={2}>
                        <Tabs align="center" colorScheme={'whiteAlpha'} isManual variant="enclosed">
                            <TabList mx={{ base: 0, xl: 10 }}>
                                <Tab className="feature_tab_header">Tímový účet</Tab>
                                <Tab className="feature_tab_header">Účet zamestnanca</Tab>
                            </TabList>
                            <TabPanels h={{ base: undefined, md: '90vh' }}>
                                <TabPanel>
                                    <FeatureLogAttendanceTab />
                                </TabPanel>
                                <TabPanel>
                                    <FeatureLogOwnAttendance />
                                </TabPanel>
                                <TabPanel>
                                    <FeatureAttendanceReport />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Card>

                <Card mt={8}  id='manual_attendance' >
                    <CardHeader>
                        <Heading size="md">2. Záznam dochádzky zapísaný vedúcim pre vybraného zamestnanca (manuálny záznam)</Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>
                            Vedúci pracovníci s rolami Hlavného správcu a Správcu môžu zapísať dochádzku pre ostatných zamestnancov. Tieto
                            záznamy neobsahujú fotografiu ani reálnu polohu zamestnanca. Slúžia na vyplnenie medzier v dochádzke v prípade
                            výnimočných situácií. (príklad: Zamestnanec mal výnimočnú úlohu a nemohol sa zapísať na pracovisku)
                        </Text>

                        <OrderedList>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Dátum a čas</Text>
                                </Box>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Priradená zóna</Text>
                                    <Text>
                                        Každé prihlásenie dochádzky v systéme má priradenú pracovnú zónu, teda pracovisko, kde by sa mal
                                        zamestnanec nachádzať.
                                    </Text>
                                </Box>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Potvrdenie o vytvorení správcom</Text>
                                    <Text>Záznam obsahuje informáciu o tom, ktorý správca záznam vytvoril</Text>
                                </Box>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <Text fontWeight={600}>Voliteľná poznámka</Text>
                                    <Text>Dodatočná informácia k dochádzke.</Text>
                                </Box>
                            </ListItem>
                        </OrderedList>
                    </CardBody>
                </Card>

                <Heading p={4} id="edit_attendance">
                    Ako upravovať dochádzku?
                </Heading>
                <Text>
                    Používatelia s rolami <b>Hlavný správca</b> a <b>Správca</b> môžu upravovať záznamy dochádzky podriadených. Každý záznam
                    dochádzky obsahuje svoju históriu úprav. Kedykoľvek vidíte kto, kedy a ako záznam upravil. Tlačítko na úpravu docházkdy
                    nájdete v detaile záznamu.
                </Text>
                <Card mt={4}>
                    <CardHeader>
                        <Heading size="md">Čo môžem v zázname dochádzky upraviť</Heading>
                    </CardHeader>
                    <CardBody>
                        <UnorderedList>
                            <ListItem>
                                <Text fontWeight={600}>Typ (príchod/odchod)</Text>
                            </ListItem>
                            <ListItem>
                                <Text fontWeight={600}>Čas a dátum</Text>
                            </ListItem>
                            <ListItem>
                                <Text fontWeight={600}>Poznámku</Text>
                            </ListItem>
                        </UnorderedList>
                    </CardBody>
                </Card>

                <Heading p={4} id="attendance_report">Čo je výpis docházky?</Heading>
                <Text>
                    Výpis dochádzky vám pomôže spárovať jednotlivé príchody a odchody. Výpis je možné vytvoriť vo webovej aplikácii v detaile zamestnanca. Maximálne obdobie za ktoré môžete vytvoriť jeden výpis sú 3 mesiace.
                    V rámci výpisu dochádzky môžete jednoducho a s prehľadom upraviť dochádzku zamestnanca.
                </Text>
                <Card mt={4}>
                    <CardHeader>
                        <Heading size="md">Čo výpis dochádzky obsahuje?</Heading>
                    </CardHeader>
                    <CardBody>
                        <UnorderedList>
                            <ListItem>
                                <Text fontWeight={600}>Spárované príchody a odchody</Text>
                            </ListItem>
                            <ListItem>
                                <Text fontWeight={600}>Odpracované hodiny za jednotlivé dni</Text>
                            </ListItem>
                            <ListItem>
                                <Text fontWeight={600}>Odpracované hodiny za vybrané obdobie</Text>
                            </ListItem>
                        </UnorderedList>
                    </CardBody>

                    <Box background={'black'} borderRadius={'md'} p={2}>
                        <FeatureAttendanceReport />
                    </Box>
                </Card>
            </Box>
        </Box>
    );
};
