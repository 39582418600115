import { Box, Heading, Stack, Text } from '@chakra-ui/react';

export const DocsAdministration: React.FC = () => {
    return (
        <Box pb={12}>
            <Box maxW={'1140px'} marginX={'auto'} px={{ base: 2, md: 0 }} pt={4}>
                <Heading p={4}>Čo je Administrácia s systéme Supervizer?</Heading>
                <Stack spacing={1}>
                    <Text>
                        Administrácia je pripravované rozšírenie systému. Toto rozšírenie prinesie funkcionalitu ako správu vozidiel,
                        dokumentov ako zápis o prevázke vozidla a iné. Táto súčasť systému je v aktívnom vývoji. Ak máte záujem dozvedieť sa
                        viac a získať predbežný prístup neváhajte nás kontaktovať.
                    </Text>
                </Stack>
            </Box>
        </Box>
    );
};
