import type { CookieConsentConfig } from "vanilla-cookieconsent";

const getConfig = () => {
  const config: CookieConsentConfig = {
    // root: 'body',
    // autoShow: true,
    // disablePageInteraction: true,
    // hideFromBots: true,
    // mode: 'opt-in',
    // revision: 0,

    cookie: {
      // name: 'cc_cookie',
      // domain: location.hostname,
      // path: '/',
      // sameSite: "Lax",
      // expiresAfterDays: 365,
    },

    /**
     * Callback functions
     */
    onFirstConsent: ({ cookie }) => {
      // console.log("onFirstConsent fired", cookie);
    },

    onConsent: ({ cookie }) => {
      // console.log("onConsent fired!", cookie);
    },

    onChange: ({ changedCategories, changedServices }) => {
      // console.log("onChange fired!", changedCategories, changedServices);
    },

    onModalReady: ({ modalName }) => {
      // console.log("ready:", modalName);
    },

    onModalShow: ({ modalName }) => {
      // console.log("visible:", modalName);
    },

    onModalHide: ({ modalName }) => {
      // console.log("hidden:", modalName);
    },

    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
    guiOptions: {
      consentModal: {
        layout: "box inline",
        position: "bottom left",
        equalWeightButtons: true,
        flipButtons: false,
      },
      preferencesModal: {
        layout: "box",
        equalWeightButtons: true,
        flipButtons: false,
      },
    },

    categories: {
      necessary: {
        enabled: true, // this category is enabled by default
        readOnly: true, // this category cannot be disabled
      },
      analytics: {
        autoClear: {
          cookies: [
            {
              name: /^_ga/, // regex: match all cookies starting with '_ga'
            },
            {
              name: "_gid", // string: exact cookie name
            },
          ],
        },

        // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
        services: {
          ga: {
            label: "Google Analytics",
            onAccept: () => {},
            onReject: () => {},
          },
        },
      },
    },

    language: {
      default: "sk",
      autoDetect: 'browser',
      translations: {
        en: {
          consentModal: {
            title: "We use cookies",
            description:
              "We use cookies to measure user interactions through Google Analytics for simple statistical purposes.",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            showPreferencesBtn: "Manage Individual preferences",
            // closeIconLabel: 'Reject all and close modal',
            // footer: `
            //             <a href="#path-to-impressum.html" target="_blank">Impressum</a>
            //             <a href="#path-to-privacy-policy.html" target="_blank">Privacy Policy</a>
            //         `,
          },
          preferencesModal: {
            title: "Manage cookie preferences",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            savePreferencesBtn: "Accept current selection",
            closeIconLabel: "Close modal",
            serviceCounterLabel: "Service|Services",
            sections: [
              {
                title: "Your Privacy Choices",
                description: `In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.`,
              },
              {
                title: "Strictly Necessary",
                description:
                  "These cookies are essential for the proper functioning of the website and cannot be disabled.",

                //this field will generate a toggle linked to the 'necessary' category
                linkedCategory: "necessary",
              },
              {
                title: "Performance and Analytics",
                description:
                  "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                linkedCategory: "analytics",
                cookieTable: {
                  caption: "Cookie table",
                  headers: {
                    name: "Cookie",
                    domain: "Domain",
                    desc: "Description",
                  },
                  body: [
                    {
                      name: "_ga",
                      domain: window.location.hostname,
                      desc: "Used to distinguish users for Google Analytics.",
                    },
                    {
                      name: "_gid",
                      domain: window.location.hostname,
                      desc: "Used to distinguish users for Google Analytics.",
                    },
                  ],
                },
              },
              {
                title: "More information",
                description:
                  'For more information read our <a href="/terms-of-use">terms of use</a> and <a href="/policy">privacy policy</a>',
              },
            ],
          },
        },
        sk: {
          consentModal: {
            title: "Používame cookies",
            description:
              "Používame cookies na meranie interakcií používateľov prostredníctvom Google Analytics na jednoduché štatistické účely.",
            acceptAllBtn: "Prijať všetky",
            acceptNecessaryBtn: "Odmietnuť všetky",
            showPreferencesBtn: "Spravovať individuálne preferencie",
            // closeIconLabel: 'Odmietnuť všetky a zatvoriť modal',
            // footer: `
            //             <a href="#path-to-impressum.html" target="_blank">Impressum</a>
            //             <a href="#path-to-privacy-policy.html" target="_blank">Ochrana osobných údajov</a>
            //         `,
          },
          preferencesModal: {
            title: "Spravovať preferencie cookies",
            acceptAllBtn: "Prijať všetky",
            acceptNecessaryBtn: "Odmietnuť všetky",
            savePreferencesBtn: "Prijať aktuálny výber",
            closeIconLabel: "Zatvoriť modal",
            serviceCounterLabel: "Služba|Služby",
            sections: [
              {
                title: "Vaše voľby ochrany osobných údajov",
                description: `V tomto paneli môžete vyjadriť niektoré preferencie týkajúce sa spracovania vašich osobných údajov. Môžete kedykoľvek preskúmať a zmeniť vyjadrené voľby opätovným zobrazením tohto panelu prostredníctvom poskytnutého odkazu. Ak chcete odmietnuť svoj súhlas so špecifickými spracovateľskými činnosťami uvedenými nižšie, prepínajte prepínače na vypnutie alebo použite tlačidlo „Odmietnuť všetky“ a potvrďte, že chcete uložiť svoje voľby.`,
              },
              {
                title: "Prísne nevyhnutné",
                description:
                  "Tieto cookies sú nevyhnutné pre správne fungovanie webovej stránky a nemožno ich vypnúť.",
                linkedCategory: "necessary",
              },
              {
                title: "Výkonnosť a analytika",
                description:
                  "Tieto cookies zhromažďujú informácie o tom, ako používate našu webovú stránku. Všetky údaje sú anonymizované a nemožno ich použiť na vašu identifikáciu.",
                linkedCategory: "analytics",
                cookieTable: {
                  caption: "Tabuľka cookies",
                  headers: {
                    name: "Cookie",
                    domain: "Doména",
                    desc: "Popis",
                  },
                  body: [
                    {
                      name: "_ga",
                      domain: window.location.hostname,
                      desc: "Používa sa na rozlíšenie používateľov pre Google Analytics.",
                    },
                    {
                      name: "_gid",
                      domain: window.location.hostname,
                      desc: "Používa sa na rozlíšenie používateľov pre Google Analytics.",
                    },
                  ],
                },
              },
              {
                title: "Viac informácií",
                description:
                  'Pre viac informácii prečítajte naše <a href="/terms-of-use">podmienky používania</a> a <a href="/policy">zásady ochrany osobných údajov</a>',
              },
            ],
          },
        },
      },
    },
  };

  return config;
};

export default getConfig;
