import { useIntl } from 'react-intl';
import { useGetRegisteredDevicesQuery } from '../../query/resource-hooks/devices';
import { useParams } from 'react-router-dom';
import { Card, HStack, ListIcon, ListItem, Text, useColorModeValue, useDisclosure, useTheme, VStack } from '@chakra-ui/react';
import InfiniteList from '../../components/lists/InfiniteList';
import { DeviceType, RegisteredDevice } from '../../types';
import { FiMonitor, FiSmartphone, FiTablet } from 'react-icons/fi';
import { DeviceDetailModal } from '../../components/devices/DeviceDetailModal';
import { useState } from 'react';
import { useAuthStore } from '../../store/auth/authStore';

const DeviceList: React.FC<{ userId: number }> = () => {
    const routeParams = useParams<{ id: string }>();
    const devicesQuery = useGetRegisteredDevicesQuery({ userId: +routeParams.id! });
    const [openedDevice, setOpenedDevice] = useState<RegisteredDevice | null>(null);
    const deviceDetailDisclosure = useDisclosure();
    const loggedUser = useAuthStore((s) => s.user);

    return (
        <>
            <InfiniteList<RegisteredDevice>
                data={devicesQuery.data}
                fetchNextPage={devicesQuery.fetchNextPage}
                hasNextPage={devicesQuery.hasNextPage}
                isFetchingNextPage={devicesQuery.isFetchingNextPage}
                renderItem={(device) => (
                    <RegisteredDeviceListItem
                        device={device}
                        onClick={() => {
                            setOpenedDevice(device);
                            deviceDetailDisclosure.onOpen();
                        }}
                        key={device.id}
                    />
                )}
            ></InfiniteList>
            <DeviceDetailModal
                device={openedDevice}
                isOpen={deviceDetailDisclosure.isOpen}
                onClose={() => {
                    deviceDetailDisclosure.onClose();
                    setOpenedDevice(null);
                }}
                loggedUser={loggedUser || undefined}
                userId={+routeParams.id!}
                onLogout={() => devicesQuery.refetch()}
            />
        </>
    );
};

const RegisteredDeviceListItem: React.FC<{ device: RegisteredDevice; onClick?: () => void }> = ({ device, onClick }) => {
    const theme = useTheme();
    const intl = useIntl();

    return (
        <ListItem
            cursor={'pointer'}
            borderRadius={'lg'}
            key={device.id}
            m={1}
            onClick={onClick}
        >
            <Card
                p={2}
                pl={3}
                borderRadius={'lg'}
                _hover={{
                    bg: useColorModeValue(theme.colors.gray[200], theme.colors.gray[600]),
                    boxShadow: 'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
            >
                <HStack>
                    <ListIcon as={getDeviceIcon(device)} boxSize={6} />
                    <HStack justify={'space-between'} width={'100%'}>
                        <VStack align={'start'} spacing={0}>
                            <Text fontWeight={600} fontSize={'md'}>
                                {device.name}
                            </Text>
                            <Text fontSize={'small'}>{device.client}</Text>
                        </VStack>
                        <Text display={{ base: 'none', md: 'block' }}>
                            {intl.formatMessage({ id: 'device.loggedAt' }, { date: intl.formatDate(device.createdAt) })}
                        </Text>
                    </HStack>
                </HStack>
            </Card>
        </ListItem>
    );
};

function getDeviceIcon(device: RegisteredDevice): React.ElementType {
    switch (device.type) {
        case DeviceType.DESKTOP:
        case DeviceType.OTHER:
            return FiMonitor;
        case DeviceType.MOBILE:
            return FiSmartphone;
        case DeviceType.TABLET:
            return FiTablet;
        default:
            return FiMonitor;
    }
}

export { DeviceList, getDeviceIcon };
