import { Box, HStack, IconButton, Divider, Heading, MenuButton, Menu, MenuItem, MenuList } from '@chakra-ui/react';
import { ArrowBackIcon, HamburgerIcon } from '@chakra-ui/icons';
import { ScrollRestoration } from 'react-router-dom';

interface SecondPageHeaderProps {
    title: string;
    onBack?: () => void;
    menuListItems?: {
        title: string;
        onClick: () => void;
        icon?: React.ReactElement;
    }[];
}

const SecondPageHeader: React.FC<SecondPageHeaderProps> = ({ title, onBack, menuListItems }) => {
    return (
        <Box p={2}>
            <ScrollRestoration />
            <HStack spacing={2} justifyContent={'space-between'}>
                {onBack && <IconButton aria-label="back" icon={<ArrowBackIcon boxSize={6} />} onClick={onBack} borderRadius={20} />}
                <Divider />
                <Heading minWidth={'fit-content'} size={'md'}>
                    {title}
                </Heading>
                <Divider />
                {menuListItems?.length && (
                    <Menu>
                        <MenuButton as={IconButton} icon={<HamburgerIcon />} aria-label="menu" transition="all 0.3s" borderRadius={20}></MenuButton>

                        <MenuList zIndex={1000}>
                            {menuListItems.map((item, index) => (
                                <MenuItem key={index} onClick={item.onClick} icon={item.icon}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                )}
            </HStack>
        </Box>
    );
};

export default SecondPageHeader;
