import { FormControl, FormLabel, Select, Text } from '@chakra-ui/react';

type Props = React.ComponentProps<typeof Select> & {
    label: string;
    values: Record<string, any>;
    fieldName: string;
    handleChange: (field: string) => any;
    errors: Record<string, any>;
    handleBlur: (field: string) => any;
    touched: Record<string, any>;
    afterChange?: (value: string) => void;
    options: { value: string; label: string }[];
};

export const FormikSelect: React.FC<Props> = ({
    values,
    label,
    fieldName,
    handleBlur,
    handleChange,
    errors,
    touched,
    afterChange,
    options,
    ...restProps
}) => {
    return (
        <FormControl>
            <FormLabel htmlFor={fieldName}>{label}</FormLabel>
            <Select
                value={values[fieldName]}
                {...restProps}
                id={fieldName}
                isInvalid={!!(errors[fieldName] && touched[fieldName])}
                onChange={(e) => {
                    handleChange(fieldName)(e.target.value);
                    afterChange?.(e.target.value);
                }}
            >
                {options.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    );
                })}
            </Select>
            {!!(errors[fieldName] && touched[fieldName]) ? (
                <Text color="red.500" fontSize="sm">
                    {errors[fieldName] as string}
                </Text>
            ) : null}
        </FormControl>
    );
};
