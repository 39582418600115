import { Box, Skeleton } from '@chakra-ui/react';
import React from 'react';

const ZonesListSkeleton: React.FC = () => {
    return (
        <>
            {Array.from({ length: 10 }).map((_, index) => (
                <Box key={index}  my={'1px'}>
                    <Skeleton h={'25px'} />
                </Box>
            ))}
        </>
    );
};

export default ZonesListSkeleton;
