import React from 'react';
import { PairedShift, WorkDayReport } from '../../../types';
import { Box, VStack, Text, HStack, Divider, Card, Badge, Icon, useTheme, Flex, useColorModeValue, Tooltip } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { FiMap } from 'react-icons/fi';
import { BsClock } from 'react-icons/bs';
import { ChevronRightIcon } from '@chakra-ui/icons';

type ReportWorkDayCardProps = {
    workDay: WorkDayReport;
    onShiftClick?: (shift: PairedShift, index: number) => void;
    isSelected?: boolean; // Current day is selected
    onDayClick?: (workDay: WorkDayReport) => void;
    selectedShiftIndex?: number; // Index of selected shift in day
};

export const ReportWorkDayCard: React.FC<ReportWorkDayCardProps> = ({
    workDay,
    onShiftClick,
    onDayClick,
    isSelected,
    selectedShiftIndex,
}) => {
    const intl = useIntl();
    const theme = useTheme();

    const selectedPrimary = useColorModeValue(theme.colors.primary[200], theme.colors.primary[700]);
    const selectedColor = useColorModeValue(theme.colors.gray[200], theme.colors.gray[700]);
    return (
        <HStack alignItems={'start'}>
            <Card backgroundColor={isSelected ? selectedPrimary : undefined} borderRadius="xl" boxShadow={'xl'}>
                <VStack textAlign="center" p="2" borderRadius="md">
                    <Text fontSize="xl" fontWeight="bold">
                        {intl.formatDate(workDay.date, { day: 'numeric' })}
                    </Text>
                    <Divider />
                    <Text fontSize="lg">{intl.formatDate(workDay.date, { month: 'short' })}</Text>
                </VStack>
            </Card>

            <Card
                backgroundColor={isSelected ? selectedPrimary : undefined}
                borderRadius="xl"
                boxShadow={'xl'}
                minWidth={250}
                onClick={() => onDayClick?.(workDay)}
            >
                <Box display={'flex'} justifyContent={'space-between'} m={1}>
                    <Badge borderRadius={'xl'}>{intl.formatDate(workDay.date, { weekday: 'long' })}</Badge>
                    <Tooltip label={intl.formatMessage({ id: 'attendanceReport.timeInDay' })}>
                        <Badge borderRadius={'xl'}>
                            {workDay.shifts.reduce((sum, currentShift) => {
                                return sum + (currentShift.hoursInShift || 0);
                            }, 0)}
                            h
                        </Badge>
                    </Tooltip>
                </Box>
                <VStack alignItems={'start'} p="4" borderColor="gray.200">
                    {workDay.shifts.map((shift, index) => (
                        <Card
                            backgroundColor={selectedShiftIndex === index && isSelected ? selectedColor : undefined}
                            key={JSON.stringify(shift)}
                            _hover={{ transform: 'scale(1.05)' }}
                            style={selectedShiftIndex === index && isSelected ? { transform: 'scale(1.05)' } : undefined}
                            transition="transform 0.2s"
                            cursor={'pointer'}
                            onClick={() => onShiftClick?.(shift, index)}
                            variant={'outline'}
                            width={'100%'}
                            p={2}
                            borderRadius={'xl'}
                            boxShadow={'sm'}
                        >
                            <HStack justifyContent={'space-between'}>
                                <VStack alignItems={'start'} key={index} display="flex" mb="2">
                                    <Flex alignItems={'center'}>
                                        <Icon color={theme.colors.primary} as={FiMap} mr={1}></Icon>
                                        <Text fontWeight={'600'}>{shift.zone?.name}</Text>
                                    </Flex>
                                    <Flex alignItems={'center'}>
                                        <Icon as={BsClock} mr={1} />
                                        <Text>
                                            {`${shift.arrival ? intl.formatDate(shift.arrival.date, { hour: 'numeric', minute: 'numeric' }) : '-'} - ${shift.departure ? intl.formatDate(shift.departure.date, { hour: 'numeric', minute: 'numeric' }) : '-'}`}
                                        </Text>
                                    </Flex>
                                    {workDay.shifts.length > 1 && (
                                        <Tooltip label={intl.formatMessage({ id: 'attendanceReport.timeInShift' })}>
                                            <Badge>{shift.hoursInShift ?? 0} h</Badge>
                                        </Tooltip>
                                    )}
                                </VStack>
                                <ChevronRightIcon />
                            </HStack>
                        </Card>
                    ))}
                </VStack>
            </Card>
        </HStack>
    );
};
