import { Box, Card, CardBody, CardHeader, Heading, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';

export const DocsZones: React.FC = () => {
    return (
        <Box pb={12}>
            <Box maxW={'1140px'} marginX={'auto'} px={{ base: 2, md: 0 }} pt={4}>
                <Heading p={4}>Pracovné zóny a ich význam</Heading>
                <Stack spacing={1}>
                    <Text>
                        Zóny v systéme Supervizer predstavujú pracoviská, na ktorých by zamestnanci mali nahlasovať svoju dochádzku.
                    </Text>
                    <Text>Každá zóna musí mať svoj názov a polohu na mape</Text>
                    <Card mt={4}>
                        <CardHeader>
                            <Heading size="md">Na čo zóna slúži?</Heading>
                        </CardHeader>
                        <CardBody>
                            <UnorderedList>
                                <ListItem>
                                    <Text fontWeight={600}>Automatická kontrola polohy zamestnancov</Text>
                                    <Text>
                                        Vďaka vyznačeným zónam môže systém automaticky skontrolovať či sa zamestnanec prihlásil na správnom
                                        mieste
                                    </Text>
                                </ListItem>
                                <ListItem>
                                    <Text fontWeight={600}>Prehľad</Text>
                                    <Text>
                                        Rozdelenie dochádzky medzi pracovné zóny vám umožní lepší prehľad o dochádzke. Jednoducho uvidíte
                                        kto, kde prišiel a odišiel
                                    </Text>
                                </ListItem>
                                <ListItem>
                                    <Text fontWeight={600}>Hlásenia</Text>
                                    <Text>
                                        K zónam je možné pridávať hlásenia. Hlásenia pozostávajú z popisu, textu a obrázkov.
                                    </Text>
                                </ListItem>
                            </UnorderedList>
                        </CardBody>
                    </Card>
                </Stack>
                <Heading p={4} size={'md'} id='archive'>Vymazanie zóny - archív</Heading>
                <Text>
                    Zónu je možné kedykoľvek vymazať. Ak vymazaná zóna obsahuje záznamy dochádzky alebo hlásenia, zóna je presunutá do archývu. S výnimkou fotografií nestratíte žiadne údaje o dochádzke. Vymazanú zónu nie je možné obnoviť ani použiť pri zapísaní dochádzky.
                </Text>
                
            </Box>
        </Box>
    );
};
