import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Text,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
    Textarea,
    useToast,
    ModalCloseButton,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { ComponentProps, useState } from 'react';
import { useIntl } from 'react-intl';
import SchemaProvider from '../../../utils/SchemaProvider';
import FormikTextInput from '../../formik/FormikTextInput';
import { InferType } from 'yup';
import { send } from '@emailjs/browser';

type ContactModalProps = Omit<ComponentProps<typeof Modal>, 'children'>;

export const ContactModal: React.FC<ContactModalProps> = ({ isOpen, onClose }) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const initialRef = React.useRef<HTMLInputElement>(null);
    const contactSchema = SchemaProvider.contactUsSchema(intl);

    const handleSubmit = async (values: InferType<typeof contactSchema>) => {
        try {
            setIsLoading(true);
            await send(process.env.REACT_APP_EMAILJS_SERVICE_ID!, process.env.REACT_APP_EMAILJS_TEMPLATE_ID!, {
                name: values.name,
                contact: values.contact,
                message: values.message,
            }, {
                publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            });
            toast({
                title: intl.formatMessage({ id: 'contactUs.confirmation' }),
                status: 'success',
                duration: 6000,
                isClosable: true,
                position: 'top',
            });
            onClose();
        } catch (error) {
            console.error('Failed to send email', error);
            toast({
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                status: 'error',
                duration: 6000,
                isClosable: true,
                position: 'top',
            });
        }
        setIsLoading(false);
    };

    return (
        <Modal initialFocusRef={initialRef} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxW={'800px'}>
                <ModalHeader>{intl.formatMessage({ id: 'contactUs.title' })}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        <Formik
                            onSubmit={handleSubmit}
                            validationSchema={contactSchema}
                            initialValues={{
                                name: '',
                                contact: '',
                                message: '',
                            }}
                        >
                            {({ errors, touched, handleChange, handleBlur, values }) => (
                                <Form>
                                    <VStack>
                                        <FormikTextInput
                                            errors={errors}
                                            fieldName="name"
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            label={intl.formatMessage({ id: 'name' })}
                                            values={values}
                                            inputRef={initialRef}
                                        />
                                        <FormikTextInput
                                            errors={errors}
                                            fieldName="contact"
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            label={intl.formatMessage({ id: 'title.contact' })}
                                            values={values}
                                        />
                                        <FormControl isInvalid={!!(errors['message'] && touched['message'])}>
                                            <FormLabel>
                                                <Text>{intl.formatMessage({ id: 'contactUs.message' })}</Text>
                                            </FormLabel>
                                            <Textarea
                                                onBlur={handleBlur('message')}
                                                onChange={(e) => handleChange('message')(e.target.value)}
                                                maxLength={500}
                                            ></Textarea>
                                        </FormControl>
                                        <Button isLoading={isLoading} type="submit" colorScheme="green">
                                            {intl.formatMessage({ id: 'form.submit' })}
                                        </Button>
                                    </VStack>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
