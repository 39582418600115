import React, { useState } from 'react';
import { Card, CardBody, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react';
import { EmployeeSelectModal } from '../../screens/employees/EmployeesSelectModal';
import { User } from '../../types';
import { FaUser } from 'react-icons/fa';
import { CheckIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useIntl } from 'react-intl';

type EmployeeSelectButtonProps = {
    onSelect: (user: User) => void;
    initialUser?: User;
};

const EmployeeSelectButton: React.FC<EmployeeSelectButtonProps> = ({ onSelect, initialUser }) => {
    const intl = useIntl();
    const modalDisclosure = useDisclosure();
    const [selectedUser, setSelectedUser] = useState<User | null>(initialUser ?? null);

    function handleSelect(user: User) {
        setSelectedUser(user);
        onSelect(user);
    }

    return (
        <>
            <Card cursor={'pointer'} onClick={modalDisclosure.onOpen} variant={'outline'} width={'100%'}>
                <CardBody>
                    <Flex alignItems={'center'}>
                        <Icon color={selectedUser ? 'green' : undefined} as={FaUser} mr={2} />
                        <Heading size={'xs'}>{selectedUser ? selectedUser.fullName : intl.formatMessage({id: 'selectAccount'})}</Heading>
                        <Icon ml={'auto'}  color={selectedUser ? 'green' : undefined} as={selectedUser ? CheckIcon : ChevronRightIcon} />
                    </Flex>
                </CardBody>
            </Card>
            <EmployeeSelectModal
                onSelect={handleSelect}
                selectedUserId={selectedUser?.id}
                isOpen={modalDisclosure.isOpen}
                onClose={modalDisclosure.onClose}
            />
        </>
    );
};

export default EmployeeSelectButton;
