import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from '../store/auth/authStore';
import { useCheckLogin } from '../query/resource-hooks/auth';
import { useState } from 'react';
import { Role } from '../types';
import { Box } from '@chakra-ui/react';
import { LoadingCard } from '../components/ui/LoadingCard';
import Overlay from '../screens/results/Overlay';

export const PrivateRoutes = () => {
    const [isLoading, setIsLoading] = useState(true);

    useCheckLogin((user) => setIsLoading(false));
    const user = useAuthStore((s) => s.user);

    if (isLoading) {
        return (
            <Box display={'flex'} justifyItems={'center'} justifyContent={'center'} textAlign={'center'}>
                <LoadingCard marginTop={5} width={'md'} />
            </Box>
        );
    }

    if (!user) {
        console.log('NO USER!', user);
        return <Navigate to="/login" />;
    }

    if (![Role.SUPER_OWNER, Role.OWNER, Role.ADMIN].includes(user.role)) {
        return <Navigate to="/no-access" />;
    }

    return (
        <Overlay loggedUser={user}>
            <Outlet />
        </Overlay>
    );
};
