import { Box, Card, HStack, IconButton, ListIcon, ListItem, Text, Tooltip, useDisclosure, useTheme } from '@chakra-ui/react';
import { useArchivedZonesQuery } from '../../../query/resource-hooks/company';
import InfiniteList from '../../../components/lists/InfiniteList';
import { Zone } from '../../../types';
import { FiMap, FiRefreshCcw } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ZoneForm } from '../../../components/zones/ZoneForm';
import SecondPageHeader from '../../../components/ui/SecondPageHeader';

interface ZoneListItemProps {
    zone: { name: string };
    onClick?: () => void;
}

const ZoneListItem: React.FC<ZoneListItemProps> = ({ zone, onClick }) => {
    const theme = useTheme();

    return (
        <ListItem
            onClick={onClick}
            _hover={{
                bg: theme.colors.primary[500],
                color: 'white',
            }}
            cursor={'pointer'}
            borderRadius={'lg'}
        >
            <HStack p={1}>
                <ListIcon as={FiMap} color="green.500" />
                <Text fontWeight={600} fontSize={'md'}>
                    {zone.name}
                </Text>
            </HStack>
        </ListItem>
    );
};

const ArchivedZonesList: React.FC = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const zonesQuery = useArchivedZonesQuery({});
    const addZoneDisclosure = useDisclosure();

    return (
        <Box>
            <SecondPageHeader title={intl.formatMessage({ id: 'archive.zones.title' })} onBack={() => navigate(-1)} />
            <HStack justifyContent={'end'} mx={{ base: 2, md: 4 }} align={'center'}>
                <Tooltip label={intl.formatMessage({ id: 'refresh' })}>
                    <IconButton icon={<FiRefreshCcw />} aria-label="refresh" m={2} variant={'text'} onClick={() => zonesQuery.refetch()} />
                </Tooltip>
            </HStack>
            <Card mx={{ base: 0, md: 4 }} p={4}>
                <InfiniteList<Zone>
                    data={zonesQuery.data}
                    fetchNextPage={zonesQuery.fetchNextPage}
                    hasNextPage={zonesQuery.hasNextPage}
                    isFetchingNextPage={zonesQuery.isFetchingNextPage}
                    renderItem={(zone, index) => <ZoneListItem onClick={() => navigate(`detail/${zone.id}`)} zone={zone} key={index} />}
                    isLoading={zonesQuery.isLoading || zonesQuery.isRefetching}
                />
            </Card>
            <ZoneForm
                isOpen={addZoneDisclosure.isOpen}
                onClose={addZoneDisclosure.onClose}
                onSuccess={(zone) => {
                    navigate(`detail/${zone.id}`);
                    addZoneDisclosure.onClose();
                }}
            />
        </Box>
    );
};

export default ArchivedZonesList;
