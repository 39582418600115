import { Box, Divider, Heading, HStack, Icon, Link, useTheme } from '@chakra-ui/react';
import { AboutLayout } from '../../components/about/AboutHeader';
import { useIntl } from 'react-intl';
import { FiFile } from 'react-icons/fi';

const TermsScreen: React.FC = () => {
    const theme = useTheme();
    const intl = useIntl();
    return (
        <AboutLayout>
            <HStack spacing={2} my={10} maxWidth={'100%'} data-aos="zoom-in-up">
                <Divider borderColor={'gray.400'} />
                <Box data-aos="fade-up" width={'100%'} minWidth={'fit-content'}>
                    <Heading size={'lg'} color={theme.colors.primary[500]} textAlign={'center'}>
                        {intl.formatMessage({ id: 'termsAndConditions' })}
                    </Heading>
                </Box>
                <Divider borderColor={'gray.400'} />
            </HStack>
            <Box maxW={'1140px'} mx={'auto'} px={2}>
                <HStack justifyContent={'center'}>
                    <Icon as={FiFile} ></Icon>
                    <Link href={'/terms_of_use_sk.pdf'}> {intl.formatMessage({ id: 'termsAndConditions' })}</Link>
                </HStack>
            </Box>
        </AboutLayout>
    );
};

export default TermsScreen;
