import { Box, Card, CardBody, Heading, Link, Stack, StackDivider, Text } from '@chakra-ui/react';
import { DocsHomeHero } from './DocsHomeHero';
import { EmailIcon } from '@chakra-ui/icons';

export const DocsHome: React.FC = () => {
    return (
        <Box>
            <DocsHomeHero />
            <Box maxW={'1140px'} pb={12} marginX={'auto'} px={{ base: 2, md: 0 }} pt={4}>
                <Heading id="questions" p={4}>
                    Často kladené otázky
                </Heading>

                <Card>
                    <CardBody>
                        <Stack divider={<StackDivider />} spacing="4">
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Pre koho bol systém navrhnutý?
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Systém Supervizer je navrhnutý pre malé a stredné stavebné firmy, ktoré chcú mať prehľad o dochádzke. Je
                                    vhodný pre kohokoľvek kto chce jednoducho a rýchlo zaznamenávať dochádzku na viacerých miestach. Pri
                                    vývoji úzko spolupracujeme so stavebnými firmami a vieme, že flexibilita, jednoduchosť a rýchlosť
                                    vyhráva. Systém je v neustálom intenzívnom vývoji s novými funkciami a vylepšeniami.
                                </Text>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Čo potrebujem na začatie používania systému?
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Systém môžete používať na akomkoľvek mobilnom telefóne v podobe mobilnej aplikácie alebo tu na webe. Na
                                    zaznamenanie dochádzky potrebujete mobilný telefón alebo tablet s fotoaparátom, zapnutým GPS a
                                    pripojením na internet. Zo skúseností našich zákazníkov vieme, že pre efektívne využívanie stačí lacný
                                    mobilný telefón od výrobcov ako myPhone alebo Xiaomy.
                                </Text>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Ako môžem zapísať dochádzku?
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Dochádzku je možné zapísať v mobilnej aplikácii Supervizer. Je tak možné spraviť v účte zamestnanca
                                    alebo prostredníctvom tímového účtu (terminál).
                                </Text>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Čo sa stane ak zamestnanec nezapíše dochádzku?
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Nič. Systém slúži, ako pomôcka a nenúti vás ho používať neustále. Pri vývoji sme úzko spolupracovali so
                                    stavebnými firmami a vieme, že flexibilita je dôležitá. Preto náš systém neobsahuje žiadne obmedzenia v
                                    tom kedy a, ako ho máte použiť. Nájdite si spôsob, ktorý vyhovuje vám!
                                </Text>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Čo sa stane ak systém nerozpozná tvár zamestnanca?
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Žiadny problém! Pokiaľ systém nerozpozná tvár zamestnanca pri zapísanej dochádzky, môžete jeho totožnosť
                                    skontrolovať manuálne. Každý záznam dochádzky obsahuje fotografiu. Systém vás jednoducho upozorní, že
                                    daný záznam nebolo možné overiť.
                                </Text>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Môžem systém využívať aj vo forme terminálu v ktorom sa búdú zamestnanci prihlasovať?
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Áno. V systéme môžete vytvoriť takzvaný Tímový účet. Tento účet prihlásite do aplikácie ktorá sa
                                    následne chová ako terminál pre prihlasovanie ktoréhokoľvek zamestnanca. Zamestnanec vyberie svoje meno zo zoznamu a nahlási dochádzku fotkou.
                                </Text>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Je možné upravovať dochádzku?
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Áno. Používatelia s rolou Hlavný správca a Správca môžu kedykoľvek upraviť záznam dochádzky. Každá
                                    úprava je zapísaná v histórii záznamu. Všetky nezrovnalosti jednoducho vyriešite.
                                </Text>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Môžem ako vedúci dodatočne zapísať dochádzku zamestnanca?
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Áno. Používatelia s rolou Hlavný správca a Správca môžu kedykoľvek doplniť dochádzku zamestnanca. Tieto
                                    záznamy nazývame manuálne. Manuálne záznamy nevyžadujú fotografiu ani neobsahujú reálnu polohu. Slúžia
                                    na vyplnenie medzier v dochádzke.
                                </Text>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Umožní systém prihlásiť dochádzku aj v prípade neskorého príchodu alebo skorého odchodu?
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Áno. Kvôli flexibilite a jednoduchosti systém nie je naviazaný na pracovnú dobu. Zo skúseností vieme, že
                                    pracovná doba sa môže líšiť od zamestnanca k zamestnancovi. Preto systém nebmedzuje zamestnanca v
                                    prípade neskorého príchodu alebo skorého odchodu. Všetky záznamy môže skontrolovať vedúci a sám ich
                                    vyhodnotiť.
                                </Text>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Ako môžem nahlásiť problém alebo chybu systému?
                                </Heading>
                                <Text pt="2" fontSize="sm">
                                    Akékoľvek pripomienky a problémy nám prosím napíšte na náš email.{' '}
                                    <Link href="mailto:info.supervizer@gmail.com">
                                        info.supervizer@gmail.com <EmailIcon />{' '}
                                    </Link>
                                </Text>
                            </Box>
                        </Stack>
                    </CardBody>
                </Card>
            </Box>
        </Box>
    );
};
