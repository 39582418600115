import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { PricingTable } from '../../../components/about/Pricing/PricingTable';

export const DocsSubscription: React.FC = () => {
    return (
        <Box pb={12}>
            <Box maxW={'1140px'} marginX={'auto'} px={{ base: 2, md: 0 }} pt={4}>
                <Heading p={4}>Predplatné</Heading>
                <Stack spacing={1}>
                    <Text>Systém je spoplatnený mesačným poplatkom. Výška poplatku závisí od počtu používateľov a zvolených služieb.</Text>
                    <Text>Platba prebieha automaticky každý mesiac z vašej kreditnej karty.</Text>
                    <Text>
                        V prípade že vaše predplatné nie je platné (nepodarená platba, zrušené) do systému má prístup len Hlavný správca
                        spoločnosti. V systéme nemôžete vytvárať ďalšie prihlásenia, účty alebo zóny. Ak chcete opäť obnoviť zrušené
                        predplatné skontrolujte nastavenia predplatného. Ak sa vám problém nepodarí vyriešiť, kontaktujte nás.
                    </Text>
                    <Text>
                        Predplatné môže spravovať výhradne účet s rolou <b>Hlavný správca</b>. Nastavenia predplatného môžete kedykoľvek
                        nájsť v detailoch spoločnosti.
                    </Text>
                    <PricingTable />
                </Stack>
            </Box>
        </Box>
    );
};
