import { useGetAttendancePagination } from '../../query/resource-hooks/company';
import { Box, Button, Flex, IconButton, Stack, Tooltip, useDisclosure } from '@chakra-ui/react';
import AttendanceTable from './AttendanceTable';
import { AttendanceTableItem } from '../../components/lists/AttendanceTableItem';
import { useNavigate } from 'react-router-dom';
import { FiRefreshCcw } from 'react-icons/fi';
import { adjustDateToUserTimezone } from '../../components/calendarPicker';
import { useState } from 'react';
import { LoginDirection } from '../../types';
import { AttendanceListFilter } from '../../components/attendance/AttendanceListFilter';
import { UpdateAttendanceModal } from '../../components/attendance/UpdateAttendanceModal';
import { PlusSquareIcon } from '@chakra-ui/icons';
import { useIntl } from 'react-intl';
import UserPolicy from '../../policies/UserPolicy';
import { useAuthStore } from '../../store/auth/authStore';

const AttendanceList: React.FC = () => {
    const navigate = useNavigate();
    const loggedUser = useAuthStore((s) => s.user);
    const intl = useIntl();
    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const [filterLoginDirection, setFilterLoginDirection] = useState<LoginDirection>(LoginDirection.NOT_SET);
    const addAttendanceDisclosure = useDisclosure();

    const attendanceQuery = useGetAttendancePagination({
        ...(selectedDates[0] && { dateFrom: adjustDateToUserTimezone(selectedDates[0]).toISOString() }),
        ...(selectedDates[1] && { dateTo: new Date(adjustDateToUserTimezone(selectedDates[1]).setUTCHours(23, 59, 59)).toISOString() }),
        ...(filterLoginDirection !== LoginDirection.NOT_SET && { direction: filterLoginDirection }),
    });

    return (
        <Box display="flex" justifyContent="center">
            <Box maxW={1680} width={'100%'}>
                <Stack flexDir={{ base: 'column', lg: 'row' }} width={'100%'}>
                    <Box order={{ base: 2, lg: 1 }} width={'100%'}>
                        <Box m={{ base: 0, md: 4 }}>
                            <Flex justify={'space-between'} align={'center'}>
                                <Tooltip label={intl.formatMessage({ id: 'attendance.manual.help' })}>
                                    <Button
                                        ml={2}
                                        onClick={addAttendanceDisclosure.onOpen}
                                        borderRadius={'full'}
                                        leftIcon={<PlusSquareIcon />}
                                        isDisabled={!UserPolicy.canCreateManualAttendance(loggedUser!)}
                                        boxShadow={'md'}
                                    >
                                        {intl.formatMessage({ id: 'tab.Add' })}
                                    </Button>
                                </Tooltip>
                                <IconButton
                                    icon={<FiRefreshCcw />}
                                    aria-label="refresh"
                                    m={2}
                                    variant={'text'}
                                    onClick={() => attendanceQuery.refetch()}
                                />
                            </Flex>

                            <AttendanceTable
                                data={attendanceQuery.data}
                                fetchNextPage={attendanceQuery.fetchNextPage}
                                hasNextPage={attendanceQuery.hasNextPage}
                                isFetchingNextPage={attendanceQuery.isFetchingNextPage}
                                isLoading={attendanceQuery.isLoading || attendanceQuery.isRefetching}
                                renderItem={(item, showPreview) => {
                                    return <AttendanceTableItem onClick={() => showPreview()} item={item} key={item.id} />;
                                }}
                                handleNavigateToDetail={(id) => navigate(`detail/${id}`)}
                            />
                        </Box>
                    </Box>
                    <Box order={{ base: 1, lg: 2 }}>
                        <AttendanceListFilter
                            onDateChange={setSelectedDates}
                            selectedDates={selectedDates}
                            filterLoginDirection={filterLoginDirection}
                            onFilterLoginDirectionChange={setFilterLoginDirection}
                            totalRecords={attendanceQuery.data?.pages?.[0].meta?.total}
                            position="sticky"
                            top="22px"
                            mt={4}
                            ml={{ base: 2, lg: 0 }}
                            mr={2}
                        />
                    </Box>
                </Stack>
                <UpdateAttendanceModal
                    isOpen={addAttendanceDisclosure.isOpen}
                    onClose={addAttendanceDisclosure.onClose}
                    onSuccess={() => attendanceQuery.refetch()}
                    preselectedDate={new Date().toISOString()}
                />
            </Box>
        </Box>
    );
};

export default AttendanceList;
