import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { BasePaginationFilter, DeviceData, RegisteredDevice, RegisteredDevicePagination } from '../../types';
import { AxiosError } from 'axios';
import DeviceService from '../services/DeviceService';

const useGetRegisteredDevicesQuery = (params: { userId: number; filter?: BasePaginationFilter }) => {
    return useInfiniteQuery<RegisteredDevicePagination, AxiosError<any>>({
        queryKey: ['registeredDevices', params],
        queryFn: ({ pageParam = params }) => DeviceService.getUserDevices(params.userId, params?.filter),
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page === lastPage.meta.last_page) {
                return undefined; // no more pages
            }

            return {
                userId: params.userId,
                filter: {
                    ...params.filter,
                    page: lastPage.meta.current_page + 1,
                },
            };
        },
    });
};

const useGetRegisteredDeviceDetailsQuery = (params: { userId?: number; deviceId?: number }) => {
    return useQuery<RegisteredDevice, AxiosError<any>>({
        queryKey: ['registeredDeviceDetails', params],
        queryFn: () => DeviceService.getDeviceDetails(params.userId!, params.deviceId!),
        enabled: !!params.userId && !!params.deviceId,
    });
};

const useUpdateRegisteredDeviceMutation = (onSuccess?: (data: RegisteredDevice) => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<RegisteredDevice, AxiosError<any>, { id: number; userId: number; data: DeviceData }>({
        mutationFn: ({ id, userId, data }) => DeviceService.updateRegisteredDevice(userId, id, data),
        onSuccess: onSuccess,
        onError: onError,
    });
};

const useLogoutRegisteredDeviceMutation = (onSuccess?: () => void, onError?: (error: AxiosError<any>) => void) => {
    return useMutation<void, AxiosError<any>, { userId: number; deviceId: number }>({
        mutationFn: ({ userId, deviceId }) => DeviceService.logoutDevice(userId, deviceId),
        onSuccess: onSuccess,
        onError: onError,
    });
};

export {
    useGetRegisteredDevicesQuery,
    useGetRegisteredDeviceDetailsQuery,
    useUpdateRegisteredDeviceMutation,
    useLogoutRegisteredDeviceMutation,
};
