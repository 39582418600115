import { Box, Card, Heading, Image, useColorModeValue, Text, VStack, Divider, Button, useToast } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useResendEmailVerificationEmail } from '../../query/resource-hooks/auth';
import { EmailIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';

export const EmailNotVerified: React.FC = () => {
    const intl = useIntl();
    const toast = useToast();
    const resendEmailMutation = useResendEmailVerificationEmail();
    const [isDisabled, setIsDisabled] = useState(false);
    const [counter, setCounter] = useState(100);

    useEffect(() => {
        if (isDisabled && counter > 0) {
            const timer = setTimeout(() => setCounter(counter - 1), 1000);
            return () => clearTimeout(timer);
        }

        if (counter === 0) {
            setIsDisabled(false);
            setCounter(100);
        }
    }, [isDisabled, counter]);

    const handleClick = async () => {
        setIsDisabled(true);
        console.log('click')
        const response = await resendEmailMutation.mutateAsync();
        if (response.status >= 300) {
            toast({
                title: intl.formatMessage({ id: 'somethingWentWrong.tryLater' }),
                status: 'error',
                duration: 6000,
                isClosable: true,
            });
        }
    };

    return (
        <Box
            justifyContent={'center'}
            alignItems={'start'}
            display="flex"
            w={'100vw'}
            h={'100vh'}
            pt={4}
            px={2}
            bg={useColorModeValue('gray.100', 'gray.900')}
        >
            <Card p={4}>
                <VStack maxW={'450px'}>
                    <Box p={2} textAlign={'center'}>
                        <Heading pb={2}>{intl.formatMessage({ id: 'confirmEmail' })}!</Heading>
                        <Divider />
                        <Text>{intl.formatMessage({ id: 'confirmEmailText' })}</Text>
                        <Button  onClick={() => handleClick()} isDisabled={isDisabled} leftIcon={<EmailIcon />} my={2}>
                            {intl.formatMessage({ id: 'requestEmail' })}
                            {isDisabled && `   (${counter}s)`}
                        </Button>
                    </Box>
                    <Box p={2} alignItems={'center'} display={'flex'} justifyContent={'center'}>
                        <Image maxW={{ base: 280, md: 380 }} src="images/openedEmail.svg"></Image>
                    </Box>
                </VStack>
            </Card>
        </Box>
    );
};
