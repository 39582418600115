import { Menu, MenuButton, Button, MenuList, Radio, RadioGroup, Stack, MenuItem } from '@chakra-ui/react';
import { MdLanguage } from 'react-icons/md';
import { useSettingsStore } from '../../store/ui/SettingsStore';
import { languages } from '../../i18n/languages';

const LanguageSelectMenu = () => {
    const setLanguage = useSettingsStore((state) => state.setLanguage);
    const currentLanguage = useSettingsStore((state) => state.language);

    return (
        <Menu>
            <MenuButton variant={'text'} as={Button} colorScheme="teal">
                <MdLanguage size={20} color="white" />
            </MenuButton>
            <MenuList>
                <RadioGroup onChange={setLanguage} value={currentLanguage || undefined}>
                    <Stack>
                        {languages.map((lang) => {
                            return (
                                <MenuItem key={lang.code} onClick={() => setLanguage(lang.code)}>
                                    <Radio value={lang.code}>{lang.name}</Radio>
                                </MenuItem>
                            );
                        })}
                    </Stack>
                </RadioGroup>
            </MenuList>
        </Menu>
    );
};

export default LanguageSelectMenu;
