import React from 'react';
import { Helmet } from 'react-helmet';

export const SeoHelmet: React.FC = () => {
    return (
        <Helmet>
            <title>Supervizer | Dochádzkový systém</title>

            <meta name="google-site-verification" content="32Y2BD-xsFfeOqbMstMs5H_JhCZlAMkBlIJ43FfqKz4" />

            <meta name="description" content="Dochádzkový systém s overením totožnosti" />
            <meta name="keywords" content="dochádzka, rozpoznanie tváre, mobilná aplikácia, kontrola dochádzky" />
            <meta name="author" content="Dominik Brandys" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            <link rel="canonical" href="https://www.supervizer.eu/" />

           




            <meta property="og:title" content="Supervizer docházkový systém" />
            <meta property="og:description" content="Dochádzkový systém s rozpoznaním tváre" />
            <meta property="og:image" content="https://www.supervizer.eu/logo512.png" />
            <meta property="og:url" content="https://www.supervizer.eu/" />
            <meta property="og:type" content="website" />

            <meta name="twitter:title" content="Supervizer docházkový systém" />
            <meta name="twitter:description" content="Dochádzkový systém s rozpoznaním tváre" />
            <meta name="twitter:image" content="https://www.supervizer.eu/logo512.png" />
        </Helmet>
    );
};
