import React, { useState } from 'react';
import { useGetBillingInfoByToken } from '../../query/resource-hooks/auth';
import { Box, Heading, Spinner } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { BillingAccount, InternalSubscriptionStatus } from '../../types';
import { InfoResult } from '../results/InfoResult';
import { AxiosError } from 'axios';

export const CheckoutReturn: React.FC<{ token: string }> = ({ token }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [isCancelled, setIsCancelled] = useState(false);

    const params = new URLSearchParams(useLocation().search);

    const billingInfo = useGetBillingInfoByToken(token, onSuccess, onError);

    function onSuccess(data: BillingAccount) {
        if (params.get('cancel')) {
            setIsCancelled(true);
            return;
        }

        switch (data?.internalSubscriptionStatus) {
            case InternalSubscriptionStatus.ACTIVE:
                return navigate('/result', {
                    state: {
                        title: intl.formatMessage({ id: 'billing.setup.completed.title' }),
                        text: intl.formatMessage({ id: 'billing.setup.completed.text' }),
                        type: 'success',
                    },
                });

            case InternalSubscriptionStatus.INACTIVE:
                return navigate('/result', {
                    state: {
                        title: intl.formatMessage({ id: 'somethingWentWrong' }),
                        text: intl.formatMessage({ id: 'billing.setup.error.text' }),
                        type: 'error',
                    },
                });
            case InternalSubscriptionStatus.INITIALIZING:
            default:
                return navigate('/result', {
                    state: {
                        title: intl.formatMessage({ id: 'billing.setup.initializing.title' }),
                        text: intl.formatMessage({ id: 'billing.setup.initializing.text' }),
                        type: 'info',
                    },
                });
        }
    }

    function onError(_error: AxiosError<any>) {
        navigate('/result', {
            state: {
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                text: intl.formatMessage({ id: 'billing.setup.error.text' }),
                type: 'error',
            },
        });
    }

    function cancelledAction() {
        if (billingInfo.data?.redirectUrl) {
            window.location.href = billingInfo.data.redirectUrl;
        } else {
            window.location.href = '/home';
        }
    }

    if (isCancelled) {
        return (
            <InfoResult
                title={intl.formatMessage({ id: 'billing.setup.cancelled.title' })}
                text={intl.formatMessage({ id: 'billing.setup.cancelled.text' })}
                type="error"
                actionText={
                    billingInfo.data?.redirectUrl
                        ? intl.formatMessage({ id: 'billing.backToPayment' })
                        : intl.formatMessage({ id: 'returnToApp' })
                }
                onAction={() => cancelledAction()}
            />
        );
    }

    return (
        <Box textAlign="center" py={10} px={6}>
            <Spinner />
            <Heading as="h2" size="xl" mt={6} mb={2}>
                {intl.formatMessage({ id: 'billing.setup.initializing.title' })}
            </Heading>
        </Box>
    );
};
