import messages_cz from './cz.json';
import messages_en from './en.json';
import messages_sk from './sk.json';

export interface Language {
  code: string;
  name: string;
}

export const languages: Language[] = [
  {
    code: 'sk',
    name: 'Slovenčina',
  },
  {
    code: 'cs',
    name: 'Čeština',
  },
  {
    code: 'en',
    name: 'English',
  },
];

export const messages = {
  en: messages_en,
  sk: messages_sk,
  cs: messages_cz,
};

/**
 * Map of available languages - use in case you do not need to import whole languages arrays
 */
export const availableLanguagesMap: Record<string, any> = {
  en: true,
  sk: true,
  cs: true,
};

export const getLanguageMessages = (locale: string) => {
  // @ts-ignore
  return messages[locale];
};

export const isLanguageAvailable = (locale: string) => {
  if (availableLanguagesMap[locale]) {
    return true;
  }

  return false;
};

export const getFormattedLanguage = (locale?: string) => {
  if (!locale) {
    return;
  }

  return locale.slice(0, 2).toUpperCase();
};
