import {
    Badge,
    Box,
    Card,
    CardBody,
    Divider,
    FormLabel,
    Heading,
    HStack,
    Icon,
    IconButton,
    Wrap,
    WrapItem,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { LoginDirection } from '../../types';
import { CalendarIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { RangeDatepicker } from '../calendarPicker';
import { LoginDirectionButton } from '../ui/LogindirectionButton';
import { useIntl } from 'react-intl';
import React from 'react';
import { isSameDay } from 'date-fns';

type AttendanceListFilterProps = {
    onDateChange: (dates: Date[]) => void;
    selectedDates: Date[];
    filterLoginDirection: LoginDirection;
    onFilterLoginDirectionChange: (loginDirection: LoginDirection) => void;
    totalRecords?: number;
    [key: string]: any;
};

const FilterChip: React.FC<{ label: string; onClear: () => void; selected: boolean; onSelect: () => void }> = ({
    label,
    onClear,
    selected,
    onSelect,
}) => {
    const background = useColorModeValue('gray.300', 'gray.700');
    const iconColor = useColorModeValue('green', 'greenyellow');

    function handleClick() {
        if (selected) {
            return onClear();
        }

        onSelect();
    }
    return (
        <Box
            onClick={handleClick}
            cursor={'pointer'}
            borderRadius={'md'}
            px={1}
            backgroundColor={background}
            border={'solid'}
            borderWidth={selected ? 2 : 1}
        >
            <HStack>
                {selected && <CheckIcon color={iconColor} boxSize={4} onClick={onClear} />}
                <Text fontWeight={selected ? 700 : 500} fontSize={'small'}>
                    {label}
                </Text>
            </HStack>
        </Box>
    );
};

export const AttendanceListFilter: React.FC<AttendanceListFilterProps> = ({
    onDateChange,
    onFilterLoginDirectionChange,
    filterLoginDirection,
    selectedDates,
    totalRecords,
    ...restCardProps
}) => {
    const intl = useIntl();

    function isChipSelected(chipType: 'day' | 'week' | 'month'): boolean {
        let start = new Date();
        let end = new Date();
        switch (chipType) {
            case 'day':
                start.setHours(0, 0, 0, 0);
                end.setHours(23, 59, 59, 999);
                break;
            case 'week':
                start.setHours(0, 0, 0, 0);
                start.setDate(start.getDate() - start.getDay());
                end.setHours(23, 59, 59, 999);
                end.setDate(start.getDate() + 6);
                break;
            case 'month':
                start.setDate(1);
                start.setHours(0, 0, 0, 0);
                end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
                end.setHours(23, 59, 59, 999);
                break;
        }

        if (isSameDay(selectedDates[0], start) && isSameDay(selectedDates[1], end)) {
            return true;
        }

        return false;
    }

    return (
        <Card {...restCardProps} boxShadow={'md'}>
            <CardBody>
                <HStack mb={2} alignItems={'center'} justifyContent={'space-between'}>
                    <Heading alignItems={'center'} size={'md'}>
                        Filter
                    </Heading>
                    <Badge borderRadius={'md'} alignItems={'center'} display={'flex'}>
                        {totalRecords ?? 0}
                        <Icon ml={1} as={CalendarIcon} />
                    </Badge>
                </HStack>
                <Divider />
                <FormLabel>{intl.formatMessage({ id: 'period' })}</FormLabel>
                <HStack>
                    <RangeDatepicker
                        selectedDates={selectedDates}
                        onDateChange={(dates) => {
                            onDateChange(dates);
                        }}
                        usePortal
                        configs={{
                            placeHolders: [intl.formatMessage({ id: 'dateFrom' }), intl.formatMessage({ id: 'dateTo' })],
                        }}
                    />

                    {selectedDates.length > 0 && (
                        <IconButton
                            onClick={() => onDateChange([])}
                            aria-label="clear dates"
                            icon={<CloseIcon color={'red'} />}
                            variant={'text'}
                        ></IconButton>
                    )}
                </HStack>
                <Wrap mt={2}>
                    <WrapItem>
                        <FilterChip
                            label={intl.formatMessage({ id: 'timefilter.today' })}
                            onClear={() => {
                                onDateChange([]);
                            }}
                            selected={isChipSelected('day')}
                            onSelect={() => {
                                const start = new Date();
                                const end = new Date();
                                end.setHours(23, 59, 59, 999);
                                start.setHours(0, 0, 0, 0);
                                onDateChange([start, end]);
                            }}
                        />
                    </WrapItem>
                    <WrapItem>
                        <FilterChip
                            label={intl.formatMessage({ id: 'timefilter.week' })}
                            onClear={() => {
                                onDateChange([]);
                            }}
                            selected={isChipSelected('week')}
                            onSelect={() => {
                                const start = new Date();
                                start.setHours(0, 0, 0, 0);
                                start.setDate(start.getDate() - start.getDay());
                                const end = new Date();
                                end.setHours(23, 59, 59, 999);
                                end.setDate(start.getDate() + 6);
                                onDateChange([start, end]);
                            }}
                        />
                    </WrapItem>
                    <WrapItem>
                        <FilterChip
                            label={intl.formatMessage({ id: 'timefilter.month' })}
                            onClear={() => {
                                onDateChange([]);
                            }}
                            selected={isChipSelected('month')}
                            onSelect={() => {
                                const start = new Date();
                                start.setDate(1);
                                start.setHours(0, 0, 0, 0);
                                const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
                                end.setHours(23, 59, 59, 999);
                                onDateChange([start, end]);
                            }}
                        />
                    </WrapItem>
                </Wrap>
                <FormLabel mt={2}>{intl.formatMessage({ id: 'type' })}</FormLabel>
                <LoginDirectionButton showAll loginDirection={filterLoginDirection} onChange={onFilterLoginDirectionChange} />
            </CardBody>
        </Card>
    );
};
