import { Box, Button, HStack, Tag } from '@chakra-ui/react';
import { Formik, FormikConfig, FormikProps } from 'formik';
import React, { useState } from 'react';
import { FormikStepProps } from './FormikStep';
import { useIntl } from 'react-intl';
import { Form } from 'react-router-dom';
import { ArrowBackIcon, ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';

type FormikStepperProps = FormikConfig<any> & {
    onStepChange?: (newStep: number) => void;
    finalStepSubmitText?: string;
    showStepCounter?: boolean;
    isLoading?: boolean
};

const FormikStepper: React.FC<FormikStepperProps> = ({
    initialValues,
    onSubmit,
    children,
    onStepChange,
    finalStepSubmitText,
    showStepCounter = false,
    isLoading = false,
    ...restProps
}) => {
    const intl = useIntl();
    const [step, setStep] = useState(0);
    const totalSteps = React.Children.count(children);

    const handlePrevStep = () => {
        if (step > 0) {
            onStepChange?.(step - 1);
            setStep(step - 1);
        }
    };

    const childrenArray = React.Children.toArray(children as React.ReactNode[]) as React.ReactElement<FormikStepProps>[];

    const renderCurrentStep = (formikProps: FormikProps<any>) => {
        return React.cloneElement(childrenArray[step], { formikProps });
    };

    function isLastStep() {
        return step === totalSteps - 1;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (values, helpers) => {
                if (isLastStep()) {
                    await onSubmit(values, helpers);
                } else {
                    onStepChange?.(step + 1);
                    setStep(step + 1);
                }
            }}
            validationSchema={childrenArray[step].props.validationSchema}
            {...restProps}
        >
            {(formikProps: FormikProps<any>) => (
                <Box style={{ flex: 1 }}>
                    <Form> {renderCurrentStep(formikProps)}</Form>
                    <HStack justifyContent={'space-between'} pt={2}>
                        <Box>
                            <Button variant={'outlined'} leftIcon={<ArrowBackIcon />} isDisabled={step === 0} onClick={handlePrevStep}>
                                {intl.formatMessage({ id: 'form.back' })}
                            </Button>
                        </Box>
                        {showStepCounter && (
                            <Box>
                                <Tag variant={'outlined'}>
                                    {step + 1} / {totalSteps}
                                </Tag>
                            </Box>
                        )}
                        <Box>
                            <Button
                                rightIcon={isLastStep() ? <CheckIcon /> : <ArrowForwardIcon />}
                                onClick={(_e) => formikProps.handleSubmit()}
                                colorScheme={isLastStep() ? 'green' : 'primary'}
                                isLoading={isLoading}
                            >
                                {isLastStep()
                                    ? finalStepSubmitText || intl.formatMessage({ id: 'form.submit' })
                                    : intl.formatMessage({ id: 'form.next' })}
                            </Button>
                        </Box>
                    </HStack>
                </Box>
            )}
        </Formik>
    );
};

export default FormikStepper;
