import {
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepIcon,
    StepNumber,
    StepTitle,
    StepDescription,
    StepSeparator,
    Box,
    useBreakpointValue,
} from '@chakra-ui/react';



type StepperProps = {
    step: number;
    steps: {title: string, description: string}[]
};

const FormStepperVisualizer: React.FC<StepperProps> = ({ step, steps }) => {
    const isMobile = useBreakpointValue({ base: true, md: false });


    return (
        <Stepper index={step}>
            {steps.map((step, index) => (
                <Step key={index}>
                    <StepIndicator>
                        <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                    </StepIndicator>

                    <Box flexShrink="0">
                        <StepTitle>{step.title}</StepTitle>
                        {isMobile ? null : <StepDescription>{step.description}</StepDescription>}
                    </Box>

                    <StepSeparator />
                </Step>
            ))}
        </Stepper>
    );
};

export default FormStepperVisualizer;
