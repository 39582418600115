export enum LoginFaceValidationResult {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    ERROR = 'ERROR',
    WAITING = 'WAITING',
}

export enum LoginDirection {
    IN = 'IN',
    OUT = 'OUT',
    NOT_SET = 'NOT_SET',
}

export enum LoginPositionValidationResult {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    ERROR = 'ERROR',
    WAITING = 'WAITING',
}
