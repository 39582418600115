import { Center, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { BusinessIdentity } from '../../types';
import { LoadingCard } from '../ui/LoadingCard';
import { useIntl } from 'react-intl';

export const IdentityDataTable: React.FC<{ identity: BusinessIdentity }> = ({ identity }) => {
    const intl = useIntl();
    if (!identity) {
        return (
            <Center p={5}>
                <LoadingCard />
            </Center>
        );
    }

    return (
        <TableContainer>
            <Table size={'sm'} overflow={'hidden'}>
                <Tbody>
                    <Tr>
                        <Td colSpan={2}>{identity.name}</Td>
                    </Tr>
                    {identity.email && (
                        <Tr>
                            <Td  colSpan={2}>{identity.email}</Td>
                        </Tr>
                    )}
                    <Tr>
                        <Td  colSpan={2}>{identity.street || '-'}</Td>
                    </Tr>
                    <Tr>
                        <Td  colSpan={2}>{`${identity.postalCode ?? ''} ${identity.city ?? '-'}`}</Td>
                    </Tr>
                    <Tr>
                        <Td>{intl.formatMessage({ id: 'field.organizationIdentifier' })}</Td>
                        <Td>{identity.organizationIdentifier ?? '-'}</Td>
                    </Tr>
                    <Tr>
                        <Td>{intl.formatMessage({ id: 'field.taxId' })}</Td>
                        <Td>{`${identity.taxId ?? '-'}`}</Td>
                    </Tr>
                    <Tr>
                        <Td>{intl.formatMessage({ id: 'field.vatId' })}</Td>
                        <Td>{identity.vatId ?? '-'}</Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>
    );
};
