import {
    Badge,
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Divider,
    Heading,
    HStack,
    IconButton,
    SimpleGrid,
    Stack,
    Tooltip,
    useDisclosure,
    useToast,
    Wrap,
} from '@chakra-ui/react';
import SecondPageHeader from '../../components/ui/SecondPageHeader';
import CompanyCard from '../../components/company/CompanyCard';
import { useGetCompanyDetail } from '../../query/resource-hooks/company';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { IdentityDataTable } from '../../components/company/IdentityDataTable';
import { BillingAccount, Feature, InternalSubscriptionStatus, Role } from '../../types';
import { CheckIcon, EditIcon, LinkIcon, QuestionIcon, WarningIcon } from '@chakra-ui/icons';
import { useCreateBillingPortalSession } from '../../query/resource-hooks/auth';
import { ContactForm } from '../../components/contacts/ContactForm';
import UserPolicy from '../../policies/UserPolicy';
import { useAuthStore } from '../../store/auth/authStore';

const CompanyDetailScreen: React.FC = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const loggedUser = useAuthStore((state) => state.user);

    const companyDetail = useGetCompanyDetail();
    const createBillingPortalSession = useCreateBillingPortalSession();
    const toast = useToast();

    const editCompanyDataDisclosure = useDisclosure();

    const handleRedirectToBilling = async () => {
        try {
            if (companyDetail?.data?.billingAccount?.redirectUrl) {
                window.location.href = companyDetail.data.billingAccount.redirectUrl;
                return;
            }

            const result = await createBillingPortalSession.mutateAsync();
            if (result.url) {
                return (window.location.href = result.url);
            }
            throw new Error('No billing portal session');
        } catch (error) {
            toast({
                title: intl.formatMessage({ id: 'somethingWentWrong' }),
                description: intl.formatMessage({ id: 'billing.setup.error.contactUs' }),
                status: 'error',
                duration: 10000,
                isClosable: true,
            });
        }
    };

    function getSubscriptionColorScheme(billingAccount?: BillingAccount | null) {
        if (!billingAccount) {
            return 'green';
        }

        if (billingAccount.actionRequired) {
            return 'yellow';
        }

        switch (billingAccount.internalSubscriptionStatus) {
            case InternalSubscriptionStatus.ACTIVE:
                return 'green';
            case InternalSubscriptionStatus.INACTIVE:
                return 'red';
            case InternalSubscriptionStatus.INITIALIZING:
                return 'primary';
        }
    }

    function enableRedirectToBilling() {
        if (loggedUser?.role !== Role.SUPER_OWNER) {
            return false;
        }

        return !!(companyDetail.data?.billingAccount?.stripeStatus ?? companyDetail.data?.billingAccount?.redirectUrl);
    }

    function getSubscriptionStatusText(billingAccount?: BillingAccount | null) {
        if (!billingAccount) {
            return intl.formatMessage({ id: 'billing.status.active' });
        }

        if (billingAccount.actionRequired) {
            return intl.formatMessage({ id: 'billing.setting.actionRequired' });
        }

        return intl.formatMessage({ id: `billing.status.${billingAccount.internalSubscriptionStatus}` });
    }

    function getSubscriptionBadgeIcon() {
        if (companyDetail.data?.billingAccount?.actionRequired) {
            return <WarningIcon mr={1} color={'red'} />;
        }

        switch (companyDetail.data?.billingAccount?.internalSubscriptionStatus) {
            case InternalSubscriptionStatus.INACTIVE:
                return <WarningIcon mr={1} color={'red'} />;
            case InternalSubscriptionStatus.INITIALIZING:
                return <QuestionIcon mr={1} />;
            case InternalSubscriptionStatus.ACTIVE:
            default:
                return <CheckIcon mr={1} color={'green'} />;
        }
    }

    function renderFeature(feature: Feature) {
        switch (feature) {
            case Feature.ADMINISTRATION:
                return (
                    <Tooltip key={feature} label={intl.formatMessage({ id: 'feature.administration.description' })}>
                        <Badge borderRadius={'md'} colorScheme="teal">
                            {intl.formatMessage({ id: 'feature.administration' })}
                        </Badge>
                    </Tooltip>
                );
            case Feature.ATTENDANCE:
                return (
                    <Tooltip key={feature} label={intl.formatMessage({ id: 'feature.attendance.description' })}>
                        <Badge borderRadius={'md'}>{intl.formatMessage({ id: 'feature.attendance' })}</Badge>
                    </Tooltip>
                );
        }
    }

    return (
        <>
            <SecondPageHeader onBack={() => navigate(-1)} title={intl.formatMessage({ id: 'company' })} />
            <Box display={'flex'} width={'100%'} justifyItems={'center'} justifyContent={'center'} px={{ base: 2, xl: 0 }} pt={4}>
                <SimpleGrid minW={300} gap={4} columns={{ base: 1, xl: 2 }}>
                    <CompanyCard onLogoUpdate={() => companyDetail.refetch()} boxShadow={'lg'} minW={{ base: 0, md: 450 }} company={companyDetail.data!}></CompanyCard>
                    <Card>
                        <CardHeader>
                            <Heading size={'md'}>{intl.formatMessage({ id: 'billing.subscription' })}</Heading>
                        </CardHeader>
                        <CardBody>
                            <Stack divider={<Divider />}>
                                <HStack justify={'space-between'}>
                                    <Heading size={'sm'}>{intl.formatMessage({ id: 'billing.status' })}</Heading>
                                    <Badge
                                        fontSize="0.8em"
                                        borderRadius={'md'}
                                        colorScheme={getSubscriptionColorScheme(companyDetail.data?.billingAccount)}
                                    >
                                        {getSubscriptionBadgeIcon()}
                                        {getSubscriptionStatusText(companyDetail.data?.billingAccount)}
                                    </Badge>
                                </HStack>
                                <Box>
                                    <Heading size={'sm'}>{intl.formatMessage({ id: 'features.title' })}</Heading>
                                    <Wrap p={2}>
                                        {companyDetail?.data?.billingAccount?.price
                                            ? companyDetail?.data?.billingAccount?.price?.features?.map((feature) =>
                                                  renderFeature(feature.name)
                                              )
                                            : Object.values(Feature).map((feature) => renderFeature(feature))}
                                    </Wrap>
                                </Box>
                            </Stack>
                        </CardBody>
                        <CardFooter>
                            <Button
                                isDisabled={!enableRedirectToBilling()}
                                colorScheme={companyDetail?.data?.billingAccount?.actionRequired ? 'red' : 'primary'}
                                leftIcon={<LinkIcon />}
                                onClick={() => handleRedirectToBilling()}
                                isLoading={createBillingPortalSession.isLoading}
                            >
                                {intl.formatMessage({ id: 'billing.settings' })}
                            </Button>
                        </CardFooter>
                    </Card>
                    {companyDetail?.data?.businessIdentity && (
                        <Card boxShadow={'lg'} gridColumn={{ base: 'span 1', xl: 'span 2' }}>
                            <CardHeader display={'flex'} justifyContent={'space-between'}>
                                <Heading size={'md'}>{intl.formatMessage({ id: 'title.contact' })}</Heading>
                                {UserPolicy.canUpdateCompany(loggedUser!) && (
                                    <IconButton
                                        aria-label="edit company data"
                                        icon={<EditIcon />}
                                        variant={'text'}
                                        onClick={editCompanyDataDisclosure.onOpen}
                                    />
                                )}
                            </CardHeader>
                            <CardBody>
                                <IdentityDataTable identity={companyDetail.data.businessIdentity} />
                            </CardBody>
                        </Card>
                    )}
                </SimpleGrid>
            </Box>
            <ContactForm
                isOpen={editCompanyDataDisclosure.isOpen}
                onClose={editCompanyDataDisclosure.onClose}
                updatedContact={companyDetail.data?.businessIdentity}
                onSuccess={() => companyDetail.refetch()}
                isCompanyUpdate
            />
        </>
    );
};

export default CompanyDetailScreen;
