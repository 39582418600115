import {
    Box,
    Icon,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react';
import { ComponentProps } from 'react';
import { FaAndroid, FaApple } from 'react-icons/fa';
import { useIntl } from 'react-intl';

type InstallAppModalProps = Omit<ComponentProps<typeof Modal>, 'children'>;

export const InstallAppModal: React.FC<InstallAppModalProps> = ({ isOpen, onClose }) => {
    const intl = useIntl();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{intl.formatMessage({ id: 'about.timeline.downloadApp.title' })}</ModalHeader>
                <ModalCloseButton />
                <ModalBody></ModalBody>
                <VStack mx={5}>
                    <Box as={Link} target='_blank' href="https://play.google.com/store/apps/details?id=com.supervizer" borderWidth="1px" width={'full'} borderRadius="lg" overflow="hidden" p="4" textAlign="center">
                        <Box my="2">
                            {/* Android Logo Placeholder */}
                            <Icon color={'green'} as={FaAndroid} boxSize="44px" />
                        </Box>
                        <Box mt="2">
                            <Text fontWeight={'600'}>ANDROID</Text>
                        </Box>
                    </Box>
                    <Box as={Link} target='_blank' href="https://apps.apple.com/sk/app/supervizer/id6479220108" borderWidth="1px" width={'full'} borderRadius="lg" overflow="hidden" p="4" textAlign="center">
                        <Box my="2">
                            <Icon as={FaApple} boxSize="44px" />
                        </Box>
                        <Box mt="2">
                            <Text fontWeight={'600'}>IOS</Text>
                        </Box>
                    </Box>
                </VStack>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
    );
};
